import React, { useEffect, useState } from 'react'
import { Product } from '../components/Product';
import { useDispatch, useSelector } from 'react-redux';
import { listCategoryProducts } from '../actions/productActions';
import { ToolBar } from '../components/ToolBar';
import { LoadingBox } from '../components/LoadingBox';
import { AlertMessage } from '../components/AlertMessage';
import { useParams } from 'react-router';
import { detailsUser } from '../actions/userActions';
import { getNotificationsUser } from '../actions/notificationActions';
import { listCategorySubscriptionProducts } from '../actions/subscriptionProductActions';
import { SubscriptionProduct } from '../components/SubscriptionProduct';
import { listCategoryExtracurricularActivities } from '../actions/extracurricularActivityActions';
import { ExtracurricularActivity } from '../components/ExtracurricularActivity';

export const CategoryScreen = () => {

  const params = useParams();
  const categoryId = params.id;


  const dispatch = useDispatch();
  const categoryProductList = useSelector((state) => state.categoryProductList);
  const { loading, error, products } = categoryProductList;

  const categorySubscriptionProductList = useSelector(state => state.subscriptionProductList)
  const {loading: loadingSubscriptionProducts, error: errorSubscriptionProducts, subscriptionProducts} = categorySubscriptionProductList

  const {loading: loadingExtracurricularActivity, error: errorExtracurricularActivity, extracurricularActivities} = useSelector(state => state.extracurricularActivityList)

  const userDetails = useSelector(state => state.userDetails)
  const userSignin = useSelector(state => state.userSignin)
  const {
    userInfo
} = userSignin;

  const [availableProducts, setProducts] = useState([])
  const [availableSubscriptionProducts, setSubscriptionProducts] = useState([])
  const [availableExtracurricularActivities, setAvailableExtracurricularActivities] = useState([])

  useEffect(() => {
    if (userInfo) {
      dispatch(listCategoryProducts(categoryId));
      dispatch(listCategorySubscriptionProducts(categoryId))
      dispatch(listCategoryExtracurricularActivities(categoryId))
      dispatch(detailsUser(userInfo._id))
      dispatch(getNotificationsUser());
    }
    
    
  }, [dispatch, categoryId, userInfo])

  useEffect(() =>{
    if (userDetails.user && products) {
      if (userDetails.user.isAdmin === true) {
        setProducts(products)
      } else {
        let productsFiltered = []
        userDetails.user.userChildren.forEach(child => {
          products.forEach(product => {
            let available = true
            if (product.courses?.length > 0 && product.courses.indexOf(child.course) === -1) {
              available = false
            }

            if (product.students?.length > 0 && product.students.indexOf(child._id) === -1) {
              available = false
            }

            let found = 0
            let hasAttributeCourses = false
            for (let i = 0; i < product.attributes.length; i++) {
              if (product.attributes[i]?.value.courses?.length > 0) {
                if (product.attributes[i]?.value.courses.indexOf(child.course) === -1) {
                  found++
                }
                hasAttributeCourses = true
              }
            }
            if (hasAttributeCourses && found === 0) {
              available = false
            }

            if (available && !productsFiltered.find(p => p._id === product._id)) {
              productsFiltered.push(product)
            }
          });
        });
        setProducts(productsFiltered)
      }
    } else {
      setProducts([])
    }
  }, [products, userDetails])

  useEffect(() =>{
    if (userDetails.user && subscriptionProducts) {
      if (userDetails.user.isAdmin === true) {
        setSubscriptionProducts(subscriptionProducts)
      } else {
        let productsFiltered = []
        userDetails.user.userChildren.forEach(child => {
          subscriptionProducts.forEach(product => {
            let available = true
            if (product.courses?.length > 0 && product.courses.indexOf(child.course) === -1) {
              available = false
            }

            if (product.students?.length > 0 && product.students.indexOf(child._id) === -1) {
              available = false
            }

            if (available && !productsFiltered.find(p => p._id === product._id)) {
              productsFiltered.push(product)
            }
          });
        });
        setSubscriptionProducts(productsFiltered)
      }
    } else {
      setSubscriptionProducts([])
    }
  }, [subscriptionProducts, userDetails])

  useEffect(() =>{
    if (userDetails.user && extracurricularActivities) {
      if (userDetails.user.isAdmin === true) {
        setAvailableExtracurricularActivities(extracurricularActivities)
      } else {
        let extracurricularActivitiesFiltered = []
        userDetails.user.userChildren.forEach(child => {
          extracurricularActivities.forEach(product => {
            let available = true
            if (product.courses?.length > 0 && product.courses.indexOf(child.course) === -1) {
              available = false
            }

            if (product.students?.length > 0 && product.students.indexOf(child._id) === -1) {
              available = false
            }

            if (available && !extracurricularActivitiesFiltered.find(p => p._id === product._id)) {
              extracurricularActivitiesFiltered.push(product)
            }
          });
        });
        setAvailableExtracurricularActivities(extracurricularActivitiesFiltered)
      }
    } else {
      setAvailableExtracurricularActivities([])
    }
  }, [extracurricularActivities, userDetails])

  function mostrarLabel(productDate) {
    const daysBetween = new Date().getDate() - new Date(productDate).getDate()
    const monthsBetween = new Date().getMonth() - new Date(productDate).getMonth();
    const yearsBetween = new Date().getFullYear() - new Date(productDate).getFullYear();

    if (daysBetween <= 5 && monthsBetween === 0 && yearsBetween === 0)
      return (
        <span className="card-label fw-bolder fs-3 mb-1">¡Nuevo!</span>
      )
  }

  return (
    <>
      <ToolBar titulo={"Inicio"} hitos={[{ titulo: "Inicio", enlace: "/" }]} />
      <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
        <div className="content flex-row-fluid" id="kt_content">
          {loading || loadingSubscriptionProducts || loadingExtracurricularActivity ? (
            <LoadingBox variant={"primary"} />
          ) :
            error ? (
              <AlertMessage variant={"danger"} message={error} />
            ) : errorSubscriptionProducts ? (
              <AlertMessage variant={"danger"} message={errorSubscriptionProducts} />
            ) : errorExtracurricularActivity ? (
              <AlertMessage variant={"danger"} message={errorExtracurricularActivity} />
            ) : (
              <div className="row g-5 g-xl-8">
                {availableProducts.map((product) => (
                  product.active && (
                    <div key={product._id} className="col-xl-4 col-md-6">
                      <div className="card card-xl-stretch mb-xl-3">
                        <div className="card-header border-0 py-5">
                          <h3 className="card-title align-items-start flex-column">
                            {mostrarLabel(product.createdAt)}
                          </h3>
                        </div>
                        <div className="card-body d-flex flex-column">
                          <Product key={product._id} product={product}></Product>
                        </div>
                      </div>
                    </div>
                  )
                ))}
                {availableSubscriptionProducts.map(subscriptionProduct => (
                  subscriptionProduct.active && (
                    <div key={subscriptionProduct._id} className="col-xl-4 col-md-6">
                      <div className="card card-xl-stretch mb-xl-3">
                        <div className="card-header border-0 py-5">
                          <h3 className="card-title align-items-start flex-column">
                            {mostrarLabel(subscriptionProduct.createdAt)}
                          </h3>
                        </div>
                        <div className="card-body d-flex flex-column">
                          <SubscriptionProduct key={subscriptionProduct._id} subscriptionProduct={subscriptionProduct}></SubscriptionProduct>
                        </div>
                      </div>
                    </div>
                  )
                ))}
                {availableExtracurricularActivities.map(extracurricularActivity => (
                  extracurricularActivity.active && (
                    <div key={extracurricularActivity._id} className="col-xl-4 col-md-6">
                      <div className="card card-xl-stretch mb-xl-3">
                        <div className="card-header border-0 py-5">
                          <h3 className="card-title align-items-start flex-column">
                            {mostrarLabel(extracurricularActivity.createdAt)}
                          </h3>
                        </div>
                        <div className="card-body d-flex flex-column">
                          <ExtracurricularActivity key={extracurricularActivity._id} extracurricularActivity={extracurricularActivity}></ExtracurricularActivity>
                        </div>
                      </div>
                    </div>
                  )
                ))}
              </div>
            )
          }
        </div>
      </div>
    </>

  )
}
