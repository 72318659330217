import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsUser } from '../actions/notificationActions';

export const HomeScreen = () => {

  const dispatch = useDispatch();

  const userSignin = useSelector(state => state.userSignin)
  const {
    userInfo
} = userSignin;


  useEffect(() => {
    if (userInfo) {
      dispatch(getNotificationsUser());
    }
    
  }, [dispatch, userInfo])
  return (
    <>
      <style>{`body {background-image: none !important}`}</style>
      <div id="home-container">
        <div id='home-container-gradient'>
          <div className='row text-white m-0 text-center'>
            <div className='col-12 p-0 mb-3'>
              <img src='/assets/media/logos/elcarmen-white.png' alt='Logo' className='home-logo' />
            </div>
            <div className='col-12 mt-2 d-flex justify-content-center p-0'>
              <div id='home-content'>
                <h2 className='text-white'>¡HOLA!</h2>
                <p className='fs-sm-1 home-paragraph'>
                  Accede al pago de todas las actividades complementarias de tu hijo/a de una manera rápida y sencilla.
                </p>
              </div>
            </div>
            <div className='col-12 mt-2 d-flex justify-content-center p-0'>
                <p className='fs-sm-1 home-paragraph'>
                  ¡COMENZAMOS!
                </p>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
