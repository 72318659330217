import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { findOrdersPaidByDate } from '../actions/orderActions'
import { listAttributes } from '../actions/attributeActions';
import { PDFDownloadLink } from '@react-pdf/renderer'
import { listProducts } from '../actions/productActions'
import { ToolBar } from '../components/ToolBar'
import OrdersInvoice from '../components/OrdersInvoice'
import { LoadingBox } from '../components/LoadingBox';
import { findSubscriptionsPaidByDate } from '../actions/subscriptionActions';
import { findExtracurricularActivityInscriptionsPaidByDate } from '../actions/extracurricularActivityInscriptionActions';
import { listSubscriptionProducts } from '../actions/subscriptionProductActions';
import { listExtracurricularActivities } from '../actions/extracurricularActivityActions';

export const InvoiceScreen = (props) => {
    const attributeList = useSelector((state) => state.attributeList);
    const { attributes } = attributeList;

    const {products} = useSelector(state => state.productList)
    const {subscriptionProducts} = useSelector(state => state.subscriptionProductList)
    const {extracurricularActivities} = useSelector(state => state.extracurricularActivityList)

    const {orders, loading: loadingOrders} = useSelector(state => state.ordersPaidByDate)
    const {subscriptions, loading: loadingSubscriptions} = useSelector(state => state.subscriptionsPaidByDate)
    const {extracurricularActivityInscriptions, loading: loadingExtracurricularActivityInscriptions} = useSelector(state => state.extracurricularActivityInscriptionsPaidByDate)

    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [submitted, setSubmitted] = useState(false)
    
    const submitHandler = (e) => {
        e.preventDefault()
        setSubmitted(true)
        dispatch(findOrdersPaidByDate({
            minYear: startDate.split('-')[0],
            minMonth: startDate.split('-')[1],
            minDay: startDate.split('-')[2],
            maxYear: endDate.split('-')[0],
            maxMonth: endDate.split('-')[1],
            maxDay: endDate.split('-')[2]
        }))
        dispatch(findSubscriptionsPaidByDate({
            minYear: startDate.split('-')[0],
            minMonth: startDate.split('-')[1],
            minDay: startDate.split('-')[2],
            maxYear: endDate.split('-')[0],
            maxMonth: endDate.split('-')[1],
            maxDay: endDate.split('-')[2]
        }))
        dispatch(findExtracurricularActivityInscriptionsPaidByDate({
            minYear: startDate.split('-')[0],
            minMonth: startDate.split('-')[1],
            minDay: startDate.split('-')[2],
            maxYear: endDate.split('-')[0],
            maxMonth: endDate.split('-')[1],
            maxDay: endDate.split('-')[2]
        }))
    }

    useEffect(()=>{
        dispatch(listAttributes())
        dispatch(listProducts())
        dispatch(listSubscriptionProducts())
        dispatch(listExtracurricularActivities())
    }, [dispatch])

    return (
        <>
            <ToolBar titulo={"Facturas"} hitos={[
                {titulo: "Inicio", enlace: "/" },
                {titulo: "Facturas", enlace: "#" }]}/>
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <div className="card card-flush">
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Descarga de facturas</h2>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={submitHandler}>
                                <div className='row align-items-end'>
                                    <div className='col-5'>
                                        <label className='required form-label'>Fecha de inicio</label>
                                        <input required type="date" onChange={(e) => {setStartDate(e.target.value)}} className="form-control" value={startDate || ''} />
                                    </div>
                                    <div className='col-5'>
                                        <label className='required form-label'>Fecha de fin</label>
                                        <input required type="date" onChange={(e) => {setEndDate(e.target.value)}} className="form-control" value={endDate || ''} />
                                    </div>
                                    <div className='col-2'>
                                        <button type='submit' className='btn btn-primary'>
                                            {
                                                loadingOrders || loadingSubscriptions || loadingExtracurricularActivityInscriptions
                                                ?
                                                    <LoadingBox variant={"primary"}/>
                                                :
                                                    `Buscar`
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {
                                orders?.length > 0 || subscriptions?.length > 0 || extracurricularActivityInscriptions?.length > 0
                                ?
                                    <div className='row mt-5'>
                                        <div className='col-12 d-grid gap-2'>
                                            <PDFDownloadLink document={<OrdersInvoice start={startDate} end={endDate} orders={orders} subscriptions={subscriptions} extracurricularActivityInscriptions={extracurricularActivityInscriptions} productsList={products} subscriptionProductsList={subscriptionProducts} extracurricularActivitiesList={extracurricularActivities} attributesList={attributes} />} fileName={'factura_.pdf'}>
                                                <button type="button" className="btn btn-primary">Descargar facturas</button>
                                            </PDFDownloadLink>
                                        </div>
                                    </div>
                                :
                                    submitted && !(loadingOrders || loadingSubscriptions || loadingExtracurricularActivityInscriptions)
                                    ?
                                        <div className='row mt-5'>
                                            <div className='col-12 text-danger'>
                                                No se han encontrado facturas
                                            </div>
                                        </div>
                                    :
                                        <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
