import React from 'react'
import { Link } from 'react-router-dom';

export const ToolBar = (props) => {
  const {hitos, titulo} = props;

  return (
    <div className="toolbar py-5 py-lg-15" id="kt_toolbar">    
        <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">        
            <div className="page-title d-flex flex-column me-3">            
                <h1 className="d-flex text-white fw-bolder my-1 fs-3">{titulo}</h1>                        
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">  
                {hitos.map((hito, i) => (                    
                
                    <li key={i} className="breadcrumb-item text-white opacity-75 pe-0">
                        <Link to={hito.enlace} className="text-white text-hover-primary">{hito.titulo}</Link> 
                        {hitos.length !== i+1 && (
                        <span className="bullet bg-white opacity-75 w-5px h-2px mx-3"></span>
                        )}
                    </li>

                ))}                                                                             
                </ul>            
            </div>                              
        </div>    
    </div>
  )
}
