// Import the functions you need from the SDKs you need
import Axios from "axios";
import {
    initializeApp
} from "firebase/app";
import {
    getMessaging,
    onMessage,
    getToken
} from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCMmRBjUqZg9dk12hjFt3O7KhXwUkwHYAw",
    authDomain: "elcarmen-fe510.firebaseapp.com",
    projectId: "elcarmen-fe510",
    storageBucket: "elcarmen-fe510.appspot.com",
    messagingSenderId: "272928017274",
    appId: "1:272928017274:web:74aee02a3fff24547624c3"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);




export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

export const getUserToken = (id = null) => {
    try {
        return getToken(messaging, {
            vapidKey: 'BD9df5A5GnL_uQ_R1iYyQ3rYYvKy0fFta3FLCT8aO_GDObmvnAac92MaIdAFEL5vYzJbdzP1e0z8QcWEyh4xKYM'
        }).then((currentToken) => {
            if (currentToken && localStorage.getItem('fcm') === null) {
                if (id) {
                    Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/pushnotifications/checktoken', {
                        user: id,
                        token: {
                            currentToken
                        }
                    });
                    localStorage.setItem('fcm', currentToken);
                } else {
                    // setTokenFound(true);
                    Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/pushnotifications/checktoken', {
                        user: JSON.parse(sessionStorage.getItem('userInfo'))._id,
                        token: {
                            currentToken
                        }
                    });
                    localStorage.setItem('fcm', currentToken);
                }
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                // setTokenFound(false);
                // shows on the UI that permission is required 
            }
        }).catch((err) => {
            if (localStorage.getItem('fcm')) {
                Axios.delete(process.env.REACT_APP_DB_ADDRESS + '/api/pushnotifications/' + localStorage.getItem('fcm'));
                localStorage.removeItem('fcm');
            }
            // console.log('An error occurred while retrieving token. ', err);
            // catch error while creating client token
        });
    } catch (error) {
        
    }
}

export const removeUserToken = () => {
    if (localStorage.getItem('fcm')) {
        Axios.delete(process.env.REACT_APP_DB_ADDRESS + '/api/pushnotifications/' + localStorage.getItem('fcm'));
        localStorage.removeItem('fcm');
    }
}