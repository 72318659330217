import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { detailsUser, updateUserProfile } from '../actions/userActions';
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants';
import Swal from 'sweetalert2'
import { ToolBar } from '../components/ToolBar';
import { LoadingBox } from '../components/LoadingBox';
import { AlertMessage } from '../components/AlertMessage';
import { Link, useSearchParams } from 'react-router-dom';
import { getNotificationsUser } from '../actions/notificationActions';

export const ProfileScreen = () => {
    const [searchParms] = useSearchParams();
    const isFirstLogin = searchParms.get("isFirstLogin");

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [dni, setDni] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [address, setAddress] = useState('');
    const [image, setImage] = useState('')

    const [passwordShow, setPasswordShow] = useState(false)
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)

    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;
    
    const userDetails = useSelector(state => state.userDetails);
    const { loading, error, user } = userDetails;
    
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [errorUpload, setErrorUpload] = useState('');

    const userUpdateProfile = useSelector(state => state.userUpdateProfile);
    // eslint-disable-next-line
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = userUpdateProfile;

    const dispatch = useDispatch();
    
    useEffect(() => {        
        if(!user) {
            dispatch({ type: USER_UPDATE_PROFILE_RESET})
            dispatch(detailsUser(userInfo._id))
        } else {
            setName(user.name);
            setEmail(user.email);      
            setDni(user.dni);  
            setPhone(user.phone) 
            setCity(user.userAddress?.city) 
            setState(user.userAddress?.state) 
            setPostalCode(user.userAddress?.postalCode)
            setAddress(user.userAddress?.address)
            setImage(user.userAddress?.image)
        }
        dispatch(getNotificationsUser());
    }, [dispatch, userInfo._id, user])

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];
        const bodyFormData = new FormData();
        bodyFormData.append('image', file);
        setLoadingUpload(true);
        try {
            const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })
            setImage(data);
            setLoadingUpload(false)
        } catch (error) {
            setErrorUpload(error.message);
            setLoadingUpload(false);
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        // dispatch update
        if(password !== confirmPassword) {
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text: 'Las contraseñas no coinciden'
            })            
        } else {
            dispatch(updateUserProfile({ userId: user._id, name, email, password, address, city, state, postalCode, phone, image, dni}))
            
            setPassword('');
            setConfirmPassword('');
            Swal.fire({
                showConfirmButton: true,
                icon: 'success',
                text: 'Perfil actualizado'
            })            
        }
    }

    return ( 
    <>
    <ToolBar titulo={"Perfil"} hitos={[
        {titulo: "Inicio", enlace: "/" },
        {titulo: "Perfil", enlace: "#" }]}/>
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">						
      <div className="content flex-row-fluid" id="kt_content">
        {
        loading ? (
            <LoadingBox variant={"primary"}/>
        ) :
        error ? (
            <AlertMessage variant={"danger"} message={error}/>
        ) :            
        <>
        {loadingUpdate && (
            <LoadingBox variant={"primary"}/>
        )}  
        {errorUpdate && (
            <AlertMessage variant={"danger"} message={errorUpdate}/>
        )}  
        {/* {successUpdate && (
            <AlertMessage variant={"success"} message={"Perfil actualizado"}/>
        )}   */}
        <div className="card mb-5 mb-xl-10">
            <div className="card-body pt-9 pb-0">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                    <div className="me-7 mb-4">
                        <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                            {user.image ?
                                <img src={user.image} alt='avatar'/>
                            :
                                user.image ?
                                    <img src={user.image} alt='avatar'/>
                                :
                                    <img src="/assets/media/avatars/blank.png" alt="Avatar" />
                            }
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2">
                                    <h1 className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{name}</h1>
                                </div>
                                {user?.userChildren?.length > 0 &&              
                                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                    <div className="text-hover-primary fs-3 fw-bolder me-1">Hijos</div> 
                                    {user.userChildren.map((hijo) => (
                                        <div className="col-12" key={hijo.name}>{hijo.name}</div>
                                    ))}
                                </div>
                                }                       
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fv-row mb-2">
                    <label htmlFor="imageFile" className="form-label">Actualizar imagen de perfil</label>
                    <input className="form-control" required={false} type="file" id="imageFile" onChange={uploadFileHandler}></input>
                    {loadingUpload && <LoadingBox variant={"primary"} />}
                    {errorUpload && <div>{errorUpload}</div>}
                </div>
            </div>
        </div>
        <div className="card mb-5 mb-xl-10">
            <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                <div className="card-title m-0">
                    <h3 className="fw-bolder m-0">Mis datos personales</h3>
                </div>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show">
                <form autoComplete='off' id="kt_account_profile_details_form" className="form" onSubmit={submitHandler}>
                    <div className="card-body border-top p-9">
                        <h3 className="fw-bolder mx-0 my-5">Datos generales</h3> 
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Nombre</label>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12 fv-row">
                                        <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="name" type="text" placeholder="Introduce tu nombre" value={name} onChange={ (e) => setName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">DNI</label>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12 fv-row">
                                        <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="dni" type="text" placeholder="Introduce tu DNI/NIF" value={dni} onChange={ (e) => setDni(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">Correo electrónico</label>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12 fv-row">
                                        <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="email" type="email" placeholder="Introduce tu email" value={email} onChange={ (e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">Teléfono</label>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12 fv-row">
                                        <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="phone" type="tel" placeholder="Introduce tu teléfono" value={phone} onChange={ (e) => setPhone(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Nueva contraseña</label>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12 fv-row position-relative">
                                        <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="password" type={passwordShow ? 'text' : 'password'} placeholder="Introduce tu contraseña" onChange={ (e) => setPassword(e.target.value)} />
                                        <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={() => {setPasswordShow(!passwordShow)}}>
                                            {
                                            passwordShow 
                                            ? <i className="bi bi-eye-slash fs-2"></i>
                                            : <i className="bi bi-eye fs-2"></i>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">Confirmar contraseña</label>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12 fv-row position-relative">
                                        <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="confirmPassword" type={confirmPasswordShow ? 'text' : 'password'} placeholder="Introduce de nuevo tu contraseña" onChange={ (e) => setConfirmPassword(e.target.value)} />
                                        <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={() => {setConfirmPasswordShow(!confirmPasswordShow)}}>
                                            {
                                            confirmPasswordShow 
                                            ? <i className="bi bi-eye-slash fs-2"></i>
                                            : <i className="bi bi-eye fs-2"></i>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                        {isFirstLogin && (<AlertMessage variant={"primary"} message="La contraseña actual es la contraseña por defecto y debe ser cambiada por una más segura"/>)}                      
                        {errorUpdate && (
                            <AlertMessage variant={"danger"} message={errorUpdate}/>
                        )}                        
                        </div>

                        <h3 className="fw-bolder mx-0 mb-5 mt-10">Datos de dirección</h3>

                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">Dirección</label>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12 fv-row">
                                        <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="address" type="text" placeholder="Introduce tu dirección" value={address} onChange={ (e) => setAddress(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">Localidad</label>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12 fv-row">
                                        <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="city" type="text" placeholder="Introduce tu localidad" value={city} onChange={ (e) => setCity(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">Provincia</label>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12 fv-row">
                                        <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="state" type="text" placeholder="Introduce tu provincia" value={state} onChange={ (e) => setState(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-bold fs-6">Código postal</label>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-12 fv-row">
                                        <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="postalCode" type="text" placeholder="Introduce tu CP" value={postalCode} onChange={ (e) => setPostalCode(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit">Actualizar datos</button>
                    </div>
                    
                </form>
            </div>
        </div>
        </>
        }
      </div>
    </div>
    </>

    )
}