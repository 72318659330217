import { PRODUCT_LIST_SUCCESS, PRODUCT_LIST_FAIL, PRODUCT_DETAILS_SUCCESS, PRODUCT_DETAILS_FAIL, PRODUCT_LIST_REQUEST, PRODUCT_CREATE_REQUEST, PRODUCT_CREATE_FAIL, PRODUCT_CREATE_SUCCESS, PRODUCT_UPDATE_REQUEST, PRODUCT_UPDATE_SUCCESS, PRODUCT_UPDATE_FAIL, PRODUCT_DELETE_REQUEST, PRODUCT_DELETE_SUCCESS, PRODUCT_DELETE_FAIL, PRODUCT_DETAILS_REQUEST, CATEGORY_PRODUCT_LIST_REQUEST, CATEGORY_PRODUCT_LIST_SUCCESS, CATEGORY_PRODUCT_LIST_FAIL, PRODUCT_DUPLICATE_REQUEST, PRODUCT_DUPLICATE_FAIL, PRODUCT_DUPLICATE_SUCCESS } from '../constants/productConstants'
import Axios from 'axios';

export const listProducts = () => async(dispatch) => {
    dispatch({ type: PRODUCT_LIST_REQUEST });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/products');
        dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
    }
}

/**
 * Lista los productos de una categoría
 * @param {*} categoryId 
 * @returns 
 */
export const listCategoryProducts = (categoryId) => async(dispatch, getState) => {
    dispatch({ type: CATEGORY_PRODUCT_LIST_REQUEST, payload: categoryId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/products/category/${categoryId}`, {
			headers: { Authorization: `Bearer ${userInfo.token}` },
		});
        dispatch({ type: CATEGORY_PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORY_PRODUCT_LIST_FAIL, payload: error.message });
    }
}

export const detailsProduct = (productId) => async(dispatch) => {
    dispatch({ type: PRODUCT_DETAILS_REQUEST, payload: productId });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/products/${productId}`);
        dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const detailsProductRestricted = (productId) => async(dispatch, getState) => {
    dispatch({ type: PRODUCT_DETAILS_REQUEST, payload: productId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/products/restricted/${productId}`, {
			headers: { Authorization: `Bearer ${userInfo.token}` },
		});
        dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const getAvailableProducts = () => async(dispatch) => {
    dispatch({ type: PRODUCT_LIST_REQUEST });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/products/available');
        dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
    }
}

export const createProduct = (product) => async(dispatch, getState) => {
    dispatch({ type: PRODUCT_CREATE_REQUEST });

    const { userSignin: { userInfo } } = getState();

    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/products', product, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({ type: PRODUCT_CREATE_SUCCESS, payload: data.product })
    } catch (error) {
        dispatch({
            type: PRODUCT_CREATE_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const deleteProduct = (productId) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_DELETE_REQUEST, payload: productId });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      await Axios.delete(process.env.REACT_APP_DB_ADDRESS + `/api/products/${productId}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({ type: PRODUCT_DELETE_SUCCESS });
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({ type: PRODUCT_DELETE_FAIL, payload: message });
    }
  };

export const updateProduct = (product) => async(dispatch, getState) => {
    dispatch({ type: PRODUCT_UPDATE_REQUEST, payload: product });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/products/${product._id}`, product, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: PRODUCT_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: PRODUCT_UPDATE_FAIL, error: message });
    }
};

export const duplicateProduct = (product, copyAttr, copyCourses, copyStudents) => async(dispatch, getState) => {
    dispatch({ type: PRODUCT_DUPLICATE_REQUEST, payload: product });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + `/api/products/duplicate/${product._id}`, {
            copyAttributtes: copyAttr,
            copyCourses: copyCourses,
            copyStudents: copyStudents
        }, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: PRODUCT_DUPLICATE_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: PRODUCT_DUPLICATE_FAIL, payload: message });
    }
}