import React from 'react'

export const LoadingBox = (props) => {
  return (
    <div className='d-flex justify-content-center'>
      <div className={'spinner-border text-' + props.variant} role="status">
        <span className="sr-only spinner-border">Cargando...</span>
      </div>
    </div>
    
  )
}
