import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useRef, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { addPaymentIntent, payOrder } from "../actions/orderActions";
import {listPaymentMethods} from "../actions/paymentMethodActions"
import { AddPaymentMethod } from "./AddPaymentMethod";
import { PAYMENTMETHOD_CREATE_RESET } from "../constants/paymentMethodConstants";

export function StripeCheckoutForm({order, clientSecret, user}) {
	const stripe = useStripe();
	const elements = useElements();
	
    const addPaymentMethodRef = useRef(null)

	const [message, setMessage] = useState(null);
	const [isProcessing, setIsProcessing] = useState(false);

	const [selectedCard, setSelectedCard] = useState("null")

	
    const [savePaymentMethod, setSavePaymentMethod] = useState(true)

	const [startPurchase, setStartPurchase] = useState(false)

	const {paymentMethods} = useSelector(state => state.paymentmethodList)

	const paymentmethodCreate = useSelector((state) => state.paymentmethodCreate);
	const {success, paymentmethod } = paymentmethodCreate;

	const dispatch = useDispatch()

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (selectedCard === 'null' && savePaymentMethod && addPaymentMethodRef.current) {
            addPaymentMethodRef.current.click()
        } else {
            setStartPurchase(true)
        }
	};

	useEffect(() => {
		dispatch(listPaymentMethods())
	}, [dispatch])

	useEffect(() => {
        if (paymentMethods?.length > 0) {
            if (paymentmethod) {
				setSelectedCard(paymentMethods[paymentMethods.length - 1]?._id)	
                setStartPurchase(true)
				dispatch({ type: PAYMENTMETHOD_CREATE_RESET});
            } else {
                setSelectedCard(paymentMethods[0]?._id || 'null')
            }
        }
    }, [paymentMethods, paymentmethod])

	useEffect(async () => {
		if (startPurchase) {
			const purchase = async () => {
				if (!stripe || !elements) {
					// Stripe.js has not yet loaded.
					// Make sure to disable form submission until Stripe.js has loaded.
					return;
				}
		
				setIsProcessing(true);
		
				if (selectedCard === 'null') {
					const { error, paymentIntent } = await stripe.confirmPayment({
						elements,
						confirmParams: {
							// Make sure to change this to your payment completion page
							return_url: `${process.env.REACT_APP_WEBPAGE_URL}/order/${order?.id}/paid`,
						},
						redirect: "if_required"
					});
		
					if (error) {
						dispatch(addPaymentIntent(order?.id || order?._id, error.payment_intent.id))
					} else {
						dispatch(addPaymentIntent(order?.id || order?._id, paymentIntent.id))
					}
		
					if (error?.type === "card_error" || error?.type === "validation_error") {
						setMessage(error.message);
					} else if(paymentIntent && paymentIntent.status === 'succeeded'){
						setMessage(null);
						dispatch(payOrder(order, {id: order?.id, status: paymentIntent.status, update_time: new Date(), email_address: order?.user?.email}));
					} else {
						setMessage("Ha ocurrido un error inesperado. Inténtelo más tarde");
					}
		
					setIsProcessing(false);
					setStartPurchase(false)
				} else {
					const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
						payment_method: order?.orderItems[0]?.alternativePayment ? paymentMethods.find(p => p._id === selectedCard).alternativeStripeId : paymentMethods.find(p => p._id === selectedCard).stripeId,
						return_url: `${process.env.REACT_APP_WEBPAGE_URL}/order/${order?.id}/paid`
					});
		
					if (error) {
						dispatch(addPaymentIntent(order?.id || order?._id, error.payment_intent.id))
					} else {
						dispatch(addPaymentIntent(order?.id || order?._id, paymentIntent.id))
					}
			
					if (error?.type === "card_error" || error?.type === "validation_error") {
						setMessage(error.message);
					} else if(paymentIntent && paymentIntent.status === 'succeeded'){
						setMessage(null);
						dispatch(payOrder(order, {id: order?.id, status: paymentIntent.status, update_time: new Date(), email_address: order?.user?.email}));
					} else {
						setMessage("Ha ocurrido un error inesperado. Inténtelo más tarde");
					}
			
					setIsProcessing(false);
					setStartPurchase(false)
				}
			}

			await purchase()
		}
	}, [startPurchase])

	return (
		<div>
			{
				paymentMethods?.length > 0 && 
				<div className='my-5'>
					<select className='form-select' name="card" value={selectedCard} onChange={(e) => setSelectedCard(e.target.value)}>
						{
							paymentMethods.map((paymentMethod, ind) => (
								<option key={ind} value={paymentMethod._id}>{paymentMethod.name || paymentMethod.last4}</option>
							))
						}
						<option value="null">Otra</option>
					</select>
				</div>
			}
			{
				selectedCard === 'null' &&
				<>
					<div className="my-5">
						<label className='form-check form-switch form-check-custom form-check-solid'>
							<input className='form-check-input' type='checkbox' checked={savePaymentMethod} onChange={e => setSavePaymentMethod(e.target.checked)} />
							<span className='form-check-label fw-bold me-4'>Guardar tarjeta</span>
						</label>
					</div>
					{
						!savePaymentMethod
							?
								<PaymentElement id="payment-element" />
							:
								<AddPaymentMethod hideSubmitBtn innerRef={addPaymentMethodRef}/>
					}
				</>
			}
			{/* Show any error or success messages */}
			{message && <div id="payment-message" className="text-danger mt-3">{message}</div>}
			<div className="d-grid gap-2 mt-3">
				<button disabled={isProcessing || !stripe || !elements} id="submit" className="btn btn-primary" onClick={(e) => handleSubmit(e)}>
						<span id="button-text">
						{isProcessing ? "Procesando ... " : "Pagar"}
						</span>
				</button>
			</div>
		</div>
	);
}