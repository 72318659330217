
const { CATEGORIE_LIST_SUCCESS, CATEGORIE_LIST_FAIL, CATEGORIE_DETAILS_REQUEST, CATEGORIE_DETAILS_SUCCESS, CATEGORIE_DETAILS_FAIL, CATEGORIE_LIST_REQUEST, CATEGORIE_UPDATE_REQUEST, CATEGORIE_UPDATE_SUCCESS, CATEGORIE_UPDATE_FAIL, CATEGORIE_UPDATE_RESET, CATEGORIE_CREATE_REQUEST, CATEGORIE_CREATE_SUCCESS, CATEGORIE_CREATE_FAIL, CATEGORIE_CREATE_RESET, CATEGORIE_DELETE_REQUEST, CATEGORIE_DELETE_SUCCESS, CATEGORIE_DELETE_FAIL, CATEGORIE_DELETE_RESET } = require('../constants/categorieConstants')

export const categorieListReducer = (
    state = { categories: [], },
    action
) => {
    switch (action.type) {
        case CATEGORIE_LIST_REQUEST:
            return { loading: true };
        case CATEGORIE_LIST_SUCCESS:
            return { loading: false, categories: action.payload };
        case CATEGORIE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const categoryDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case CATEGORIE_DETAILS_REQUEST:
            return { loading: true };
        case CATEGORIE_DETAILS_SUCCESS:
            return { loading: false, category: action.payload };
        case CATEGORIE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}


export const categoryUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORIE_UPDATE_REQUEST:
            return { loading: true };
        case CATEGORIE_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case CATEGORIE_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case CATEGORIE_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};


export const categoryCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORIE_CREATE_REQUEST:
            return { loading: true };
        case CATEGORIE_CREATE_SUCCESS:
            return { loading: false, success: true, category: action.payload };
        case CATEGORIE_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case CATEGORIE_CREATE_RESET:
            return {};
        default:
            return state;
    }
}

export const categoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORIE_DELETE_REQUEST:
            return { loading: true };
        case CATEGORIE_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CATEGORIE_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case CATEGORIE_DELETE_RESET:
            return {};
        default:
            return state;
    }
};
