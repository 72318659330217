import { NOTIFICATION_GET_ALL, NOTIFICATION_GET_USER, NOTIFICATION_READ } from "../constants/notificationConstants";

export const notificationReducer = (state = [], action) => {
    switch (action.type) {
        case NOTIFICATION_GET_ALL:
            return action.payload.data;
        case NOTIFICATION_GET_USER:
            return action.payload.data
        case NOTIFICATION_READ:
            return state.map((notification) => {
                if (notification._id === action.payload.notification._id) {
                    return {...notification, read: true}
                } else {
                    return notification
                }
            })
        default:
            return state;
    }
}