export const CATEGORIE_LIST_REQUEST = 'CATEGORIE_LIST_REQUEST';
export const CATEGORIE_LIST_SUCCESS = 'CATEGORIE_LIST_SUCCESS';
export const CATEGORIE_LIST_FAIL = 'CATEGORIE_LIST_FAIL';

export const CATEGORIE_DETAILS_REQUEST = 'CATEGORIE_DETAILS_REQUEST';
export const CATEGORIE_DETAILS_SUCCESS = 'CATEGORIE_DETAILS_SUCCESS';
export const CATEGORIE_DETAILS_FAIL = 'CATEGORIE_DETAILS_FAIL';

export const CATEGORIE_CREATE_REQUEST = 'CATEGORIE_CREATE_REQUEST';
export const CATEGORIE_CREATE_SUCCESS = 'CATEGORIE_CREATE_SUCCESS';
export const CATEGORIE_CREATE_FAIL = 'CATEGORIE_CREATE_FAIL';
export const CATEGORIE_CREATE_RESET = 'CATEGORIE_CREATE_RESET';

export const CATEGORIE_DELETE_REQUEST = 'CATEGORIE_DELETE_REQUEST';
export const CATEGORIE_DELETE_SUCCESS = 'CATEGORIE_DELETE_SUCCESS';
export const CATEGORIE_DELETE_FAIL = 'CATEGORIE_DELETE_FAIL';
export const CATEGORIE_DELETE_RESET = 'CATEGORIE_DELETE_RESET';

export const CATEGORIE_UPDATE_REQUEST = 'CATEGORIE_UPDATE_REQUEST';
export const CATEGORIE_UPDATE_SUCCESS = 'CATEGORIE_UPDATE_SUCCESS';
export const CATEGORIE_UPDATE_FAIL = 'CATEGORIE_UPDATE_FAIL';
export const CATEGORIE_UPDATE_RESET = 'CATEGORIE_UPDATE_RESET';


