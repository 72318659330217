import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar'
import Swal from 'sweetalert2';
import { getNotificationsUser } from '../actions/notificationActions';
import { deletePaymentMethod, listPaymentMethods } from '../actions/paymentMethodActions';
import { PAYMENTMETHOD_DELETE_RESET } from '../constants/paymentMethodConstants';
import { AddPaymentMethod } from '../components/AddPaymentMethod';

export const PaymentMethodListScreen = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const paymentmethodList = useSelector((state) => state.paymentmethodList);
	const { loading, error, paymentMethods } = paymentmethodList;

	const paymentmethodCreate = useSelector((state) => state.paymentmethodCreate);
	const {loading: loadingCreate, success: successCreate, paymentmethod: createdPaymentMethod } = paymentmethodCreate;
  
	const paymentmethodDelete = useSelector((state) => state.paymentmethodDelete);
	const { loading: loadingDelete, error: errorDelete, success: successDelete } = paymentmethodDelete;


	const deleteHandler = (paymentmethod) => {
		Swal.fire({
			title: `¿Desea eliminar el la tarjeta terminada en ${paymentmethod.last4} ?`,
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: 'Si',
			denyButtonText: 'No',
			cancelButtonText: "Cancelar",
			icon: "warning",
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(deletePaymentMethod(paymentmethod._id));
				Swal.fire('Tarjeta eliminada', '', 'success')
			}
		})
	}

	useEffect(() => {
		if (successDelete) {
			dispatch({ type: PAYMENTMETHOD_DELETE_RESET });
		}
		dispatch(listPaymentMethods());
		dispatch(getNotificationsUser());
	}, [dispatch, createdPaymentMethod, navigate, successCreate, successDelete])



	return (
		<>
			<ToolBar titulo={"Cursos"} hitos={[
				{ titulo: "Inicio", enlace: "/" },
				{ titulo: "Cursos", enlace: "#" },
			]} />
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
				<div className="content flex-row-fluid" id="kt_content">
					{loadingDelete && (<LoadingBox variant={"primary"}/>)}
					{errorDelete && (<AlertMessage variant={"danger"} message={errorDelete}/>)}
					{loadingCreate && (<LoadingBox variant={"primary"}/>)}
					{loading ? (<LoadingBox variant={"primary"} />) :
						error ? (<AlertMessage variant={"danger"} message={error} />) : (
							<div className="card card-flush">
								<div className="card-header align-items-center py-5 gap-2 gap-md-5">
									<div className="card-toolbar flex-row-fluid justify-content-end gap-5">
										<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
											Crear tarjeta
										</button>
									</div>
								</div>
								<div className="card-body pt-0">
									<div className='row'>
										{
											paymentMethods?.map((paymentMethod, index) => (
												<div className='col col-3' key={index}>
													<div className='card border'>
														<div className='card-header align-items-center py-5'>
															<div className='card-toolbar flex-row-fluid justify-content-between'>
																<span className='fw-bold'>{paymentMethod.name}</span>
																<button type='button' className='btn btn-danger btn-sm p-1' onClick={() => deleteHandler(paymentMethod)}><i className='bi bi-trash-fill px-2'/></button>
															</div>
														</div>
														<div className='card-body'>
															<span className='text-muted'>**** **** **** {paymentMethod.last4}</span>
														</div>
													</div>
												</div>
											))
										}
									</div>
								</div>
							</div>
						)}
				</div>
			</div>

			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">Crear Tarjeta</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<AddPaymentMethod/>
					</div>
				</div>
			</div>
		</>
	)
}
