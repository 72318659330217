import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getNotificationsUser } from '../actions/notificationActions';
import { importOrders } from '../actions/orderActions';
import { importStudents, promoteStudents } from '../actions/studentActions';
import { importUsers } from '../actions/userActions';
import { ToolBar } from '../components/ToolBar';
import { ORDER_IMPORT_RESET } from '../constants/orderConstants';
import { STUDENTS_IMPORT_RESET } from '../constants/studentConstants';
import { USERS_IMPORT_RESET } from '../constants/userConstants';


export const ImportScreeen = () => {

    const dispatch = useDispatch();

    const [importOption, setImportOption] = useState('');
    const [file, setFile] = useState();
    const [array, setArray] = useState([]);
    const [loading, setLoading] = useState(false)

    const studentsImported = useSelector(state => state.studentsImport);

    const usersImported = useSelector(state => state.usersImport);

    const ordersImported = useSelector(state => state.ordersImport);

    const fileReader = new FileReader();

    const csvFileToArray = async(string) => {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(";");
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    
        const arrayValues = csvRows.map(i => {
          const values = i.split(";");
          const obj = csvHeader.reduce((object, header, index) => {
            object[header.toLowerCase()] = values[index];
            return object;
          }, {});
          return obj;
        });
        
        setArray(arrayValues);
    };

    /**
     * 
     * @param {*} e 
     */
    const submitHandler = (e) => {
        e.preventDefault()
        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(file);
            setLoading(true)
        }
    }

    const handleImportTypeChange = (e) => {
        setImportOption(e.target.value)
    }

    const headerKeys = Object.keys(Object.assign({}, ...array));

    useEffect(() => {
        if (importOption.length > 0 && array.length > 0 && loading) {
            if (importOption === 'Students') {
                const students = [];
                array.forEach((student) => {
                    if (student['alumno/a'] && student['n.º id. escolar'] && student['dni/pasaporte primer tutor']) {
                        let studentObj = {
                            code: student['n.º id. escolar'],
                            course: student['unidad'],
                            name: student['alumno/a'],
                            userParents: [student['dni/pasaporte primer tutor'], student['dni/pasaporte segundo tutor']]
                        }
                        students.push(studentObj)
                    }
                })
                dispatch(importStudents(students))
            }
            if (importOption === 'Promote') {
                const students = [];
                array.forEach((student) => {
                    if (student['n.º id. escolar'] && student['unidad']) {
                        let studentObj = {
                            code: student['n.º id. escolar'],
                            course: student['unidad']
                        }
                        students.push(studentObj)
                    }
                })
                dispatch(promoteStudents(students))
            }
            if (importOption === 'Users') {
                const parents = []
                array.forEach(parent => {
                    if (parent.nombre && parent.contrasena && parent.dni) {
                        let parentObj = {
                            name: parent.nombre,
                            password: parent.contrasena,
                            dni: parent.dni,
                            phone: parent.telefono,
                            userAddress: {
                                city: parent.localidad,
                                state: parent.provincia,
                                postalCode: parent.codigopostal,
                                address: parent.direccion
                            }
                        }
                        parents.push(parentObj)
                    }
                })
                dispatch(importUsers(parents))
            }
            if (importOption === 'Orders') {
                const orders = []
                array.forEach(order => {
                    if (order['número de pedido'] && (order['importe'] || order[`"importe"`] ||  order[`"importe "`])) {
                        let orderObj = {
                            orderReference: order['número de pedido'],
                            itemsPrice: order['importe'] || order[`"importe"`] || order[`"importe "`]
                        }
                        orders.push(orderObj)
                    } else {
                        let orderObj = {
                            orderReference: order['número de pedido'],
                            itemsPrice: order['importe'] || order[`"importe"`] || order[`"importe "`]
                        }
                        let i = 0
                        for (const prop in order) {
                            if (i < 1) {
                                orderObj.orderReference = order[prop]
                            } else {
                                orderObj.itemsPrice = parseFloat(order[prop])
                            }
                            i++
                        }
                        orders.push(orderObj)
                    }
                })
                dispatch(importOrders(orders))
            }
        }
    }, [array, importOption, dispatch, loading])

    useEffect(() => {
        if (studentsImported.success === true) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Alumnos importados correctamente',
                showConfirmButton: false,
                timer: 1500
            })
            setLoading(false)
            dispatch({type: STUDENTS_IMPORT_RESET})
            setImportOption('')
        }
    }, [studentsImported.success, dispatch])

    useEffect(() => {
        if (usersImported.success) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Padres importados correctamente',
                showConfirmButton: false,
                timer: 1500
            })
            setLoading(false)
            dispatch({type: USERS_IMPORT_RESET})
            setImportOption('')
        }
    }, [usersImported.success, dispatch])

    useEffect(() => {
        if (ordersImported.success === true) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Pedidos importados correctamente',
                showConfirmButton: false,
                timer: 1500
            })
            setLoading(false)
            dispatch({type: ORDER_IMPORT_RESET})
            setImportOption('')
        }
    }, [ordersImported.success, dispatch])

    useEffect(() => {
        dispatch(getNotificationsUser());
    }, [dispatch])


    return (
        <>
            <ToolBar titulo={"Importador"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Importador", enlace: "#" }]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <form autoComplete='off' id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row" onSubmit={submitHandler} >
                        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-12">
                            <div className="d-flex flex-column gap-7 gap-lg-10">
                                <div className="card card-flush py-4">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h2>Importador</h2>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="row fv-row mb-7">
                                            <div className="col-md-3 text-md-end">
                                                <label className="fs-6 fw-bold form-label mt-3">
                                                    <span>¿Qué va a importar?</span>
                                                </label>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="w-100">
                                                    <select value={importOption} className="form-select form-select-solid" onChange={handleImportTypeChange}>
                                                        <option value="" disabled>Seleccione una opción</option>
                                                        <option value="Users">Padres</option>
                                                        <option value="Students">Alumnos</option>
                                                        <option value="Promote">Promocionar alumnos</option>
                                                        <option value="Orders">Pedidos</option>
                                                    </select>
                                                </div>
                                                {
                                                    importOption === 'Users'
                                                    ?
                                                        <div className="fs-7 mt-2">
                                                            <a download className='text-primary' href='https://bucket-sdschool.s3.eu-west-3.amazonaws.com/importacion_padres.csv'>
                                                                <i className='fas fa-download'></i> Descargue aquí un archivo de ejemplo
                                                            </a>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                                {
                                                    importOption === 'Students'
                                                    ?
                                                        <div className="fs-7 mt-2">
                                                            <a download className='text-primary' href='https://bucket-sdschool.s3.eu-west-3.amazonaws.com/importacion_hijos.csv'>
                                                                <i className='fas fa-download'></i> Descargue aquí un archivo de ejemplo
                                                            </a>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                                {
                                                    importOption === 'Promote'
                                                    ?
                                                        <div className="fs-7 mt-2">
                                                            <a download className='text-primary' href='https://bucket-sdschool.s3.eu-west-3.amazonaws.com/promocionador_hijos.csv'>
                                                                <i className='fas fa-download'></i> Descargue aqui un archivo de ejemplo
                                                            </a>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                                {
                                                    importOption === 'Orders'
                                                    ?
                                                        <div className="fs-7 mt-2">
                                                            <a download className='text-primary' href='https://bucket-tienda.s3.eu-west-3.amazonaws.com/importacion_pedidos.csv'>
                                                                <i className='fas fa-download'></i> Descargue aqui un archivo de ejemplo
                                                            </a>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="row fv-row mb-7">
                                            <div className="col-md-3 text-md-end">
                                                <label className="fs-6 fw-bold form-label mt-3">
                                                    <span>Seleccione el fichero</span>
                                                </label>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="w-100">
                                                    <input className="form-control" required={false} type="file" id="file" accept={".csv"} onChange={(e) => setFile(e.target.files[0])}></input>
                                                    <div className="text-muted fs-7">El archivo ha de estar en formato CSV delimitado por comas.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {headerKeys.length > 0 && 
                            (<div className="d-flex flex-column gap-7 gap-lg-10">
                                <div className="card card-flush py-4">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h2>Resultados</h2>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                    <table className='w-100'>
                                        <thead>
                                        <tr key={"header"}>
                                            {headerKeys.map((key, index) => (
                                            <th key={index}>{key}</th>
                                            ))}
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {array.map((item, index) => (
                                            <tr key={index}>
                                            {Object.values(item).map((val, index) => (
                                                <td key={index}>{val}</td>
                                            ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                            )}
                            <div className="d-flex justify-content-end">
                                <Link to="/home" id="kt_ecommerce_add_product_cancel" className="btn btn-light me-5">Cancelar</Link>
                                <button type="submit" id="kt_ecommerce_add_product_submit" className={`btn btn-primary ${loading ? 'disabled' : ''}`}>
                                    {loading
                                    ?
                                        <span className="spinner-border spinner-border-sm align-middle" />
                                    :
                                        <span className="indicator-label">Importar</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>

    )
}

