export const PAYMENTMETHOD_LIST_REQUEST = 'PAYMENTMETHOD_LIST_REQUEST';
export const PAYMENTMETHOD_LIST_SUCCESS = 'PAYMENTMETHOD_LIST_SUCCESS';
export const PAYMENTMETHOD_LIST_FAIL = 'PAYMENTMETHOD_LIST_FAIL';

export const PAYMENTMETHOD_DETAILS_REQUEST = 'PAYMENTMETHOD_DETAILS_REQUEST';
export const PAYMENTMETHOD_DETAILS_SUCCESS = 'PAYMENTMETHOD_DETAILS_SUCCESS';
export const PAYMENTMETHOD_DETAILS_FAIL = 'PAYMENTMETHOD_DETAILS_FAIL';

export const PAYMENTMETHOD_CREATE_REQUEST = 'PAYMENTMETHOD_CREATE_REQUEST';
export const PAYMENTMETHOD_CREATE_SUCCESS = 'PAYMENTMETHOD_CREATE_SUCCESS';
export const PAYMENTMETHOD_CREATE_FAIL = 'PAYMENTMETHOD_CREATE_FAIL';
export const PAYMENTMETHOD_CREATE_RESET = 'PAYMENTMETHOD_CREATE_RESET';

export const PAYMENTMETHOD_DELETE_REQUEST = 'PAYMENTMETHOD_DELETE_REQUEST';
export const PAYMENTMETHOD_DELETE_SUCCESS = 'PAYMENTMETHOD_DELETE_SUCCESS';
export const PAYMENTMETHOD_DELETE_FAIL = 'PAYMENTMETHOD_DELETE_FAIL';
export const PAYMENTMETHOD_DELETE_RESET = 'PAYMENTMETHOD_DELETE_RESET';