export const EXTRACURRICULARACTIVITY_LIST_REQUEST = 'EXTRACURRICULARACTIVITY_LIST_REQUEST';
export const EXTRACURRICULARACTIVITY_LIST_SUCCESS = 'EXTRACURRICULARACTIVITY_LIST_SUCCESS';
export const EXTRACURRICULARACTIVITY_LIST_FAIL = 'EXTRACURRICULARACTIVITY_LIST_FAIL';

export const EXTRACURRICULARACTIVITY_DETAILS_REQUEST = 'EXTRACURRICULARACTIVITY_DETAILS_REQUEST';
export const EXTRACURRICULARACTIVITY_DETAILS_SUCCESS = 'EXTRACURRICULARACTIVITY_DETAILS_SUCCESS';
export const EXTRACURRICULARACTIVITY_DETAILS_FAIL = 'EXTRACURRICULARACTIVITY_DETAILS_FAIL';

export const EXTRACURRICULARACTIVITY_CREATE_REQUEST = 'EXTRACURRICULARACTIVITY_CREATE_REQUEST';
export const EXTRACURRICULARACTIVITY_CREATE_SUCCESS = 'EXTRACURRICULARACTIVITY_CREATE_SUCCESS';
export const EXTRACURRICULARACTIVITY_CREATE_FAIL = 'EXTRACURRICULARACTIVITY_CREATE_FAIL';
export const EXTRACURRICULARACTIVITY_CREATE_RESET = 'EXTRACURRICULARACTIVITY_CREATE_RESET';

export const EXTRACURRICULARACTIVITY_DELETE_REQUEST = 'EXTRACURRICULARACTIVITY_DELETE_REQUEST';
export const EXTRACURRICULARACTIVITY_DELETE_SUCCESS = 'EXTRACURRICULARACTIVITY_DELETE_SUCCESS';
export const EXTRACURRICULARACTIVITY_DELETE_FAIL = 'EXTRACURRICULARACTIVITY_DELETE_FAIL';
export const EXTRACURRICULARACTIVITY_DELETE_RESET = 'EXTRACURRICULARACTIVITY_DELETE_RESET';

export const EXTRACURRICULARACTIVITY_UPDATE_REQUEST = 'EXTRACURRICULARACTIVITY_UPDATE_REQUEST';
export const EXTRACURRICULARACTIVITY_UPDATE_SUCCESS = 'EXTRACURRICULARACTIVITY_UPDATE_SUCCESS';
export const EXTRACURRICULARACTIVITY_UPDATE_FAIL = 'EXTRACURRICULARACTIVITY_UPDATE_FAIL';
export const EXTRACURRICULARACTIVITY_UPDATE_RESET = 'EXTRACURRICULARACTIVITY_UPDATE_RESET';

export const EXTRACURRICULARACTIVITY_SPLITTED_LIST_REQUEST = 'EXTRACURRICULARACTIVITY_SPLITTED_LIST_REQUEST'
export const EXTRACURRICULARACTIVITY_SPLITTED_LIST_SUCCESS = 'EXTRACURRICULARACTIVITY_SPLITTED_LIST_SUCCESS'
export const EXTRACURRICULARACTIVITY_SPLITTED_LIST_FAIL = 'EXTRACURRICULARACTIVITY_SPLITTED_LIST_FAIL'

export const EXTRACURRICULARACTIVITY_DUPLICATE_REQUEST = 'EXTRACURRICULARACTIVITY_DUPLICATE_REQUEST'
export const EXTRACURRICULARACTIVITY_DUPLICATE_FAIL = 'EXTRACURRICULARACTIVITY_DUPLICATE_FAIL'
export const EXTRACURRICULARACTIVITY_DUPLICATE_SUCCESS = 'EXTRACURRICULARACTIVITY_DUPLICATE_SUCCESS'
export const EXTRACURRICULARACTIVITY_DUPLICATE_RESET = 'EXTRACURRICULARACTIVITY_DUPLICATE_RESET'