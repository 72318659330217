import React, {useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { forgotPassword } from '../actions/userActions'
import { AlertMessage } from '../components/AlertMessage'
import { LoadingBox } from '../components/LoadingBox'
import { ToolBar } from '../components/ToolBar'

export const ForgotPasswordScreen = (props) => {
    
    const [username, setUsername] = useState('')    

    const dispatch = useDispatch();

    const userForgotPassword = useSelector((state) => state.userForgotPassword);
    const {userInfo, error, loading} = userForgotPassword;    

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(forgotPassword(username));
        setUsername('')        
    }

    useEffect(() => {        
        if (userInfo) {
            Swal.fire({
                showConfirmButton: true,
                icon: 'success',
                text: 'Email para recuperación de contraseña enviado.'
            })              
        }
    }, [userInfo])
    
    return (
    <>        
    <ToolBar titulo={"Recuperar contraseña"} hitos={[{titulo: "Inicio", enlace: "/" }, {titulo: "Recuperar contraseña", enlace: "#" }]}/>
    <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">        
        <form autoComplete='off' className="form w-100" noValidate="novalidate" id="kt_sign_in_form" onSubmit={submitHandler}>
            <div className="text-center mb-10">
                <Link to={`/`} className="symbol symbol-50px mb-3">
                    <span className="symbol-label" style={{ backgroundImage: `url(/assets/media/logos/logo-elcarmen.png)`, backgroundSize: 'contain'}}></span>
                </Link>
                <h1 className="text-dark mb-3">Recuperar contraseña</h1>                            
            </div>
            <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark">Usuario</label>
                <input type="text" id="username" placeholder="Usuario" className="form-control form-control-lg form-control-solid" value={username} required onChange={ e => setUsername(e.target.value)}/>                
            </div>
            
            <div className="text-center">
                <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">                    
                    <span className="indicator-progress">Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span> 
                    {
                    loading ? (
                        <LoadingBox variant={"primary"}/>
                    ) :                                         
                    <span className="indicator-label">Recuperar contraseña</span>                                     
                    }
                        
                </button>
                    {error && (
                        <AlertMessage variant={"danger"} message={error}/>
                    )} 

                    {userInfo && (
                        <AlertMessage variant={"success"} message={"Se ha enviado un email para la recuperación de su contraseña"}/>
                    )} 
                
            </div>            
        </form>
    </div>
    </>
    )
}
