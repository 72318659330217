import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { detailsSubscriptionProduct } from '../actions/subscriptionProductActions';
import { detailsUser } from '../actions/userActions';
import { ToolBar } from '../components/ToolBar';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { listAttributes } from '../actions/attributeActions';
import { getNotificationsUser } from '../actions/notificationActions';
import { detailsOrder } from '../actions/orderActions';
import { CART_EMPTY } from '../constants/cartConstants';
import { SubscriptionButton } from '../components/SubscriptionButton';
import { Elements } from '@stripe/react-stripe-js';
import { SUBSCRIPTION_PAYMENT_RESET } from '../constants/subscriptionConstants';
import { loadStripe } from '@stripe/stripe-js';
import Axios from 'axios';
import Swal from 'sweetalert2';

export const SubscriptionProductScreen = (props) => {

	const params = useParams();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const subscriptionProductId = params.id


	const subscriptionProductDetails = useSelector((state) => state.subscriptionProductDetails);
	const {loading, subscriptionProduct, error} = subscriptionProductDetails;

	const {subscription} = useSelector(state => state.subscriptionPaid)

	const userDetails = useSelector(state => state.userDetails)
	const [student, setStudent] = useState(null)
	const [studentsOptions, setStudentsOptions] = useState([])

	const [selectedFee, setSelectedFee] = useState(null)
	const [feesOptions, setFeesOptions] = useState([])

	const [stripePromise, setStripePromise] = useState(null)

	const userSignin = useSelector(state => state.userSignin)
	const {userInfo} = userSignin;

	const orderDetails = useSelector((state) => state.orderDetails);

	useEffect(() => {

		if (userInfo) {
			dispatch(listAttributes());
			dispatch(detailsSubscriptionProduct(subscriptionProductId));
			dispatch(detailsUser(userInfo._id))
			dispatch(getNotificationsUser());
		} else {
			navigate(`/`);
		}
	}, [dispatch, subscriptionProductId, userInfo, navigate])

	useEffect(() => {
		if (subscriptionProduct) {
			const getStripeParams = async () => {
				let endpointUrl = '/api/stripe/config'
	
				if (process.env.REACT_APP_PAYMENT_METHOD !== 'stripe' || (subscriptionProduct.alternativePayment && process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD === 'stripe')) {
					endpointUrl +='/alternativepayment'
				}
				const {data} = await Axios.get(process.env.REACT_APP_DB_ADDRESS + endpointUrl)
				return (loadStripe(data?.publishableKey))
			}


			if (userDetails.user?.userChildren?.length > 0) {
				let availableCourses = []
				subscriptionProduct?.courses.forEach(c => {
					if (userDetails.user?.userChildren.find(child => child.course === c._id)) {
						if (!availableCourses.find(co => co === c._id)) {
							availableCourses.push(c._id)
						}
					}
				})

				let children = []
				if (subscriptionProduct.courses?.length > 0) {
					userDetails.user?.userChildren.forEach(c => {
						if (availableCourses.find(course => course === c.course)) {
							children.push(c)
						}
					})
				} else {
					if (subscriptionProduct.students?.length > 0) {
						userDetails.user?.userChildren.forEach(c => {
						if (subscriptionProduct.students.find(student => student._id === c._id)) {
							children.push(c)
						}
					})
					} else {
						userDetails.user?.userChildren.forEach(c => {
							children.push(c)
						})
					}
				}
				setStudentsOptions(children)
				
				setStudent(children[0]?._id || null)
			}

			let availableFees = []

			subscriptionProduct.fees?.forEach(fee => {
				if (fee.active) {
					availableFees.push(fee)
				}
			})

			setFeesOptions(availableFees)
			setSelectedFee(availableFees[0]?.stripeId || null)

			if (process.env.REACT_APP_PAYMENT_METHOD === 'stripe' || process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD === 'stripe') {
				setStripePromise(getStripeParams())
			}
		}
	}, [userDetails, subscriptionProduct])

	useEffect(() => {
		if (localStorage.getItem('orderId')) {
			dispatch(detailsOrder(localStorage.getItem('orderId')))
		  }
	}, [dispatch])

	useEffect(() => {
		if (orderDetails.order?.isPaid) {
		  dispatch({ type: CART_EMPTY });
		  localStorage.removeItem('cartItems');
		  localStorage.removeItem('orderId');
		}
	  }, [orderDetails.order, dispatch])

	useEffect(() => {
		if (subscription) {
			Swal.fire({
				position: 'center',
				icon: 'success',
				title: 'Suscripción creada correctamente',
				showConfirmButton: false,
				timer: 1500
			})
			dispatch({type: SUBSCRIPTION_PAYMENT_RESET})
			navigate('/subscriptionhistory')
		}
	}, [subscription, dispatch, navigate])

	const handleStudentChange = (i) => {
		setStudent(i)
	}

	return (
		<>
			<ToolBar titulo={subscriptionProduct ? subscriptionProduct.name : ''} hitos={[
				{titulo: "Inicio", enlace: "/" },
				{titulo: subscriptionProduct ? subscriptionProduct.name : '', enlace: "#" }]}/>
		
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">						
				<div className="content flex-row-fluid" id="kt_content">  
					{loading ? (<LoadingBox variant={"primary"}/>) : 
					error ? (<AlertMessage variant={"danger"} message={error}/>) : (
						<div className="row g-5 g-xl-10 mb-5 mb-xl-10">    
							<div className="col-xl-7">        
								<div className="card card-flush h-lg-100">
									<div className="card-body p-10">
										<img className="img-fluid" src={subscriptionProduct.image ? subscriptionProduct.image : "/logo512.png"} alt={subscriptionProduct.name} />
									</div>            
								</div>        
							</div>        
							<div className="col-xl-5">
								<div className="card h-md-100">
									<div className="card-body d-flex flex-column">
										<h1>{subscriptionProduct.name}</h1>
										<span style={{fontSize : `2rem`}}>
											{
												feesOptions?.find(f => f.stripeId === selectedFee)?.price?.toFixed(2)
											} €
										</span>
										<p>{subscriptionProduct.description}</p>
										<div>
											{
												userDetails?.user?.userChildren ?
												<div className="row mt-5">
													<label className="form-label fw-bold mb-2 mt-3">Estudiante</label>
													<div className="col-12">
														<select value={student || ''} className="form-select" onChange={ (e) => {handleStudentChange(e.target.value)}}>
															{
																userDetails?.user?.userChildren?.map((child, index) => (
																	studentsOptions.find(s => s._id === child._id)
																	?
																		<option key={child._id} className={child._id} value={child._id}>{child.name}</option>
																	:
																		<Fragment key={index}></Fragment>
																))
															}
														</select>
													</div>
												</div>
												:
												<></>
											}
										</div>
										<div className='row mt-5'>
											<label className='form-label fw-bold mb-2 mt-3'>Tipo de suscripción</label>
											<div className='col-12'>
												<select value={selectedFee || ''} className='form-select' onChange={(e) => setSelectedFee(e.target.value)}>
													{
														feesOptions?.map((fee, index) => (
															<option key={index} value={fee.stripeId}>{fee.name}</option>
														))
													}
												</select>
											</div>
										</div>
										{
											(process.env.REACT_APP_PAYMENT_METHOD === 'stripe' || process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD === 'stripe') && stripePromise && subscriptionProduct?.catalogMode !== true &&
												<Elements stripe={stripePromise}>
													<SubscriptionButton subscriptionProduct={subscriptionProduct} fee={selectedFee} user={userDetails.user?.name} student={student} cancelAt={subscriptionProduct?.cancelAt}/>
												</Elements>
										}
									</div>
								</div>
							</div>
						</div>
						)
					}
				</div>
			</div>
		</>
	)
}