import Axios from "axios"
import { EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_LIST_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_LIST_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_LIST_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_PAYMENT_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_SALES_EXTRACURRICULARACTIVITYINSCRIPTIONPRODUCT_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_SALES_EXTRACURRICULARACTIVITYINSCRIPTIONPRODUCT_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_FAIL } from "../constants/extracurricularActivityInscriptionConstants";

export const getSalesExtracurricularActivity = (extracurricularActivityId) => async(dispatch, getState) => {
	dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_SALES_EXTRACURRICULARACTIVITYINSCRIPTIONPRODUCT_REQUEST })
	const { userSignin: { userInfo } } = getState();
	try {
		const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/extracurricularactivityinscription/extracurricularactivityinscription/extracurricularactivity/${extracurricularActivityId}`, {
			headers: { Authorization: `Bearer ${userInfo.token}` },
		});
		dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_SALES_EXTRACURRICULARACTIVITYINSCRIPTIONPRODUCT_SUCCESS, payload: data })
	} catch (error) {
		console.log(error);
	}
}

export const payExtracurricularActivityInscription = (extracurricularactivityinscriptionId) => async (dispatch, getState) => {
	const { userSignin: { userInfo } } = getState();
	try {
		const {data} = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/extracurricularactivityinscription/${extracurricularactivityinscriptionId}/pay`, {}, {
			headers: {
				Authorization: `Bearer ${userInfo?.token}`,
			}
		})

		dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_PAYMENT_SUCCESS, payload: data})
	} catch (error) {
		console.error(error)
	}
}

export const listExtracurricularActivityInscriptionMine = () => async(dispatch, getState) => {
	dispatch({ type: EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_REQUEST });
	const { userSignin: { userInfo } } = getState();
	try {
		const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/extracurricularactivityinscription/mine', {
			headers: { Authorization: `Bearer ${userInfo.token}` },
		});
		dispatch({ type: EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_SUCCESS, payload: data });
	} catch (error) {
		const message =
			error.response && error.response.data.message?
			error.response.data.message:
			error.message;
		dispatch({ type: EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_FAIL, payload: message })
	}
}

export const cancelExtracurricularActivityInscription = (stripeId, alternativePayment) => async(dispatch, getState) => {
	dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_REQUEST})

	const { userSignin: { userInfo } } = getState();

	try {
		const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/extracurricularactivityinscription/cancel', {stripeId, alternativePayment}, {
			headers: {
				Authorization: `Bearer ${userInfo?.token}`,
			}
		})

		dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_SUCCESS})
	} catch (error) {
		const message =
			error.response && error.response.data.message?
			error.response.data.message:
			error.message;
		dispatch({ type: EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_FAIL, payload: message })
	}
}

export const requestCancelExtracurricularActivityInscription = (id) => async(dispatch, getState) => {
	dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_REQUEST})

	const { userSignin: { userInfo } } = getState();

	try {
		const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/extracurricularactivityinscription/requestcancel', {id}, {
			headers: {
				Authorization: `Bearer ${userInfo?.token}`,
			}
		})

		dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_SUCCESS, payload: data})
	} catch (error) {
		const message =
			error.response && error.response.data.message?
			error.response.data.message:
			error.message;
		dispatch({ type: EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_FAIL, payload: message })
	}
}

export const listExtracurricularActivityInscriptions = () => async(dispatch, getState) => {
	dispatch({ type: EXTRACURRICULARACTIVITYINSCRIPTION_LIST_REQUEST });
	const { userSignin: { userInfo } } = getState();
	try {
		const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/extracurricularactivityinscriptions', {
			headers: { Authorization: `Bearer ${userInfo.token}` },
		});
		dispatch({ type: EXTRACURRICULARACTIVITYINSCRIPTION_LIST_SUCCESS, payload: data })
	} catch (error) {
		const message =
			error.response && error.response.data.message?
			error.response.data.message:
			error.message;
		dispatch({ type: EXTRACURRICULARACTIVITYINSCRIPTION_LIST_FAIL, payload: message })
	}
}

export const enrollExtracurricularActivity = (extracurricularActivityInscription) => async (dispatch, getState) => {
	dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_REQUEST})
	const { userSignin: { userInfo } } = getState()
	try {
		const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/extracurricularactivityinscription', extracurricularActivityInscription, {
			headers: {
				Authorization: `Bearer ${userInfo?.token}`,
			}
		})
		dispatch({type: dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_SUCCESS, payload: data})})
	} catch (error) {
		dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_FAIL})
	}
}

export const manageInscription = (extracurricularActivityInscription, accepted) => async (dispatch, getState) => {
	dispatch({ type: EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_REQUEST})
	const { userSignin: { userInfo } } = getState()
	try {
		const {data} = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/extracurricularactivityinscription/manage', {extracurricularActivityInscription, accepted}, {
			headers: {
				Authorization: `Bearer ${userInfo?.token}`,
			}
		})
		dispatch({type: dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_SUCCESS, payload: data})})
	} catch (error) {
		dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_FAIL})
	}
}

export const extracurricularActivityInscriptionDetails = (id) => async(dispatch, getState) => {
	dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_REQUEST })
	const { userSignin: { userInfo } } = getState();
	try {
		const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/extracurricularactivityinscription/${id}`, {
			headers: { Authorization: `Bearer ${userInfo.token}` },
		});
		dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_SUCCESS, payload: data })
	} catch (error) {
		dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_FAIL})
	}
}

/**
 * 
 * @param {JSON} params 
 * @returns 
 */
export const findExtracurricularActivityInscriptionsPaidByDate = (params) => async (dispatch, getState) => {
    dispatch({ type: EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + `/api/extracurricularactivityinscription/paidbydate`, params, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_FAIL, payload: message })
    }
}