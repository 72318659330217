import { EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_RESET, EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_PAYMENT_RESET, EXTRACURRICULARACTIVITYINSCRIPTION_PAYMENT_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_LIST_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_LIST_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_LIST_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_SALES_EXTRACURRICULARACTIVITYINSCRIPTIONPRODUCT_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_SALES_EXTRACURRICULARACTIVITYINSCRIPTIONPRODUCT_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_RESET, EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_RESET, EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_RESET, EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_REQUEST, EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_SUCCESS, EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_FAIL, EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_RESET } from "../constants/extracurricularActivityInscriptionConstants";


export const extracurricularActivityInscriptionPaidReducer = (state = {extracurricularActivityInscription: null}, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITYINSCRIPTION_PAYMENT_SUCCESS:
            return { extracurricularActivityInscription: action.payload }
        case EXTRACURRICULARACTIVITYINSCRIPTION_PAYMENT_RESET:
            return {extracurricularActivityInscription: null};
        default:
            return state;
    }
}

export const extracurricularActivityInscriptionMineListReducer = (state = { extracurricularActivityInscriptions: [] }, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_REQUEST:
            return { loading: true };
        case EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_SUCCESS:
            return { loading: false, extracurricularActivityInscriptions: action.payload };
        case EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const extracurricularActivityInscriptionCancelReducer = (state = { success: null }, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_REQUEST:
            return { loading: true };
        case EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_SUCCESS:
            return { loading: false, success: true };
        case EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_FAIL:
            return { loading: false, error: action.payload, success: false };
        case EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_RESET:
            return {loading: false, error: null, success: null}
        default:
            return state;
    }
}

export const extracurricularActivityInscriptionRequestCancelReducer = (state = { success: null }, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_REQUEST:
            return { loading: true };
        case EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_SUCCESS:
            return { loading: false, success: true };
        case EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_FAIL:
            return { loading: false, error: action.payload, success: false };
        case EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_RESET:
            return {loading: false, error: null, success: null}
        default:
            return state;
    }
}

export const extracurricularActivityInscriptionListReducer = (state = { extracurricularActivityInscriptions: [] }, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITYINSCRIPTION_LIST_REQUEST:
            return { loading: true };
        case EXTRACURRICULARACTIVITYINSCRIPTION_LIST_SUCCESS:
            return { loading: false, extracurricularActivityInscriptions: action.payload };
        case EXTRACURRICULARACTIVITYINSCRIPTION_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const salesExtracurricularActivityReducer = (state = {}, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITYINSCRIPTION_SALES_EXTRACURRICULARACTIVITYINSCRIPTIONPRODUCT_REQUEST:
            return {loading: true}
        case EXTRACURRICULARACTIVITYINSCRIPTION_SALES_EXTRACURRICULARACTIVITYINSCRIPTIONPRODUCT_SUCCESS:
            return {loading: false, sales: action.payload}
        default:
            return state;
    }
}

export const extracurricularActivityInscriptionEnrollReducer = (state = {success: null, error: null, loading: false}, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_REQUEST:
            return { ...state, loading: true }
        case EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_SUCCESS:
            return {...state, loading: false, success: true, error: null};
        case EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_FAIL:
            return {...state, loading: false, error: action.payload}
        case EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_RESET:
            return {success: null, error: null, loading: false}
        default:
            return state;
    }
}

export const extracurricularActivityInscriptionManagementReducer = (state = {}, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_REQUEST:
            return { ...state, loading: true }
        case EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_SUCCESS:
            return {...state, loading: false, success: true, error: null};
        case EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_FAIL:
            return {...state, loading: false, error: action.payload}
        case EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_RESET:
            return {success: null, error: null, loading: false}
        default:
            return state;
    }
}

export const extracurricularActivityInscriptionDetailsReducer = (state ={}, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_REQUEST:
            return { ...state, loading: true }
        case EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_SUCCESS:
            return {...state, loading: false, extracurricularActivityInscription: action.payload, error: null};
        case EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_FAIL:
            return {...state, loading: false, error: action.payload}
        default:
            return state;
    }
}

export const extracurricularActivityInscriptionsPaidByDateReducer = (state = {loading: false, extracurricularActivityInscriptions: []}, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_REQUEST:
            return {...state, loading: true };
        case EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_SUCCESS:
            return {...state, loading: false, extracurricularActivityInscriptions: action.payload };
        case EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_FAIL:
            return {...state, loading: false, error: action.payload };
        case EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_RESET:
            return {...state, loading: false, extracurricularActivityInscriptions: []};
        default:
            return state;
    }
}