import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { detailsExtracurricularActivity } from '../actions/extracurricularActivityActions';
import { detailsUser } from '../actions/userActions';
import { ToolBar } from '../components/ToolBar';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { listAttributes } from '../actions/attributeActions';
import { getNotificationsUser } from '../actions/notificationActions';
import { detailsOrder } from '../actions/orderActions';
import { CART_EMPTY } from '../constants/cartConstants';
import Swal from 'sweetalert2';
import { enrollExtracurricularActivity } from '../actions/extracurricularActivityInscriptionActions';
import { EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_RESET } from '../constants/extracurricularActivityInscriptionConstants';

export const ExtracurricularActivityScreen = (props) => {

	const params = useParams();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const extracurricularActivityId = params.id

	const extracurricularActivityDetails = useSelector((state) => state.extracurricularActivityDetails);
	const {loading, extracurricularActivity, error} = extracurricularActivityDetails;

	const userDetails = useSelector(state => state.userDetails)
	const [student, setStudent] = useState(null)
	const [studentsOptions, setStudentsOptions] = useState([])

	const [selectedFee, setSelectedFee] = useState(null)
	const [feesOptions, setFeesOptions] = useState([])

	const userSignin = useSelector(state => state.userSignin)
	const {userInfo} = userSignin;

	const orderDetails = useSelector((state) => state.orderDetails);

	const {success, error: errorEnroll, loading: loadingEnroll} = useSelector((state) => state.extracurricularActivityInscriptionEnroll);

	useEffect(() => {
		if (userInfo) {
			dispatch(listAttributes());
			dispatch(detailsExtracurricularActivity(extracurricularActivityId));
			dispatch(detailsUser(userInfo._id))
			dispatch(getNotificationsUser());
		} else {
			navigate(`/`);
		}
	}, [dispatch, extracurricularActivityId, userInfo, navigate])

	useEffect(() => {
		if (extracurricularActivity) {
			if (userDetails.user?.userChildren?.length > 0) {
				let availableCourses = []
				extracurricularActivity?.courses.forEach(c => {
					if (userDetails.user?.userChildren.find(child => child.course === c._id)) {
						if (!availableCourses.find(co => co === c._id)) {
							availableCourses.push(c._id)
						}
					}
				})

				let children = []
				if (extracurricularActivity.courses?.length > 0) {
					userDetails.user?.userChildren.forEach(c => {
						if (availableCourses.find(course => course === c.course)) {
							children.push(c)
						}
					})
				} else {
					if (extracurricularActivity.students?.length > 0) {
						userDetails.user?.userChildren.forEach(c => {
							if (extracurricularActivity.students.find(student => student._id === c._id)) {
								children.push(c)
							}
						})
					} else {
						userDetails.user?.userChildren.forEach(c => {
							children.push(c)
						})
					}
				}
				setStudentsOptions(children)
				
				setStudent(children[0]?._id || null)
			}

			let availableFees = []

			extracurricularActivity.fees?.forEach(fee => {
				if (fee.active) {
					availableFees.push(fee)
				}
			})

			setFeesOptions(availableFees)
			setSelectedFee(availableFees[0]?.stripeId || null)
		}
	}, [userDetails, extracurricularActivity])

	useEffect(() => {
		if (localStorage.getItem('orderId')) {
			dispatch(detailsOrder(localStorage.getItem('orderId')))
		  }
	}, [dispatch])

	useEffect(() => {
		if (orderDetails.order?.isPaid) {
		  dispatch({ type: CART_EMPTY });
		  localStorage.removeItem('cartItems');
		  localStorage.removeItem('orderId');
		}
	  }, [orderDetails.order, dispatch])

	useEffect(() => {
		if (success) {
			Swal.fire({
				position: 'center',
				icon: 'success',
				title: 'Matriculación a actividad extraescolar realizada correctamente',
				showConfirmButton: false,
				timer: 1500
			})
			dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_RESET})
			navigate('/extracurricularactivityhistory')
		}
	}, [success, dispatch, navigate])

	const handleStudentChange = (i) => {
		setStudent(i)
	}

	const enroll = () => {
		dispatch(enrollExtracurricularActivity({extracurriculumActivity: extracurricularActivity, student, user: userInfo, cancelAt: extracurricularActivity.cancelAt, startAt: extracurricularActivity.startAt, extracurricularactivityinscriptionFee: selectedFee}))
	}

	return (
		<>
			<ToolBar titulo={extracurricularActivity ? extracurricularActivity.name : ''} hitos={[
				{titulo: "Inicio", enlace: "/" },
				{titulo: extracurricularActivity ? extracurricularActivity.name : '', enlace: "#" }]}/>
		
			<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">						
				<div className="content flex-row-fluid" id="kt_content">  
					{loading ? (<LoadingBox variant={"primary"}/>) : 
					error ? (<AlertMessage variant={"danger"} message={error}/>) : (
						<div className="row g-5 g-xl-10 mb-5 mb-xl-10">    
							<div className="col-xl-7">        
								<div className="card card-flush h-lg-100">
									<div className="card-body p-10">
										<img className="img-fluid" src={extracurricularActivity.image ? extracurricularActivity.image : "/logo512.png"} alt={extracurricularActivity.name} />
									</div>            
								</div>        
							</div>        
							<div className="col-xl-5">
								<div className="card h-md-100">
									<div className="card-body d-flex flex-column">
										<h1>{extracurricularActivity.name}</h1>
										<span style={{fontSize : `2rem`}}>
											{
												feesOptions?.find(f => f.stripeId === selectedFee)?.price?.toFixed(2)
											} €
										</span>
										{
											extracurricularActivity?.startAt || extracurricularActivity?.cancelAt
											?
												<p style={{fontSize : `1.2rem`}}>
													{
														extracurricularActivity?.startAt
														?
															<span>{`${new Date(extracurricularActivity?.startAt).getDate()}/${parseInt(new Date(extracurricularActivity?.startAt).getMonth()) + 1}/${new Date(extracurricularActivity?.startAt).getFullYear()}${extracurricularActivity?.cancelAt ? '-' : ''}`}</span>
														:
															<></>
													}
													{
														extracurricularActivity?.cancelAt
														?
															<span>{`${new Date(extracurricularActivity?.cancelAt).getDate()}/${parseInt(new Date(extracurricularActivity?.cancelAt).getMonth()) + 1}/${new Date(extracurricularActivity?.cancelAt).getFullYear()}`} </span>
														:
															<></>
													}
												</p>
											:
												<></>
										}
										<p>{extracurricularActivity.description}</p>
										<div>
											{
												userDetails?.user?.userChildren ?
												<div className="row mt-5">
													<label className="form-label fw-bold mb-2 mt-3">Estudiante</label>
													<div className="col-12">
														<select value={student || ''} className="form-select" onChange={ (e) => {handleStudentChange(e.target.value)}}>
															{
																userDetails?.user?.userChildren?.map((child, index) => (
																	studentsOptions.find(s => s._id === child._id)
																	?
																		<option key={child._id} className={child._id} value={child._id}>{child.name}</option>
																	:
																		<Fragment key={index}></Fragment>
																))
															}
														</select>
													</div>
												</div>
												:
												<></>
											}
										</div>
										<div className='row mt-5'>
											<label className='form-label fw-bold mb-2 mt-3'>Tipo de actividad</label>
											<div className='col-12'>
												<select value={selectedFee || ''} className='form-select' onChange={(e) => setSelectedFee(e.target.value)}>
													{
														feesOptions?.map((fee, index) => (
															<option key={index} value={fee.stripeId}>{fee.name}</option>
														))
													}
												</select>
											</div>
										</div>
										{
											extracurricularActivity?.catalogMode !== true &&
											<div className='d-grid gap-2 mt-10'>
												<button disabled={loading || !selectedFee || !student} type='button' className='btn btn-primary' onClick={() => enroll()}>
													{
														loading
														?
															<>Espere, por favor...</>
														:
															<>Matricular</>
													}
												</button>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
						)
					}
				</div>
			</div>
		</>
	)
}