import Axios from 'axios'
import {
    STOPBULLYING_GET_ALL,
    STOPBULLYING_SEND_USER,
    STOPBULLYING_READ
} from '../constants/stopbullyingConstants';

export const getStopbullyings = () => async (dispatch, getState) => {
    try {
        const {
            userSignin: {
                userInfo
            }
        } = getState();
        const {
            data
        } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/stopbullyings/getall`, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({
            type: STOPBULLYING_GET_ALL,
            payload: {
                data
            }
        })
    } catch (error) {

    }

}

export const getStopbullyingsUser = (user) => async (dispatch, getState) => {
    try {
        const {
            userSignin: {
                userInfo
            }
        } = getState();
        const {
            data
        } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/stopbullyings/user/`+user, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({
            type: STOPBULLYING_GET_ALL,
            payload: {
                data
            }
        })
    } catch (error) {

    }

}

export const createStopbullying = (stopbullying) => async (dispatch, getState) => {
    const {
        userSignin: {
            userInfo
        }
    } = getState();

    let mediafiles = []

    try {
        if(stopbullying.media?.length > 0){
            await Promise.all(await stopbullying.media?.map(async (file) => {
                let bodyFormData = new FormData()
                bodyFormData.append('image', file)
                let { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3', bodyFormData, {
                    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
                })
                stopbullying.media.push(data)
                return data
            })).then(function(urls) {
                mediafiles = urls
                console.log(stopbullying.media)
            })
        }
        stopbullying.media = mediafiles
        const {
            data
        } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/stopbullyings/register', stopbullying, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch(getStopbullyingsUser());
        dispatch({
            type: STOPBULLYING_SEND_USER,
            payload: data
        })
    } catch (error) {}
}

export const responseStopbullying = (stopbullying) => async (dispatch, getState) => {
    const {
        userSignin: {
            userInfo
        }
    } = getState();

    let mediafiles = []

    try {
        if(stopbullying.media?.length > 0){
            await Promise.all(await stopbullying.media?.map(async (file) => {
                let bodyFormData = new FormData()
                bodyFormData.append('image', file)
                let { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3', bodyFormData, {
                    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
                })
                stopbullying.media.push(data)
                return data
            })).then(function(urls) {
                mediafiles = urls
                console.log(stopbullying.media)
            })
        }
        stopbullying.media = mediafiles
        const {
            data
        } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/stopbullyings/'+stopbullying.idstopbullying, {
            sender: stopbullying.sender,
            receiver: stopbullying.receiver,
            message: stopbullying.message,
            media: stopbullying.media
        }, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch(getStopbullyingsUser());
        dispatch({
            type: STOPBULLYING_SEND_USER,
            payload: data
        })
    } catch (error) {}
}

export const readStopbullying = (id) => async (dispatch, getState) => {
    const {
        userSignin: {
            userInfo
        }
    } = getState();
    try {
        const {
            data
        } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/stopbullyings/${id}/read`, null, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch(getStopbullyingsUser(userInfo._id))
        dispatch({
            type: STOPBULLYING_READ,
            payload: {
                stopbullying: data.stopbullying
            }
        })
    } catch (error) {

    }
}