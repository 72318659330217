import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer"
import { Table, TableHeader, TableCell } from "@david.kucsai/react-pdf-table"
import { Fragment } from "react"

export default function OrdersInvoice({orders, subscriptions, extracurricularActivityInscriptions, productsList, extracurricularActivitiesList, subscriptionProductsList, attributesList, start, end}){
	Font.register({
		family: 'Poppins',
		fonts: [
			{
			  	src: process.env.PUBLIC_URL+`/assets/fonts/Poppins-Regular.ttf`
			},
			{
			  	src: process.env.PUBLIC_URL+`/assets/fonts/Poppins-SemiBold.ttf`,
				fontWeight: 'semibold'
			},
			{
				src: process.env.PUBLIC_URL+`/assets/fonts/Poppins-Bold.ttf`,
				fontWeight: 'bold'
			}
		]
	})

	let styles = StyleSheet.create({
		logo: {
			width: 119,
			height: 70
		},
		datosCole: {
			marginTop: 20,
			marginRight: 20,
			marginLeft: 20,
			marginBottom: 20,
		},
		bloqueDatosCole: {
			display: "flex",
			flexDirection: 'row'
		},
		datosColeNegro: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 9
		},
		datosColeNormal: {
			fontFamily: 'Poppins',
			fontSize: 9
		},
		datosUserNegro: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			marginRight: 100,
			marginLeft: 100
		},
		datosUserNormal: {
			flex: 1,
			fontFamily: 'Poppins',
			fontSize: 8,
			marginRight: 100,
			marginLeft: 100
		},
		bloqueNumeroFactura: {
			width: 150,
			marginTop: 20
		},
		numeroFacturaNegro: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			marginTop: 5
		},
		numeroFacturaNormal: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			marginBottom: 5
		},
		bloqueFecha: {
			width: 180,
			marginTop: 15
		},
		fechaNegro: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			marginTop: 5
		},
		fechaNormal: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			marginBottom: 5
		},
		bloqueProductos: {
			width: 555,
			height: 440,
			marginTop: 15,
			border: 1
		},
		productoNormal: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			marginBottom: 5
		},
		bloqueProductosHeader: {
			width: 555,
			borderBottom: 1,
			display: "flex",
			flexDirection: 'row'
		},
		productoHeaderTextCenter: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 50,
			borderRight: 1
		},
		productoHeaderTextCenterLast: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 50
		},
		productoHeaderTextCenterLarge: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 305,
			borderRight: 1
		},
		bloqueProductosBody: {
			width: 555,
			height: 35,
			display: "flex",
			flexDirection: 'row',
			borderBottom: 0.2
		},
		productoBodyTextCenter: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			width: 50,
			borderRight: 1,
			paddingTop: 10,
			paddingBottom: 5
		},
		productoBodyTextCenterLast: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			width: 50,
			paddingTop: 10,
			paddingBottom: 5
		},
		productoBodyTextCenterLarge: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'left',
			width: 305,
			borderRight: 1,
			paddingTop: 5,
			paddingLeft: 10,
			paddingBottom: 5
		},
		bloqueDescuentos: {
			width: 152,
			height: 26,
			marginTop: 10,
			borderRight: 1,
			borderLeft: 1,
			borderTop: 1
		},
		bloqueDescuentosHeader: {
			width: 150,
			display: "flex",
			flexDirection: 'row',
			borderBottom: 1
		},
		descuentoHeaderTextCenter: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 50,
			borderRight: 1
		},
		descuentoHeaderTextCenterLast: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 50
		},
		bloqueDescuentosBody: {
			width: 150,
			display: "flex",
			flexDirection: 'row',
			borderBottom: 1
		},
		descuentoBodyTextCenter: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 50,
			borderRight: 1
		},
		descuentoBodyTextCenterLast: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 50
		},
		bloqueTotales: {
			width: 402,
			height: 52,
			marginTop: 10,
			border: 1
		},
		bloqueTotalesHeader: {
			width: 400,
			display: "flex",
			flexDirection: 'row',
			borderBottom: 1
		},
		bloqueTotalesBody: {
			width: 400,
			display: "flex",
			flexDirection: 'row'
		},
		totalHeaderTextCenter: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 100,
			borderRight: 1
		},
		totalHeaderTextCenterLast: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 100
		},
		totalBodyTextCenter: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			width: 100,
			borderRight: 1
		},
		totalBodyTextCenterColIz: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'left',
			width: 100,
			paddingLeft: 20
		},
		totalBodyTextCenterColDe: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'right',
			width: 100,
			borderRight: 1,
			paddingRight: 20
		},
		totalBodyTextCenterLast: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			width: 100
		},
		totalHeaderTextCenterColIz: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'left',
			width: 100,
			paddingLeft: 20
		},
		totalHeaderTextCenterColDe: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'right',
			width: 100,
			borderRight: 1,
			paddingRight: 20
		},
		bloqueTotalesFooter: {
			width: 400,
			display: "flex",
			flexDirection: 'row'
		},
		totalFooterTextCenter: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 100,
			borderRight: 1,
			borderTop: 1
		},
		totalFooterTextCenterColIz: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'left',
			width: 100,
			paddingLeft: 20,
			borderTop: 1
		},
		totalFooterTextCenterColDe: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'right',
			width: 100,
			borderRight: 1,
			paddingRight: 20,
			borderTop: 1
		},
		totalFooterTextCenterLast: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 100,
			borderTop: 1
		},
		bloqueTotalFacturaText: {
			marginTop: 8
		},
		bloqueTotalFacturaValue: {
			marginTop: 5,
			border: 1,
			width: 300,
			textAlign: 'right',
			paddingTop: 5,
			paddingBottom: 2,
			paddingLeft: 5,
			paddingRight: 5
		},
		bloqueCabeceraFacturasEmitidas: {
			width: 555,
			marginTop: 15,
			display: "flex",
			flexDirection: "row",
			borderBottom: 1
		},
		pedidoHeaderSmall: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			width: 65
		},
		pedidoHeaderLarge: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			width: 235
		},
		bloqueBodyFacturasEmitidas: {
			width: 555,
			marginTop: 6,
			display: "flex",
			flexDirection: "row"
		},
		bloqueBodyFacturasEmitidasNextPage: {
			width: 555,
			marginTop: 15,
			display: "flex",
			flexDirection: "row"
		},
		pedidoContenidoSmall: {
			fontFamily: 'Poppins',
			fontSize: 8,
			width: 65
		},
		pedidoContenidoLarge: {
			fontFamily: 'Poppins',
			fontSize: 8,
			width: 235
		},
		bloqueBodyTotalPedidos: {
			width: 555,
			marginTop: 5,
			display: "flex",
			flexDirection: "row"
		},
		totalPedidosContenido: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			width: 65
		},
		totalPedidosContenidoLarge: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			textAlign: 'right',
			fontSize: 8,
			width: 430,
			paddingRight: 50
		},
		nombreProducto: {
			marginBottom: 3
		}
	})

	// Cantidad de artículos máximos por página en la factura
	const MAX_ARTICLES = 14
	// Cantidad de pedidos/Suscripciones/Actividades extraescolares máximos por página en resumen de facturas
	const MAX_ORDERS = 38

	// Array para facturas de pedidos fraccionados
	let ordersInvoicesSplitted = []
	// Array para informacion de pedidos, suscripciones y actividades extraescolares fraccionados
	let ordersAndSubscriptionsSplitted = []


	// cantidad de productos de pedidos sin IVA
	let orderProductsNoIVA = []
	// cantidad de productos de pedidos con IVA super reducido
	let orderProductsSRIVA = []
	// cantidad de productos de pedidos con IVA reducido
	let orderProductsRIVA = []
	// cantidad de productos de pedidos con IVA
	let orderProductsIVA = []
	// total de los pedidos sin el IVA
	let totalWithoutIVA = []
	let absoluteWithoutIVA = 0
	let absoluteWithIVA = 0
	let i = 0
	orders.forEach(order => {
		// cantidad de productos sin IVA
		let orderNoIVA = 0
		// cantidad de productos con IVA super reducido
		let orderSRIVA = 0
		// cantidad de productos con IVA reducido
		let orderRIVA = 0
		// cantidad de productos con IVA
		let ordeorderProductsRIVA = 0

		// Productos sin IVA
		order.orderItems.filter(p => (p.iva === 0 && p.billable === true) || (productsList.find(prod => prod._id === p.product)?.iva === 0 && productsList.find(prod => prod._id === p.product)?.billable === true)).forEach(product => {
			orderNoIVA += parseFloat(product.price * product.qty)
		});

		// Productos con IVA super reducido
		order.orderItems.filter(p => (p.iva === 4 && p.billable === true) || (productsList.find(prod => prod._id === p.product)?.iva === 4 && productsList.find(prod => prod._id === p.product)?.billable === true)).forEach(product => {
			orderSRIVA += parseFloat(product.price * product.qty)
		});

		// Productos con IVA reducido
		
		order.orderItems.filter(p => (p.iva === 10 && p.billable === true) || (productsList.find(prod => prod._id === p.product)?.iva === 10 && productsList.find(prod => prod._id === p.product)?.billable === true)).forEach(product => {
			orderRIVA += parseFloat(product.price * product.qty)
		});

		// Productos con IVA
		order.orderItems.filter(p => (p.iva === 21 && p.billable === true) || (productsList.find(prod => prod._id === p.product)?.iva === 21 && productsList.find(prod => prod._id === p.product)?.billable === true)).forEach(product => {
			ordeorderProductsRIVA += parseFloat(product.price * product.qty)
		});

		orderProductsNoIVA.push(orderNoIVA)
		orderProductsSRIVA.push(orderSRIVA)
		orderProductsRIVA.push(orderRIVA)
		orderProductsIVA.push(ordeorderProductsRIVA)
		totalWithoutIVA.push(parseFloat(orderNoIVA) + (parseFloat(orderSRIVA) / (1 + (4) / 100)) + (parseFloat(orderRIVA) / (1 + (10) / 100)) + (parseFloat(ordeorderProductsRIVA) / (1 + (21) / 100)))
		if (order.orderItems[0]?.product?.alternativePayment !== true) {
			absoluteWithoutIVA += parseFloat(parseFloat(orderNoIVA) + (parseFloat(orderSRIVA) / (1 + (4) / 100)) + (parseFloat(orderRIVA) / (1 + (10) / 100)) + (parseFloat(ordeorderProductsRIVA) / (1 + (21) / 100)))
			absoluteWithIVA += parseFloat(orderNoIVA) + parseFloat(orderSRIVA) + parseFloat(orderRIVA) + parseFloat(ordeorderProductsRIVA)
		}
		// Creamos el array donde irá el pedido
		if (!ordersAndSubscriptionsSplitted[Math.trunc(i / MAX_ORDERS)]) {
			ordersAndSubscriptionsSplitted[Math.trunc(i / MAX_ORDERS)] = []
		}

		order.items = []

		order.orderItems.filter(p => p.billable === true || productsList.find(prod => prod._id === p.product)?.billable === true).forEach((product, i) => {
			// Creamos el array donde irá el item
		if (!order.items[Math.trunc(i / MAX_ARTICLES)]) {
			order.items[Math.trunc(i / MAX_ARTICLES)] = []
		}
			order.items[Math.trunc(i / MAX_ARTICLES)].push(product)
		});

		ordersAndSubscriptionsSplitted[Math.trunc(i / MAX_ORDERS)].push(order)
		ordersInvoicesSplitted.push(order)

		i ++
	})

	subscriptions.forEach(subscription => {
		if (subscription.alternativePayment !== true) {
			absoluteWithoutIVA += parseFloat(subscription.fee.price)
			absoluteWithIVA += parseFloat(subscription.fee.price)
		}

		if (!ordersAndSubscriptionsSplitted[Math.trunc(i / MAX_ORDERS)]) {
			ordersAndSubscriptionsSplitted[Math.trunc(i / MAX_ORDERS)] = []
		}

		ordersAndSubscriptionsSplitted[Math.trunc(i / MAX_ORDERS)].push(subscription)

		i++
	});

	extracurricularActivityInscriptions.forEach(extracurricularActivityInscription => {
		if (extracurricularActivityInscription.alternativePayment !== true) {
			absoluteWithoutIVA += parseFloat(extracurricularActivityInscription.fee.price)
			absoluteWithIVA += parseFloat(extracurricularActivityInscription.fee.price)
		}

		if (!ordersAndSubscriptionsSplitted[Math.trunc(i / MAX_ORDERS)]) {
			ordersAndSubscriptionsSplitted[Math.trunc(i / MAX_ORDERS)] = []
		}

		ordersAndSubscriptionsSplitted[Math.trunc(i / MAX_ORDERS)].push(extracurricularActivityInscription)

		i++
	})
	
	return (
		<Document>
			<>
				{ordersInvoicesSplitted.map((order, index) => (
					productsList.find(p => p._id === order.orderItems[0].product)?.alternativePayment !== true
					?
					Object.keys(order.items)?.map(key => (
						<Page key={`${index} - ${key}`} size="A4" style={styles.datosCole}>
							<View style={styles.bloqueDatosCole}>
								<View>
									<Image src={process.env.PUBLIC_URL+`/logo192.png`} style={styles.logo}></Image>
								</View>
								<View>
									<Text style={styles.datosColeNegro}>VIRGEN DEL CARMEN</Text>
									<Text style={styles.datosColeNormal}>CIF: R1400096B</Text>
									<Text style={styles.datosColeNormal}>C/ ALONSO EL SABIO 14</Text>
									<Text style={styles.datosColeNormal}>14001 CÓRDOBA</Text>
									<Text style={styles.datosColeNormal}>TEL.: 957482125</Text>
								</View>
								<View>
									<Text style={styles.datosUserNegro}>Dirección fiscal:</Text> 
									<Text style={styles.datosUserNormal}>{(order.user?.userAddress?.address || '')+'\n'+(order.user?.userAddress?.city || '') + (order.user?.userAddress?.state ? ' (' + order.user?.userAddress?.state +')' : '')}</Text>
									<Text style={styles.datosUserNegro}>Cliente:</Text> 
									<Text style={styles.datosUserNormal}>{order.user?.name || ''}</Text>
								</View>
							</View>
							<View style={styles.bloqueNumeroFactura}>
								<Table>
									<TableHeader>
										<TableCell>
											<Text style={styles.numeroFacturaNegro}>FRA. SIMPLIFICADA</Text>
											<Text style={styles.numeroFacturaNormal}>F / {order.paidAt ? order.paidAt?.substr(0,4) : order.updatedAt?.substr(0,4)} / {order.invoice} </Text>
										</TableCell>
									</TableHeader>
								</Table>
							</View>
							<View style={styles.bloqueFecha}>
								<Table>
									<TableHeader>
										<TableCell>
											<Text style={styles.fechaNegro}>FECHA</Text>
											<Text style={styles.fechaNormal}>{
												order.paidAt
												?
													order.paidAt?.substr(8,2)+'/'+order.paidAt?.substr(5,2)+'/'+order.paidAt?.substr(0,4)
												:
													order.updatedAt?.substr(8,2)+'/'+order.updatedAt?.substr(5,2)+'/'+order.updatedAt?.substr(0,4)
												}</Text>
										</TableCell>
										<TableCell>
											<Text style={styles.fechaNegro}>FORMA DE PAGO</Text>
											<Text style={styles.fechaNormal}>Tarjeta de credito</Text>
										</TableCell>
									</TableHeader>
								</Table>
							</View>
							<View style={styles.bloqueProductos}>
								<View style={styles.bloqueProductosHeader}>
									<Text style={styles.productoHeaderTextCenter}>CÓDIGO</Text>
									<Text style={styles.productoHeaderTextCenterLarge}>DESCRIPCIÓN (ALUMNO)</Text>
									<Text style={styles.productoHeaderTextCenter}>CANT.</Text>
									<Text style={styles.productoHeaderTextCenter}>PRECIO</Text>
									<Text style={styles.productoHeaderTextCenter}>% DTO</Text>
									<Text style={styles.productoHeaderTextCenterLast}>IMPORTE €</Text>
								</View>
								{order.items[key]?.filter(p => p.billable === true || productsList.find(prod => prod._id === p.product)?.billable === true).map((product, index) =>(
									<View key={index} style={styles.bloqueProductosBody}>
										<Text style={styles.productoBodyTextCenter}>{productsList?.find(p => p._id === product.product)?.sku}</Text>
										<View style={styles.productoBodyTextCenterLarge}>
											<Text style={styles.nombreProducto}>{product.name.toUpperCase()} {product.attributes?.length > 0 ? `${attributesList?.find(att => att._id === product.attributes[0].attribute)?.name?.toUpperCase()}: ${product.attributes[0].value?.toUpperCase()}` : ''}</Text>
											<Text>({product.student?.name})</Text>
										</View>
										<Text style={styles.productoBodyTextCenter}>{product.qty}</Text>
										<Text style={styles.productoBodyTextCenter}>{(product.price / (1 + (product.iva || productsList.find(p => p._id === product.product)?.iva || 0) / 100))?.toFixed(2)}</Text>
										<Text style={styles.productoBodyTextCenter}></Text>
										<Text style={styles.productoBodyTextCenterLast}>{((product.price / (1 + (product.iva || productsList.find(p => p._id === product.product)?.iva || 0) / 100)) * product.qty).toFixed(2)}</Text>
									</View>
								))}
							</View>
							<View style={styles.bloqueTotales}>
								<View style={styles.bloqueTotalesHeader}>
									<Text style={styles.totalHeaderTextCenter}>BASE</Text>
									<Text style={styles.totalHeaderTextCenterColIz}>% IVA</Text>
									<Text style={styles.totalHeaderTextCenterColDe}>IMP.</Text>
									<Text style={styles.totalHeaderTextCenterColIz}>% REC</Text>
									<Text style={styles.totalHeaderTextCenterColDe}>IMP.</Text>
									<Text style={styles.totalHeaderTextCenterLast}>TOTAL</Text>
								</View>
								<View style={styles.bloqueTotalesBody}>
									<Text style={styles.totalBodyTextCenter}>{orderProductsNoIVA[index]?.toFixed(2)}</Text>
									<Text style={styles.totalBodyTextCenterColIz}>0</Text>
									<Text style={styles.totalBodyTextCenterColDe}>0</Text>
									<Text style={styles.totalBodyTextCenterColIz}></Text>
									<Text style={styles.totalBodyTextCenterColDe}></Text>
									<Text style={styles.totalBodyTextCenterLast}>{orderProductsNoIVA[index]?.toFixed(2)}</Text>
								</View>
								<View style={styles.bloqueTotalesBody}>
									<Text style={styles.totalBodyTextCenter}>{(orderProductsSRIVA[index] / (1 + (4) / 100))?.toFixed(2)}</Text>
									<Text style={styles.totalBodyTextCenterColIz}>4</Text>
									<Text style={styles.totalBodyTextCenterColDe}>{(orderProductsSRIVA[index] - (orderProductsSRIVA[index] / (1 + (4) / 100)))?.toFixed(2)}</Text>
									<Text style={styles.totalBodyTextCenterColIz}></Text>
									<Text style={styles.totalBodyTextCenterColDe}></Text>
									<Text style={styles.totalBodyTextCenterLast}>{orderProductsSRIVA[index]?.toFixed(2)}</Text>
								</View>
								<View style={styles.bloqueTotalesBody}>
									<Text style={styles.totalBodyTextCenter}>{(orderProductsRIVA[index] / (1 + (10) / 100))?.toFixed(2)}</Text>
									<Text style={styles.totalBodyTextCenterColIz}>10</Text>
									<Text style={styles.totalBodyTextCenterColDe}>{(orderProductsRIVA[index] - (orderProductsRIVA[index] / (1 + (10) / 100)))?.toFixed(2)}</Text>
									<Text style={styles.totalBodyTextCenterColIz}></Text>
									<Text style={styles.totalBodyTextCenterColDe}></Text>
									<Text style={styles.totalBodyTextCenterLast}>{orderProductsRIVA[index]?.toFixed(2)}</Text>
								</View>
								<View style={styles.bloqueTotalesBody}>
									<Text style={styles.totalBodyTextCenter}>{(orderProductsIVA[index] / (1 + (21) / 100))?.toFixed(2)}</Text>
									<Text style={styles.totalBodyTextCenterColIz}>21</Text>
									<Text style={styles.totalBodyTextCenterColDe}>{(orderProductsIVA[index] - (orderProductsIVA[index] / (1 + (21) / 100)))?.toFixed(2)}</Text>
									<Text style={styles.totalBodyTextCenterColIz}></Text>
									<Text style={styles.totalBodyTextCenterColDe}></Text>
									<Text style={styles.totalBodyTextCenterLast}>{orderProductsIVA[index]?.toFixed(2)}</Text>
								</View>
								<View style={styles.bloqueTotalesFooter}>
									<Text style={styles.totalFooterTextCenter}>{(orderProductsNoIVA[index] + (orderProductsSRIVA[index] / (1 + (4) / 100)) + (orderProductsRIVA[index] / (1 + (10) / 100)) + (orderProductsIVA[index] / (1 + (21) / 100)))?.toFixed(2)}</Text>
									<Text style={styles.totalFooterTextCenterColIz}>% IVA</Text>
									<Text style={styles.totalFooterTextCenterColDe}>{((orderProductsSRIVA[index] - (orderProductsSRIVA[index] / (1 + (4) / 100))) + (orderProductsRIVA[index] - (orderProductsRIVA[index] / (1 + (10) / 100))) + (orderProductsIVA[index] - (orderProductsIVA[index] / (1 + (21) / 100))))?.toFixed(2)}</Text>
									<Text style={styles.totalFooterTextCenterColIz}>% REC</Text>
									<Text style={styles.totalFooterTextCenterColDe}>IMP.</Text>
									<Text style={styles.totalFooterTextCenterLast}>{(parseFloat(orderProductsNoIVA[index]) + parseFloat(orderProductsSRIVA[index]) + parseFloat(orderProductsRIVA[index]) + parseFloat(orderProductsIVA[index])).toFixed(2)}</Text>
								</View>
							</View>
							<View>
								<Text style={styles.bloqueTotalFacturaText}>TOTAL FACTURA</Text>
								<Text style={styles.bloqueTotalFacturaValue}>{(parseFloat(orderProductsNoIVA[index]) + parseFloat(orderProductsSRIVA[index]) + parseFloat(orderProductsRIVA[index]) + parseFloat(orderProductsIVA[index])).toFixed(2)} €</Text>
							</View>
						</Page>
					))
					:
						<Fragment key={index}></Fragment>
				))}
			</>
			<>
				{subscriptions.map((subscription, index) => (
					subscriptionProductsList.find(p => p._id === subscription.subscriptionProduct)?.alternativePayment !== true && subscriptionProductsList.find(p => p._id === subscription.subscriptionProduct)?.billable !== false
					?
						<Page key={index} size="A4" style={styles.datosCole}>
							<View style={styles.bloqueDatosCole}>
								<View>
									<Image src={process.env.PUBLIC_URL+`/logo192.png`} style={styles.logo}></Image>
								</View>
								<View>
									<Text style={styles.datosColeNegro}>VIRGEN DEL CARMEN</Text>
									<Text style={styles.datosColeNormal}>CIF: R1400096B</Text>
									<Text style={styles.datosColeNormal}>C/ ALONSO EL SABIO 14</Text>
									<Text style={styles.datosColeNormal}>14001 CÓRDOBA</Text>
									<Text style={styles.datosColeNormal}>TEL.: 957482125</Text>
								</View>
								<View>
									<Text style={styles.datosUserNegro}>Dirección fiscal:</Text> 
									<Text style={styles.datosUserNormal}>{(subscription.user?.userAddress?.address || '')+'\n'+(subscription.user?.userAddress?.city || '') + (subscription.user?.userAddress?.state ? ' (' + subscription.user?.userAddress?.state +')' : '')}</Text>
									<Text style={styles.datosUserNegro}>Cliente:</Text> 
									<Text style={styles.datosUserNormal}>{subscription.user?.name || ''}</Text>
								</View>
							</View>
							<View style={styles.bloqueNumeroFactura}>
								<Table>
									<TableHeader>
										<TableCell>
											<Text style={styles.numeroFacturaNegro}>FRA. SIMPLIFICADA</Text>
											<Text style={styles.numeroFacturaNormal}>F / {subscription.paidAt ? new Date(subscription.paidAt).toISOString().substr(0,4) : new Date(subscription.paidAt).toISOString().substr(0,4)} / {subscription.invoice} </Text>
										</TableCell>
									</TableHeader>
								</Table>
							</View>
							<View style={styles.bloqueFecha}>
								<Table>
									<TableHeader>
										<TableCell>
											<Text style={styles.fechaNegro}>FECHA</Text>
											<Text style={styles.fechaNormal}>{
												subscription.paidAt
												?
													new Date(subscription.paidAt).toISOString().substr(8,2)+'/'+new Date(subscription.paidAt).toISOString().substr(5,2)+'/'+new Date(subscription.paidAt).toISOString().substr(0,4)
												:
													new Date(subscription.updatedAt).toISOString().substr(8,2)+'/'+new Date(subscription.updatedAt).toISOString().substr(5,2)+'/'+new Date(subscription.updatedAt).toISOString().substr(0,4)
												}</Text>
										</TableCell>
										<TableCell>
											<Text style={styles.fechaNegro}>FORMA DE PAGO</Text>
											<Text style={styles.fechaNormal}>Tarjeta de credito</Text>
										</TableCell>
									</TableHeader>
								</Table>
							</View>
							<View style={styles.bloqueProductos}>
								<View style={styles.bloqueProductosHeader}>
									<Text style={styles.productoHeaderTextCenter}>CÓDIGO</Text>
									<Text style={styles.productoHeaderTextCenterLarge}>DESCRIPCIÓN (ALUMNO)</Text>
									<Text style={styles.productoHeaderTextCenter}>CANT.</Text>
									<Text style={styles.productoHeaderTextCenter}>PRECIO</Text>
									<Text style={styles.productoHeaderTextCenter}>% DTO</Text>
									<Text style={styles.productoHeaderTextCenterLast}>IMPORTE €</Text>
								</View>
								{
									(subscriptionProductsList.find(p => p._id === subscription.subscriptionProduct)?.billable !== false) &&
									<View key={index} style={styles.bloqueProductosBody}>
										<Text style={styles.productoBodyTextCenter}>{subscriptionProductsList?.find(p => p._id === subscription.subscriptionProduct)?.sku}</Text>
										<View style={styles.productoBodyTextCenterLarge}>
											<Text style={styles.nombreProducto}>{subscriptionProductsList?.find(p => p._id === subscription.subscriptionProduct)?.name.toUpperCase()} {subscription.fee.name}</Text>
											<Text>({subscription.student?.name})</Text>
										</View>
										<Text style={styles.productoBodyTextCenter}>1</Text>
										<Text style={styles.productoBodyTextCenter}>{subscription.fee.price?.toFixed(2)}</Text>
										<Text style={styles.productoBodyTextCenter}></Text>
										<Text style={styles.productoBodyTextCenterLast}>{subscription.fee.price?.toFixed(2)}</Text>
									</View>
								}
							</View>
							<View style={styles.bloqueTotales}>
								<View style={styles.bloqueTotalesHeader}>
									<Text style={styles.totalHeaderTextCenter}>BASE</Text>
									<Text style={styles.totalHeaderTextCenterColIz}>% IVA</Text>
									<Text style={styles.totalHeaderTextCenterColDe}>IMP.</Text>
									<Text style={styles.totalHeaderTextCenterColIz}>% REC</Text>
									<Text style={styles.totalHeaderTextCenterColDe}>IMP.</Text>
									<Text style={styles.totalHeaderTextCenterLast}>TOTAL</Text>
								</View>
								<View style={styles.bloqueTotalesBody}>
									<Text style={styles.totalBodyTextCenter}>{subscription.fee.price?.toFixed(2)}</Text>
									<Text style={styles.totalBodyTextCenterColIz}>0</Text>
									<Text style={styles.totalBodyTextCenterColDe}>0</Text>
									<Text style={styles.totalBodyTextCenterColIz}></Text>
									<Text style={styles.totalBodyTextCenterColDe}></Text>
									<Text style={styles.totalBodyTextCenterLast}>{subscription.fee.price?.toFixed(2)}</Text>
								</View>
								<View style={styles.bloqueTotalesBody}>
									<Text style={styles.totalBodyTextCenter}>0.00</Text>
									<Text style={styles.totalBodyTextCenterColIz}>4</Text>
									<Text style={styles.totalBodyTextCenterColDe}>0.00</Text>
									<Text style={styles.totalBodyTextCenterColIz}></Text>
									<Text style={styles.totalBodyTextCenterColDe}></Text>
									<Text style={styles.totalBodyTextCenterLast}>0.00</Text>
								</View>
								<View style={styles.bloqueTotalesBody}>
									<Text style={styles.totalBodyTextCenter}>0.00</Text>
									<Text style={styles.totalBodyTextCenterColIz}>10</Text>
									<Text style={styles.totalBodyTextCenterColDe}>0.00</Text>
									<Text style={styles.totalBodyTextCenterColIz}></Text>
									<Text style={styles.totalBodyTextCenterColDe}></Text>
									<Text style={styles.totalBodyTextCenterLast}>0.00</Text>
								</View>
								<View style={styles.bloqueTotalesBody}>
									<Text style={styles.totalBodyTextCenter}>0.00</Text>
									<Text style={styles.totalBodyTextCenterColIz}>21</Text>
									<Text style={styles.totalBodyTextCenterColDe}>0.00</Text>
									<Text style={styles.totalBodyTextCenterColIz}></Text>
									<Text style={styles.totalBodyTextCenterColDe}></Text>
									<Text style={styles.totalBodyTextCenterLast}>0.00</Text>
								</View>
								<View style={styles.bloqueTotalesFooter}>
									<Text style={styles.totalFooterTextCenter}>{subscription.fee.price?.toFixed(2)}</Text>
									<Text style={styles.totalFooterTextCenterColIz}>% IVA</Text>
									<Text style={styles.totalFooterTextCenterColDe}>0.00</Text>
									<Text style={styles.totalFooterTextCenterColIz}>% REC</Text>
									<Text style={styles.totalFooterTextCenterColDe}>IMP.</Text>
									<Text style={styles.totalFooterTextCenterLast}>{subscription.fee.price?.toFixed(2)}</Text>
								</View>
							</View>
							<View>
								<Text style={styles.bloqueTotalFacturaText}>TOTAL FACTURA</Text>
								<Text style={styles.bloqueTotalFacturaValue}>{subscription.fee.price?.toFixed(2)} €</Text>
							</View>
						</Page>
					:
						<Fragment key={index}></Fragment>
				))}
			</>
			<>
				{extracurricularActivityInscriptions.map((extracurricularActivityInscription, index) => (
					extracurricularActivitiesList.find(p => p._id === extracurricularActivityInscription.extracurricularActivity)?.alternativePayment !== true && extracurricularActivitiesList.find(p => p._id === extracurricularActivityInscription.extracurricularActivity)?.billable !== false
					?
						<Page key={index} size="A4" style={styles.datosCole}>
							<View style={styles.bloqueDatosCole}>
								<View>
									<Image src={process.env.PUBLIC_URL+`/logo192.png`} style={styles.logo}></Image>
								</View>
								<View>
									<Text style={styles.datosColeNegro}>VIRGEN DEL CARMEN</Text>
									<Text style={styles.datosColeNormal}>CIF: R1400096B</Text>
									<Text style={styles.datosColeNormal}>C/ ALONSO EL SABIO 14</Text>
									<Text style={styles.datosColeNormal}>14001 CÓRDOBA</Text>
									<Text style={styles.datosColeNormal}>TEL.: 957482125</Text>
								</View>
								<View>
									<Text style={styles.datosUserNegro}>Dirección fiscal:</Text> 
									<Text style={styles.datosUserNormal}>{(extracurricularActivityInscription.user?.userAddress?.address || '')+'\n'+(extracurricularActivityInscription.user?.userAddress?.city || '') + (extracurricularActivityInscription.user?.userAddress?.state ? ' (' + extracurricularActivityInscription.user?.userAddress?.state +')' : '')}</Text>
									<Text style={styles.datosUserNegro}>Cliente:</Text> 
									<Text style={styles.datosUserNormal}>{extracurricularActivityInscription.user?.name || ''}</Text>
								</View>
							</View>
							<View style={styles.bloqueNumeroFactura}>
								<Table>
									<TableHeader>
										<TableCell>
											<Text style={styles.numeroFacturaNegro}>FRA. SIMPLIFICADA</Text>
											<Text style={styles.numeroFacturaNormal}>F / {extracurricularActivityInscription.paidAt ? new Date(extracurricularActivityInscription.paidAt).toISOString().substr(0,4) : new Date(extracurricularActivityInscription.paidAt).toISOString().substr(0,4)} / {extracurricularActivityInscription.invoice} </Text>
										</TableCell>
									</TableHeader>
								</Table>
							</View>
							<View style={styles.bloqueFecha}>
								<Table>
									<TableHeader>
										<TableCell>
											<Text style={styles.fechaNegro}>FECHA</Text>
											<Text style={styles.fechaNormal}>{
												extracurricularActivityInscription.paidAt
												?
													new Date(extracurricularActivityInscription.paidAt).toISOString().substr(8,2)+'/'+new Date(extracurricularActivityInscription.paidAt).toISOString().substr(5,2)+'/'+new Date(extracurricularActivityInscription.paidAt).toISOString().substr(0,4)
												:
													new Date(extracurricularActivityInscription.updatedAt).toISOString().substr(8,2)+'/'+new Date(extracurricularActivityInscription.updatedAt).toISOString().substr(5,2)+'/'+new Date(extracurricularActivityInscription.updatedAt).toISOString().substr(0,4)
												}</Text>
										</TableCell>
										<TableCell>
											<Text style={styles.fechaNegro}>FORMA DE PAGO</Text>
											<Text style={styles.fechaNormal}>Tarjeta de credito</Text>
										</TableCell>
									</TableHeader>
								</Table>
							</View>
							<View style={styles.bloqueProductos}>
								<View style={styles.bloqueProductosHeader}>
									<Text style={styles.productoHeaderTextCenter}>CÓDIGO</Text>
									<Text style={styles.productoHeaderTextCenterLarge}>DESCRIPCIÓN (ALUMNO)</Text>
									<Text style={styles.productoHeaderTextCenter}>CANT.</Text>
									<Text style={styles.productoHeaderTextCenter}>PRECIO</Text>
									<Text style={styles.productoHeaderTextCenter}>% DTO</Text>
									<Text style={styles.productoHeaderTextCenterLast}>IMPORTE €</Text>
								</View>
								{
									(extracurricularActivitiesList.find(p => p._id === extracurricularActivityInscription.extracurricularActivity)?.billable !== false) &&
									<View key={index} style={styles.bloqueProductosBody}>
										<Text style={styles.productoBodyTextCenter}>{extracurricularActivitiesList?.find(p => p._id === extracurricularActivityInscription.extracurricularActivity)?.sku}</Text>
										<View style={styles.productoBodyTextCenterLarge}>
											<Text style={styles.nombreProducto}>{extracurricularActivitiesList?.find(p => p._id === extracurricularActivityInscription.extracurricularActivity)?.name.toUpperCase()} {extracurricularActivityInscription.fee.name}</Text>
											<Text>({extracurricularActivityInscription.student?.name})</Text>
										</View>
										<Text style={styles.productoBodyTextCenter}>1</Text>
										<Text style={styles.productoBodyTextCenter}>{extracurricularActivityInscription.fee.price?.toFixed(2)}</Text>
										<Text style={styles.productoBodyTextCenter}></Text>
										<Text style={styles.productoBodyTextCenterLast}>{extracurricularActivityInscription.fee.price?.toFixed(2)}</Text>
									</View>
								}
							</View>
							<View style={styles.bloqueTotales}>
								<View style={styles.bloqueTotalesHeader}>
									<Text style={styles.totalHeaderTextCenter}>BASE</Text>
									<Text style={styles.totalHeaderTextCenterColIz}>% IVA</Text>
									<Text style={styles.totalHeaderTextCenterColDe}>IMP.</Text>
									<Text style={styles.totalHeaderTextCenterColIz}>% REC</Text>
									<Text style={styles.totalHeaderTextCenterColDe}>IMP.</Text>
									<Text style={styles.totalHeaderTextCenterLast}>TOTAL</Text>
								</View>
								<View style={styles.bloqueTotalesBody}>
									<Text style={styles.totalBodyTextCenter}>{extracurricularActivityInscription.fee.price?.toFixed(2)}</Text>
									<Text style={styles.totalBodyTextCenterColIz}>0</Text>
									<Text style={styles.totalBodyTextCenterColDe}>0</Text>
									<Text style={styles.totalBodyTextCenterColIz}></Text>
									<Text style={styles.totalBodyTextCenterColDe}></Text>
									<Text style={styles.totalBodyTextCenterLast}>{extracurricularActivityInscription.fee.price?.toFixed(2)}</Text>
								</View>
								<View style={styles.bloqueTotalesBody}>
									<Text style={styles.totalBodyTextCenter}>0.00</Text>
									<Text style={styles.totalBodyTextCenterColIz}>4</Text>
									<Text style={styles.totalBodyTextCenterColDe}>0.00</Text>
									<Text style={styles.totalBodyTextCenterColIz}></Text>
									<Text style={styles.totalBodyTextCenterColDe}></Text>
									<Text style={styles.totalBodyTextCenterLast}>0.00</Text>
								</View>
								<View style={styles.bloqueTotalesBody}>
									<Text style={styles.totalBodyTextCenter}>0.00</Text>
									<Text style={styles.totalBodyTextCenterColIz}>10</Text>
									<Text style={styles.totalBodyTextCenterColDe}>0.00</Text>
									<Text style={styles.totalBodyTextCenterColIz}></Text>
									<Text style={styles.totalBodyTextCenterColDe}></Text>
									<Text style={styles.totalBodyTextCenterLast}>0.00</Text>
								</View>
								<View style={styles.bloqueTotalesBody}>
									<Text style={styles.totalBodyTextCenter}>0.00</Text>
									<Text style={styles.totalBodyTextCenterColIz}>21</Text>
									<Text style={styles.totalBodyTextCenterColDe}>0.00</Text>
									<Text style={styles.totalBodyTextCenterColIz}></Text>
									<Text style={styles.totalBodyTextCenterColDe}></Text>
									<Text style={styles.totalBodyTextCenterLast}>0.00</Text>
								</View>
								<View style={styles.bloqueTotalesFooter}>
									<Text style={styles.totalFooterTextCenter}>{extracurricularActivityInscription.fee.price?.toFixed(2)}</Text>
									<Text style={styles.totalFooterTextCenterColIz}>% IVA</Text>
									<Text style={styles.totalFooterTextCenterColDe}>0.00</Text>
									<Text style={styles.totalFooterTextCenterColIz}>% REC</Text>
									<Text style={styles.totalFooterTextCenterColDe}>IMP.</Text>
									<Text style={styles.totalFooterTextCenterLast}>{extracurricularActivityInscription.fee.price?.toFixed(2)}</Text>
								</View>
							</View>
							<View>
								<Text style={styles.bloqueTotalFacturaText}>TOTAL FACTURA</Text>
								<Text style={styles.bloqueTotalFacturaValue}>{extracurricularActivityInscription.fee.price?.toFixed(2)} €</Text>
							</View>
						</Page>
					:
						<Fragment key={index}></Fragment>
				))}
			</>
			<>
				{
					ordersAndSubscriptionsSplitted.map((group, index) => (
						<Page size="A4" style={styles.datosCole} key={index}>
							<View style={styles.bloqueDatosCole}>
								<View>
									<Image src={process.env.PUBLIC_URL+`/logo192.png`} style={styles.logo}></Image>
								</View>
								<View>
									<Text style={styles.datosColeNegro}>VIRGEN DEL CARMEN</Text>
									<Text style={styles.datosColeNormal}>CIF: R1400096B</Text>
									<Text style={styles.datosColeNormal}>C/ ALONSO EL SABIO 14</Text>
									<Text style={styles.datosColeNormal}>14001 CÓRDOBA</Text>
									<Text style={styles.datosColeNormal}>TEL.: 957482125</Text>
								</View>
								<View>
									<Text style={styles.datosUserNegro}>Periodo:</Text> 
									<Text style={styles.datosUserNormal}>{start.split('-')[2]}/{start.split('-')[1]}/{start.split('-')[0]} - {end.split('-')[2]}/{end.split('-')[1]}{end.split('-')[0]}</Text>
								</View>
							</View>
							<View>
								<View style={styles.bloqueCabeceraFacturasEmitidas}>
									<Text style={styles.pedidoHeaderSmall}>SERIE</Text>
									<Text style={styles.pedidoHeaderSmall}>REFERENCIA</Text>
									<Text style={styles.pedidoHeaderLarge}>NOMBRE</Text>
									<Text style={styles.pedidoHeaderSmall}>FECHA</Text>
									<Text style={styles.pedidoHeaderSmall}>IMPORTE</Text>
									<Text style={styles.pedidoHeaderSmall}>IMPORTE + IVA</Text>
								</View>
								{group.map((element, i) => (
									element.subscriptionReference
									?
										subscriptionProductsList.find(p => p._id === element.subscriptionProduct)?.alternativePayment !== true && subscriptionProductsList.find(p => p._id === element.subscriptionProduct)?.billable !== false
										?
											<View key={`${index} - ${i}`} style={styles.bloqueBodyFacturasEmitidas}>
												<Text style={styles.pedidoContenidoSmall}>F / {element.paidAt ? new Date(element.paidAt).toISOString().substr(0,4) : new Date(element.paidAt).toISOString().substr(0,4)} / {element.invoice}</Text>
												<Text style={styles.pedidoContenidoSmall}>{element.subscriptionReference}</Text>
												<Text style={styles.pedidoContenidoLarge}>{element.user?.name}</Text>
												<Text style={styles.pedidoContenidoSmall}>
													{
														element.paidAt
															?
																new Date(element.paidAt).toISOString().substr(8,2)+'/'+new Date(element.paidAt).toISOString().substr(5,2)+'/'+new Date(element.paidAt).toISOString().substr(0,4)
															:
																new Date(element.updatedAt).toISOString().substr(8,2)+'/'+new Date(element.updatedAt).toISOString().substr(5,2)+'/'+new Date(element.updatedAt).toISOString().substr(0,4)
													}
												</Text>
												<Text style={styles.pedidoContenidoSmall}>{element.fee.price.toFixed(2)}</Text>
												<Text style={styles.pedidoContenidoSmall}>{element.fee.price.toFixed(2)}</Text>
											</View>
										:
											<Fragment key={`${index} - ${i}`}></Fragment>
									:
										element.extracurricularactivityinscriptionReference
										?
											extracurricularActivitiesList.find(p => p._id === element.extracurricularActivity)?.alternativePayment !== true && extracurricularActivitiesList.find(p => p._id === element.extracurricularActivity)?.billable !== false
											?
												<View key={`${index} - ${i}`} style={styles.bloqueBodyFacturasEmitidas}>
													<Text style={styles.pedidoContenidoSmall}>F / {element.paidAt ? new Date(element.paidAt).toISOString().substr(0,4) : new Date(element.paidAt).toISOString().substr(0,4)} / {element.invoice}</Text>
													<Text style={styles.pedidoContenidoSmall}>{element.extracurricularactivityinscriptionReference}</Text>
													<Text style={styles.pedidoContenidoLarge}>{element.user?.name}</Text>
													<Text style={styles.pedidoContenidoSmall}>
														{
															element.paidAt
																?
																	new Date(element.paidAt).toISOString().substr(8,2)+'/'+new Date(element.paidAt).toISOString().substr(5,2)+'/'+new Date(element.paidAt).toISOString().substr(0,4)
																:
																	new Date(element.updatedAt).toISOString().substr(8,2)+'/'+new Date(element.updatedAt).toISOString().substr(5,2)+'/'+new Date(element.updatedAt).toISOString().substr(0,4)
														}
													</Text>
													<Text style={styles.pedidoContenidoSmall}>{element.fee.price.toFixed(2)}</Text>
													<Text style={styles.pedidoContenidoSmall}>{element.fee.price.toFixed(2)}</Text>
												</View>
											:
												<Fragment key={`${index} - ${i}`}></Fragment>
										:
											productsList.find(p => p._id === element.orderItems[0].product)?.alternativePayment !== true
												?
													<View key={`${index} - ${i}`} style={styles.bloqueBodyFacturasEmitidas}>
														<Text style={styles.pedidoContenidoSmall}>F/{element.paidAt ? element.paidAt?.substr(0,4) : element.updatedAt?.substr(0,4)}/{element.invoice}</Text>
														<Text style={styles.pedidoContenidoSmall}>{element.orderReference}</Text>
														<Text style={styles.pedidoContenidoLarge}>{element.user?.name}</Text>
														<Text style={styles.pedidoContenidoSmall}>
															{
																element.paidAt
																?
																element.paidAt?.substr(8,2)+'/'+element.paidAt?.substr(5,2)+'/'+element.paidAt?.substr(0,4)
																:
																element.createdAt?.substr(8,2)+'/'+element.createdAt?.substr(5,2)+'/'+element.createdAt?.substr(0,4)
															}
														</Text>
														<Text style={styles.pedidoContenidoSmall}>{totalWithoutIVA[index + i].toFixed(2)}</Text>
														<Text style={styles.pedidoContenidoSmall}>{(parseFloat(orderProductsNoIVA[index + i]) + parseFloat(orderProductsSRIVA[index + i]) + parseFloat(orderProductsRIVA[index + i]) + parseFloat(orderProductsIVA[index + i])).toFixed(2)}</Text>
													</View>
												:
													<Fragment key={index}></Fragment>
								))}
								<View style={styles.bloqueBodyTotalPedidos}>
									<Text style={styles.totalPedidosContenidoLarge}>Total general:</Text>
									<Text style={styles.totalPedidosContenido}>{absoluteWithoutIVA.toFixed(2)}</Text>
									<Text style={styles.totalPedidosContenido}>{absoluteWithIVA.toFixed(2)}</Text>
								</View>
							</View>
						</Page>
					))
				}
				
			</>
			
		</Document>
	)
}