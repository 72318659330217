export const STUDENT_REGISTER_REQUEST = 'STUDENT_REGISTER_REQUEST';
export const STUDENT_REGISTER_SUCCESS = 'STUDENT_REGISTER_SUCCESS';
export const STUDENT_REGISTER_FAIL = 'STUDENT_REGISTER_FAIL';
export const STUDENT_REGISTER_RESET = 'STUDENT_REGISTER_RESET';

export const STUDENT_SIGNIN_REQUEST = 'STUDENT_SIGNIN_REQUEST';
export const STUDENT_SIGNIN_SUCCESS = 'STUDENT_SIGNIN_SUCCESS';
export const STUDENT_SIGNIN_FAIL = 'STUDENT_SIGNIN_FAIL';

export const STUDENT_SIGNOUT = 'STUDENT_SIGNOUT';

export const STUDENT_DETAILS_REQUEST = 'STUDENT_DETAILS_REQUEST';
export const STUDENT_DETAILS_SUCCESS = 'STUDENT_DETAILS_SUCCESS';
export const STUDENT_DETAILS_FAIL = 'STUDENT_DETAILS_FAIL';
export const STUDENT_DETAILS_RESET = 'STUDENT_DETAILS_RESET';

export const STUDENT_UPDATE_PROFILE_REQUEST = 'STUDENT_UPDATE_PROFILE_REQUEST';
export const STUDENT_UPDATE_PROFILE_SUCCESS = 'STUDENT_UPDATE_PROFILE_SUCCESS';
export const STUDENT_UPDATE_PROFILE_FAIL = 'STUDENT_UPDATE_PROFILE_FAIL';
export const STUDENT_UPDATE_PROFILE_RESET = 'STUDENT_UPDATE_PROFILE_RESET';

export const STUDENT_LIST_REQUEST = 'STUDENT_LIST_REQUEST';
export const STUDENT_LIST_SUCCESS = 'STUDENT_LIST_SUCCESS';
export const STUDENT_LIST_FAIL = 'STUDENT_LIST_FAIL';

export const STUDENT_DELETE_REQUEST = 'STUDENT_DELETE_REQUEST';
export const STUDENT_DELETE_SUCCESS = 'STUDENT_DELETE_SUCCESS';
export const STUDENT_DELETE_FAIL = 'STUDENT_DELETE_FAIL';
export const STUDENT_DELETE_RESET = 'STUDENT_DELETE_RESET';

export const STUDENT_UPDATE_REQUEST = 'STUDENT_UPDATE_REQUEST';
export const STUDENT_UPDATE_SUCCESS = 'STUDENT_UPDATE_SUCCESS';
export const STUDENT_UPDATE_FAIL = 'STUDENT_UPDATE_FAIL';
export const STUDENT_UPDATE_RESET = 'STUDENT_UPDATE_RESET';

export const STUDENTS_IMPORT_REQUEST = 'STUDENTS_IMPORT_REQUEST';
export const STUDENTS_IMPORT_SUCCESS = 'STUDENTS_IMPORT_SUCCESS';
export const STUDENTS_IMPORT_FAIL = 'STUDENTS_IMPORT_FAIL';
export const STUDENTS_IMPORT_RESET = 'STUDENTS_IMPORT_RESET';