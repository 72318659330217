import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Swal from 'sweetalert2';
import { listStudents } from '../actions/studentActions';
import { detailsCourse } from '../actions/courseActions';
import { getNotificationsUser, sendNotificationParent } from '../actions/notificationActions';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar'
import { CustomDataTable } from '../components/CustomDataTable';
import removeAccents from '../utils/removeAccents';

export const AbsencesCourseScreen = () => {
    const params = useParams();
    const { id: courseId } = params;

    const { loading, error, students } = useSelector(state => state.studentList);
    const [studentsCourse, setStudentsCourse] = useState([]);
    const [sendingNotification, setsendingNotification] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [message, setMessage] = useState('')

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(detailsCourse(courseId));
        dispatch(listStudents())
        dispatch(getNotificationsUser());
    }, [dispatch, courseId])

    useEffect(() => {
        if(students){
            setStudentsCourse(students?.filter(student => student.course._id === courseId))
        }
    }, [students, courseId])

    const pushNotificationHandler = async(e) => {
        e.preventDefault();
        if (selectedUsers.length > 0) {
            setsendingNotification(true)
            selectedUsers.forEach((user, index) => {
                dispatch(sendNotificationParent(user, message))
                    if (index === selectedUsers.length - 1) {
                        setsendingNotification(false)
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Notificaciones enviadas correctamente',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
            })
        }

        setSelectedUsers([]);
        setMessage('');
    }

    const addUser = async(e, idStudent) => {
        if (e.target.checked) {
            setSelectedUsers([...selectedUsers, idStudent])
        }else{
            setSelectedUsers(selectedUsers?.filter(student => student !== idStudent))
        }
    }

    const columns = [
        {
            name: 'Alumno',
            selector: (row) => removeAccents(row.name),
            cell: (row) => row.name,
            sortable: true,
        },{
            name: 'Ausente',
            selector: (row) => removeAccents(row.name),
            cell: (row) => 
                <div className="fv-row">
                    <label className='form-check form-switch form-check-custom form-check-solid'>
                        <input className='form-check-input' type='checkbox' onClick={e => addUser(e, row._id)}/>
                    </label>
                </div>
            ,
            sortable: true,
        }
    ];

    return (
        <>
            <ToolBar titulo={"Lista de ausencias"} hitos={[
                { titulo: "Inicio", enlace: "/" },
            ]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <form autoComplete='off' className="form w-100" noValidate="novalidate" id="kt_sign_in_form" onSubmit={pushNotificationHandler}>
                    <div className="content flex-row-fluid" id="kt_content">
                        {loading ? (<LoadingBox variant={"primary"} />) :
                            error ? (<AlertMessage variant={"danger"} message={error} />) : (
                                <div className="card card-flush">
                                    <div className="card-body pt-0">
                                        <CustomDataTable data={studentsCourse} columns={columns} />
                                        <div className="mb-10 fv-row">
                                            <label className="form-label">Mensaje de la ausencia: </label>
                                            <textarea className="form-control mb-3" rows="5" data-kt-element="input" value={message || ''} onChange={(e) => setMessage(e.target.value)}></textarea>
                                        </div>
                                        <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">     
                                            {sendingNotification ? (<LoadingBox variant={"primary"}/>)
                                            :
                                            (<span className="indicator-label">Notificar ausencias</span>)}
                                        </button>
                                    </div>
                                </div>
                            )}
                    </div>
                </form>
            </div>
        </>
    )
}