import Axios from 'axios';
import {putNewLog} from '../utils/logs'

export const sendLog = (log) => async (dispatch, getState) => {
    const { userSignin: { userInfo } } = getState();

    try {
        const { data, status } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/logs', {stringlog: log}, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        if(status === 200){
            localStorage.setItem('SDerrorlog', '')
            putNewLog('Suc', status, userInfo._id, '/api/logs')
        }else{
            putNewLog('War', status, userInfo._id, '/api/logs')
        }
    } catch (error) {
        putNewLog('Err', error, userInfo?._id, '/api/logs')
    }
}