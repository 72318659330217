import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { createOrder } from '../actions/orderActions'
import { AlertMessage } from '../components/AlertMessage'
import { LoadingBox } from '../components/LoadingBox'
import { ToolBar } from '../components/ToolBar'
import { ORDER_CREATE_RESET } from '../constants/orderConstants'

export const PlaceOrderScreen = (props) => {
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);

  
  const orderCreate = useSelector((state) => state.orderCreate);
  const {loading, success, error, order} = orderCreate;

  // Convierte un numero a decimal con dos cifras
  const toPrice = (num) => Number(num.toFixed(2)); // 5.123 => "5.12" => 5.12
  
  let totalPrice = 0
  let totalTaxes = 0

  cart.cartItems.forEach(item => {
      let priceWithoutTaxes = item.price / (1 + item.iva / 100)
      let taxesPrice = item.price - priceWithoutTaxes

      totalPrice += item.qty * priceWithoutTaxes
      totalTaxes += item.qty * taxesPrice
  });

  // Precio total de los artículos
  cart.itemsPrice = toPrice(totalPrice)
  //Precio total del iva de los artículos
  cart.taxPrice = toPrice(totalTaxes)
  // Coste de envío
  cart.shippingPrice = cart.itemsPrice > 100 ? toPrice(0) : toPrice(0);

  // Total: precio de los articulos + coste de envío + impuestos
  cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;

  const dispatch = useDispatch();
  const placeOrderHandler = () => {
    dispatch(createOrder({...cart, orderItems: cart.cartItems}))
  }

  useEffect(() => {
    if(success) {        
        navigate(`/order/${order._id}`);
        dispatch({type: ORDER_CREATE_RESET});
    }
  }, [dispatch, order, success, navigate])

  return (
    
    <>
    <ToolBar titulo={"Checkout"} hitos={[
        {titulo: "Inicio", enlace: "/" },
        {titulo: "Carrito", enlace: "cart" },
        {titulo: "Envío", enlace: "/shipping" },
        {titulo: "Método de pago", enlace: "/payment" },
        {titulo: "Finalizar pedido", enlace: "#" }]}/>
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">						
      <div className="content flex-row-fluid" id="kt_content">
        <div className="row g-5 g-xl-10 mb-5 mb-xl-10">    
            <div className="col-xl-8">        
                {/* <div className="card card-flush mb-4"> 
                    <div className="card-header pt-7" id="kt_chat_contacts_header">                
                        <div className="card-title">                    
                            <span className="svg-icon svg-icon-1 me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black" />
                                    <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black" />
                                </svg>
                            </span>                    
                            <h2>Envío</h2>
                        </div>                
                    </div>                                             
                    <div className="card-body p-5">                                    
                        <p>
                            <strong>Nombre:</strong> {cart.shippingAddress.fullName} <br/>
                            <strong>Dirección:</strong> {cart.shippingAddress.address}, {cart.shippingAddress.city}, {cart.shippingAddress.postalCode}, {cart.shippingAddress.country}
                        </p>
                    </div>            
                </div> */}
                
                <div className="card card-flush mb-4">  
                    <div className="card-header pt-7" id="kt_chat_contacts_header">                
                        <div className="card-title">                    
                            <span className="svg-icon svg-icon-1 me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black" />
                                    <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black" />
                                </svg>
                            </span>                    
                            <h2>Pago</h2>
                        </div>                
                    </div>                                     
                    <div className="card-body p-5">                
                        <p className="px-3">
                            <strong>Método de pago:</strong> {cart.paymentMethod}
                        </p>
                    </div>            
                </div>

                <div className="card card-flush mb-4">
                    <div className="card-header pt-7" id="kt_chat_contacts_header">                
                        <div className="card-title">                    
                            <span className="svg-icon svg-icon-1 me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black" />
                                    <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black" />
                                </svg>
                            </span>                    
                            <h2>Resumen de la cesta</h2>
                        </div>                
                    </div>                                              
                    <div className="card-body p-5">                                                        
                        <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">            
                            <thead>                
                                <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                    <th className="min-w-200px">Producto</th>
                                    <th className="text-end min-w-70px">Cantidad</th>
                                    <th className="text-end min-w-70px">Precio</th>
                                    <th className="text-end min-w-100px">Total</th>
                                </tr>                
                            </thead>                        
                            <tbody className="fw-bold text-gray-600">
                                {cart.cartItems.map((item) => (         
                                <tr key={item.product}>                                                           
                                    <td>
                                        <div className="d-flex align-items-center">                            
                                            <Link to={"#"} className="symbol symbol-50px">
                                                <span className="symbol-label" style={{ backgroundImage: `url(${item.image})` }}></span>
                                            </Link>                            
                                            <div className="ms-5">   
                                                <Link to={`/product/${item.product}`} className="text-gray-800 text-hover-primary fs-5 fw-bolder" data-kt-ecommerce-product-filter="product_name">{item.name}</Link>                                                     
                                            </div>
                                        </div>
                                    </td>                                                                                                   
                                    <td className="text-end pe-0" data-order="41">
                                        <span className="fw-bolder ms-3">               
                                            {item.qty}
                                        </span>
                                    </td>  
                                    <td className="text-end pe-0" data-order="41">
                                        <span className="fw-bolder ms-3">               
                                            {item.price} €
                                        </span>
                                    </td>                                        
                                    <td className="text-end pe-0">
                                        <span className="fw-bolder text-dark">{(item.qty * item.price).toFixed(2)} €</span>
                                    </td>
                                </tr> 
                                ))}                                        
                            </tbody>            
                        </table>                     
                    </div>            
                </div>
            </div>        
            <div className="col-xl-4">        
                <div className="card h-md-100">
                    <div className="card-header pt-7" id="kt_chat_contacts_header">                
                        <div className="card-title">                    
                            <span className="svg-icon svg-icon-1 me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black" />
                                    <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black" />
                                </svg>
                            </span>                    
                            <h2>Resumen del pedido</h2>
                        </div>                
                    </div>            
                    <div className="card-body d-flex flex-column">                
                            
                            <div className="row d-flex justify-content-between">
                                <div className="col-6 py-3">Artículos</div>
                                <div className="col-6 py-3 text-right">{cart.itemsPrice.toFixed(2)} €</div>
                            </div> 
                            {/* <div className="row">
                                <div className="col-6 py-3">Envío</div>
                                <div className="col-6 py-3 text-right">{cart.shippingPrice.toFixed(2)} €</div>
                            </div> */}
                            <div className="row">
                                <div className="col-6 py-3">Impuestos</div>
                                <div className="col-6 py-3 text-right">{cart.taxPrice.toFixed(2)} €</div>
                            </div>
                            <div className="row">
                                <div className="col-6 py-3"><strong>Total</strong></div>
                                <div className="col-6 py-3 text-right"><strong>{cart.totalPrice.toFixed(2)} €</strong></div>
                            </div> 

                            <button type="button" className="btn btn-primary btn-block" onClick={placeOrderHandler} disabled={cart.cartItems.length === 0}>Finalizar pedido</button>
                            {loading && (
                                <LoadingBox variant={"primary"}/>
                            ) }
                            {error && (
                                <AlertMessage variant={"danger"} message={error}/>
                            )}
                    </div>            
                </div>        
            </div>    
        </div>    
      </div>
    </div>
    </>
  )
}
