import Axios from 'axios'
// import { PayPalButton } from 'react-paypal-button-v2'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { detailsOrder, payOrder, updatePaymentMethod } from '../actions/orderActions'
import { ToolBar } from '../components/ToolBar'
import { LoadingBox } from '../components/LoadingBox'
import { AlertMessage } from '../components/AlertMessage'
import { listAttributes } from '../actions/attributeActions';
import { getNotificationsUser } from '../actions/notificationActions'

export const PayOrderScreen = () => {

    const params = useParams();
    const formRef = useRef()

    const orderId = params.id;

    const [redsys_payable, setRedsysPay] = useState({});

    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    const attributeList = useSelector((state) => state.attributeList);
    const { attributes } = attributeList;

    const orderDetails = useSelector(state => state.orderDetails);
    const { loading, order, error } = orderDetails;

    const orderPay = useSelector(state => state.orderPay);
    const { loading: loadingPay, error: errorPay, success: successPay } = orderPay;

    const [selectedPaymentMethod, setPaymentMethod] = useState('Tarjeta crédito/débito');

    const dispatch = useDispatch();

    useEffect(() => {
        const setRedsysParams = async () => {
            if (order) {
                const redsys_payable = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/redsys/form', { idOrder: order._id, order: order.orderReference, quantity: order.totalPrice, alternativePayment: order.orderItems.find(i => i.alternativePayment === true) ? true : false });
                setRedsysPay(redsys_payable.data.redsys_params)
            }
        }
        if (!order?.isPaid) {
            setRedsysParams();
        }
    }, [dispatch, orderId, order, successPay]);

    useEffect(()=>{
        dispatch(listAttributes())
        dispatch(detailsOrder(orderId))
        dispatch(getNotificationsUser());
    }, [dispatch, orderId])

    useEffect(() => {
        if (order) {
            setPaymentMethod(order.paymentMethod)   
        }
    }, [order])

    useEffect(() => {
        if (selectedPaymentMethod) {
            dispatch(updatePaymentMethod(orderId, selectedPaymentMethod))
        }
    }, [selectedPaymentMethod, dispatch])

    const successPaymentHandler = (paymentResult) => {
        dispatch(payOrder(order, paymentResult));
    }

    useEffect(() => {
        if (redsys_payable.Ds_MerchantParameters) {
            formRef.current.submit()
        }
        
    }, [redsys_payable])

    return (
        <>
            <ToolBar titulo={"Checkout"} hitos={[
                { titulo: "Checkout", enlace: "/" },
                { titulo: "Finalizar pedido", enlace: "#" }]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    {loading ? (
                        <LoadingBox variant={"primary"} />
                    ) : error ? (
                        <AlertMessage variant={"danger"} message={error} />
                    ) : (
                        <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                            <div className="col-xl-8">

                                <div className="card card-flush">
                                    <div className="card-header pt-5">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bolder text-dark">Datos de pago</span>
                                        </h3>
                                    </div>
                                    <div className="card-body p-5 mx-3">
                                    {
                                        order.isPaid
                                        ?
                                            <>
                                                <strong>Método de pago:</strong>
                                                <div className="alert alert-primary">
                                                    <div className="d-flex flex-column">
                                                        <span>{order.paymentMethod}</span>
                                                    </div>
                                                </div>

                                                <strong>Estado del pago:</strong>
                                                {order.isPaid ? (
                                                    <div className="alert alert-success">
                                                        <div className="d-flex flex-column">
                                                            <span>Pagado a las {
                                                                order.paidAt
                                                                ?
                                                                    <span>Pagado a las {new Date(order.paidAt)?.toTimeString().substring(0, 5)+' ('+order.paidAt?.substr(8,2)+'/'+order.paidAt?.substr(5,2)+'/'+order.paidAt?.substr(0,4)+')'}</span>
                                                                :
                                                                    <span>Pagado a las {new Date(order.updatedAt)?.toTimeString().substring(0, 5)+' ('+order.updatedAt?.substr(8,2)+'/'+order.updatedAt?.substr(5,2)+'/'+order.updatedAt?.substr(0,4)+')'}</span>
                                                            }</span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="alert alert-danger">
                                                        <div className="d-flex flex-column">
                                                            <span>Pago pendiente</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        :
                                            <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                <div className="col-12">
                                                <label className="d-flex flex-stack cursor-pointer mb-5">    
                                                    <span className="d-flex align-items-center me-2">        
                                                        <span className="symbol symbol-50px me-6">
                                                            <span className="symbol-label bg-light-primary">
                                                                <img src="/assets/media/svg/payment-methods/visa.svg" alt="Avatar"/>
                                                            </span>
                                                        </span>                
                                                        <span className="d-flex flex-column">
                                                            <span className="fw-bolder fs-6">Tarjeta de crédito/débito</span>            
                                                        </span>        
                                                    </span>        
                                                    <span className="form-check form-check-custom form-check-solid">
                                                        <input className="form-check-input" type="radio" id="card" value="Tarjeta crédito/débito" name="paymentMethod" required checked={selectedPaymentMethod === 'Tarjeta crédito/débito' ? true : false} onChange={ (e) => setPaymentMethod(e.target.value)}/>
                                                    </span>    
                                                </label>
                                                </div>
                                            </div>
                                    }
                                        
                                    </div>
                                </div>
                                
                                <div className="card card-flush py-4 mt-4 flex-row-fluid overflow-hidden">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h2>Pedido #{order.orderReference}</h2>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                <thead>
                                                    <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                                        <th className="min-w-175px">Producto</th>
                                                        <th className="min-w-175px">Estudiante</th>
                                                        <th className="min-w-70px text-end">Cantidad</th>
                                                        <th className="min-w-100px text-end">Fechas</th>
                                                        <th className="min-w-70px text-end">Precio</th>
                                                        <th className="min-w-70px text-end">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="fw-bold text-gray-600">
                                                    {order.orderItems.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <Link to={`/product/${item.product}`} className="symbol symbol-50px">
                                                                        <span className="symbol-label" style={{ backgroundImage: `url('${item.image}')` }}></span>
                                                                    </Link>
                                                                    <div className="ms-5">
                                                                        <Link to={`/product/${item.product}`} className="fw-bolder text-gray-600 text-hover-primary">
                                                                            {item.name}
                                                                            {item.attributes?.length > 0
                                                                                ?
                                                                                `, ${attributes?.find(att => att._id === item.attributes[0].attribute)?.name}: ${item.attributes[0].value}`
                                                                                :
                                                                                ''}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>{item.student?.name}</td>
                                                            <td className="text-end">{item.qty}</td>
                                                            <td className="text-end">
                                                                {item.dates.map((date, key) => (
                                                                    <div key={key}>{date}</div>
                                                                ))}
                                                            </td>
                                                            <td className="text-end">{item.price} €</td>
                                                            <td className="text-end">{(item.qty * item.price).toFixed(2)} €</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="card h-md-100">
                                    <div className="card-header pt-7" id="kt_chat_contacts_header">
                                        <div className="card-title">
                                            <span className="svg-icon svg-icon-1 me-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black" />
                                                    <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black" />
                                                </svg>
                                            </span>
                                            <h2>Resumen del pedido</h2>
                                        </div>
                                    </div>
                                    <div className="card-body d-flex flex-column">

                                        <div className="row d-flex justify-content-between">
                                            <div className="col-6 py-3">Artículos</div>
                                            <div className="col-6 py-3 text-right">{order.itemsPrice.toFixed(2)} €</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 py-3">Impuestos</div>
                                            <div className="col-6 py-3 text-right">{order.taxPrice.toFixed(2)} €</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 py-3"><strong>Total</strong></div>
                                            <div className="col-6 py-3 text-right"><strong>{order.totalPrice.toFixed(2)} €</strong></div>
                                        </div>

                                        <div className="row z-index-1">
                                            <div className="col-12 py-3">
                                                <form autoComplete='off' name="from" action="https://sis.redsys.es/sis/realizarPago" method="POST" ref={formRef} hidden={!order.isPaid && selectedPaymentMethod !== 'PayPal' && redsys_payable.Ds_MerchantParameters !== undefined}>
                                                    <input readOnly type="hidden" name="Ds_SignatureVersion" value={redsys_payable.Ds_SignatureVersion || ''} />
                                                    <input readOnly type="hidden" name="Ds_MerchantParameters" value={redsys_payable.Ds_MerchantParameters || ''} />
                                                    <input readOnly type="hidden" name="Ds_Signature" value={redsys_payable.Ds_Signature || ''} />
                                                    <div className="d-grid gap-2">
                                                        <button type="submit" className="btn btn-primary">Paga con tarjeta</button>
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                        {loading && (
                                            <LoadingBox variant={"primary"} />
                                        )}
                                        {error && (
                                            <AlertMessage variant={"danger"} message={error} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
        </>
    )
}
