import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { deleteUser, listUsers, register } from '../actions/userActions';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar';
import { USER_DETAILS_RESET } from '../constants/userConstants';
import Swal from 'sweetalert2';
import { CustomDataTable } from '../components/CustomDataTable';
import Select from "react-select"
import { listCourses } from '../actions/courseActions';
import { listStudents } from '../actions/studentActions';
import removeAccents from '../utils/removeAccents';


export const UserListScreen = () =>{
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const userList = useSelector(state =>state.userList);
    const {loading, error, users} = userList;

    const studentList = useSelector(state =>state.studentList);
    const { loading: loadingStudents, error: errorStudents, students } = studentList;

    const userRegister = useSelector((state) =>state.userRegister);
    const {loading : loadingCreate, error : errorCreate} = userRegister


    const userDelete = useSelector(state =>state.userDelete);
    const {loading: loadingDelete, error: errorDelete, success: successDelete} = userDelete;

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [dni, setDni] = useState('')
    const [phone, setPhone] = useState('')

    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [address, setAddress] = useState('');

    const [passwordShow, setPasswordShow] = useState(false)
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)

    const columns = [        
        {
            name: "NOMBRE DEL PADRE",
            selector: (row) =>removeAccents(row.name),
            sortable: true,    
            grow: 1.5,   
            cell: row =><span>{row.name}</span>
        },
        {
            name: "HIJOS",
            selector: (row) =>row.userChildren,
            sortable: true,    
            grow: 1.5,   
            cell: row =>
            <div className="col-12">
            {row.userChildren.map((hijo) =>(
                <div key={hijo._id}>
                <span>{hijo.name}</span>
                <br/>
               </div>
            ))}
            </div>
        },        
        {
            name: "DNI",
            selector: (row) =>removeAccents(row.dni),
            sortable: true,        
            cell: row =><span>{row.dni}</span>
        },        
        {
            name: "TLF. CONTACTO",
            selector: (row) =>removeAccents(row.phone), 
            cell: row =><span>{row.phone}</span>,
            sortable: true             
        }, 
        {
        name: "",
        cell: row =>
            <div className="row">
                <div className="col-12">
                <i className="bi bi-pencil px-2" onClick={() =>navigate(`/user/${row._id}/edit`)} style={{fontSize: '1.4rem', cursor: 'pointer'}}></i>
                <i className="bi bi-trash-fill px-2" onClick={ () =>deleteHandler(row)} style={{fontSize: '1.4rem', cursor: 'pointer'}}></i>
                </div>
            </div>,
        // <div className="dropdown dropup">
        //     <button className="btn btn-sm btn-light btn-active-light-primary" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
        //         Acciones
        //         <span className="svg-icon svg-icon-5 m-0">
        //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        //                 <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
        //             </svg>
        //         </span>
        //     </button>
        //     <ul className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" aria-labelledby="dropdownMenuButton1">
        //         <li className="menu-item px-3"><button className="dropdown-item menu-link px-3" onClick={() =>navigate(`/user/${row._id}/edit`)}>Editar</button></li>
        //         <li className="menu-item px-3"><button className="dropdown-item menu-link px-3" onClick={ () =>deleteHandler(row)} >Eliminar</button></li>
        //     </ul>
        // </div>,
        center: true,                             
        
        }
    ];

    // Select de Usuarios 
    // const options = []
    const [options, setOptions] = useState([])

    const selectedUsers = [];
    const [selectedOptions, setSelectedOptions] = useState([])

    const handleChange = (selectedOptions) =>{
        setSelectedOptions(selectedOptions)        
    }

    useEffect(() =>{
      dispatch(listUsers());
      dispatch(listStudents());
      dispatch({type: USER_DETAILS_RESET});  
      dispatch(listCourses())  
    
    }, [dispatch, successDelete])


    useEffect(() =>{
        if (students && !loadingStudents) {
            setOptions([])
            students.map((student) =>(
                setOptions(options =>[...options, { value: student._id, label: student.name }])                
            ))
        }
        dispatch(listCourses())    
    }, [dispatch, students, loadingStudents])

    
    const deleteHandler = (user) =>{
        Swal.fire({
            title: `¿Desea eliminar al usuario ${user.name} ?`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Si',
            denyButtonText: 'No', 
            cancelButtonText: "Cancelar",
            icon: "warning",           
          }).then((result) =>{
            if (result.isConfirmed) {
              dispatch(deleteUser(user._id));
              Swal.fire('Usuario eliminado', '', 'success')
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })        
    }

    const submitHandler = (e) =>{
        e.preventDefault();
        if(password !== confirmPassword) {
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text: 'Las contraseñas no coinciden'
            })            
        }else { 
            // Añadir objetos de hijos al padre
            selectedOptions.map((selectedOption) =>(
                selectedUsers.push(students.find(student =>student._id === selectedOption.value))
            ))
                    
            dispatch(register(name, email, password, dni, phone, selectedUsers, address, city, state, postalCode));
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Usuario creado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
            
            dispatch(listUsers());

            setName('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');
            setDni('');
            setPhone('');
        
            setCity('');
            setState('');
            setPostalCode('');
            setAddress('');

            setSelectedOptions([])

            document.getElementById('closeModal').click()                       
        }
    }

  return (
    <>
    <ToolBar titulo={"Lista de padres"} hitos={[
        {titulo: "Inicio", enlace: "/" },
        {titulo: "Lista de padres", enlace: "#" }]}/>
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">						
        <div className="content flex-row-fluid" id="kt_content">
    
        {loadingDelete && <LoadingBox variant={"primary"}/>}
        {errorDelete && <AlertMessage variant={"danger"} message={errorDelete}/>}
        {
            loading ? (<LoadingBox variant={"primary"}/>) :
            error ? (<AlertMessage variant={"danger"} message={error}/>) :
            (
                <div className="card card-flush">
                    <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                        <div className="card-title">
                        </div>
                        <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            Crear usuario
                            </button>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <CustomDataTable data={users} columns={columns}/>
                    </div>
                </div>
            )
        } 
        </div>
    </div>
    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Crear usuario</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    {loadingCreate ? <LoadingBox />:
                    <form autoComplete='off' className="form w-100 row" id="kt_sign_in_form" onSubmit={submitHandler}>
                        <h3 className="fw-bolder mx-0 mb-5">Datos generales</h3>
                        <div className="col-12 col-md-6 mb-5">
                            <label className="form-label fs-6 fw-bolder text-dark"><span className="required">Nombre</span></label>
                            <input className="form-control form-control-lg form-control-solid" placeholder="Nombre" value={name} required onChange={ e =>setName(e.target.value)}/>
                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                            <input className="form-control form-control-lg form-control-solid" type="email" id="email" value={email} placeholder="Email" onChange={ e =>setEmail(e.target.value)}/>
                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <div className="d-flex flex-stack mb-2">
                                <label className="form-label fw-bolder text-dark fs-6 mb-0"><span className="required">Contraseña</span></label>
                            </div>
                            <div className="position-relative">
                                <input className="form-control form-control-lg form-control-solid" type={passwordShow ? 'text' : 'password'} id="password" value={password} placeholder="Contraseña" required onChange={ e =>setPassword(e.target.value)}/>
                                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={() => {setPasswordShow(!passwordShow)}}>
                                    {
                                    passwordShow 
                                    ? <i className="bi bi-eye-slash fs-2"></i>
                                    : <i className="bi bi-eye fs-2"></i>
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <div className="d-flex flex-stack mb-2">
                                <label className="form-label fw-bolder text-dark fs-6 mb-0"><span className="required">Confirmar contraseña</span></label>
                            </div>
                            <div className="position-relative">
                                <input className="form-control form-control-lg form-control-solid" type={confirmPasswordShow ? 'text' : 'password'} id="confirmPassword" value={confirmPassword} placeholder="Confirmar contraseña" required onChange={ e =>setConfirmPassword(e.target.value)}/>
                                <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={() => {setConfirmPasswordShow(!confirmPasswordShow)}}>
                                    {
                                    confirmPasswordShow 
                                    ? <i className="bi bi-eye-slash fs-2"></i>
                                    : <i className="bi bi-eye fs-2"></i>
                                    }
                                </span>
                            </div>
                        </div>
                       
                        <div className="col-12 col-md-6 mb-5">
                            <label className="form-label fs-6 fw-bolder text-dark">
                                <span className="required">DNI</span>
                                <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" data-bs-placement="top" title="En caso de no disponer de DNI, usar el código de centro"></i>
                            </label>
                            <input className="form-control form-control-lg form-control-solid" type="text" value={dni} required onChange={ e =>setDni(e.target.value)}/>
                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <label className="form-label fs-6 fw-bolder text-dark">Teléfono</label>
                            <input className="form-control form-control-lg form-control-solid" placeholder="Teléfono" value={phone} onChange={ e =>setPhone(e.target.value)}/>
                        </div>
                        
                        <div className="col-12 col-md-6 mb-5">
                            <label className="form-label fs-6 fw-bolder text-dark">Hijos</label>
                            {loadingStudents ? (<LoadingBox variant={"primary"} />) :
                            errorStudents ? (<AlertMessage variant={"danger"} message={error} />) : (
                                <Select isMulti isClearable options={options} onChange={handleChange} value={selectedOptions} placeholder="Escoja los hijos del usuario" />
                            )}                  
                        </div>
                        

                        <h3 className="fw-bolder mx-0 mb-5 mt-10 col-12">Datos de dirección</h3>

                        <div className="col-12 col-md-6 mb-5">
                            <label className="form-label fs-6 fw-bolder text-dark">Dirección</label>
                            <input className="form-control form-control-lg form-control-solid" placeholder="Introduce tu dirección" value={address} onChange={ (e) =>setAddress(e.target.value)}/>
                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <label className="form-label fs-6 fw-bolder text-dark">Localidad</label>
                            <input className="form-control form-control-lg form-control-solid" placeholder="Introduce tu localidad" value={city} onChange={ (e) =>setCity(e.target.value)}/>
                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <label className="form-label fs-6 fw-bolder text-dark">Provincia</label>
                            <input className="form-control form-control-lg form-control-solid" placeholder="Introduce tu provincia" value={state} onChange={ (e) =>setState(e.target.value)}/>
                        </div>
                        <div className="col-12 col-md-6 mb-5">
                            <label className="form-label fs-6 fw-bolder text-dark">Código postal</label>
                            <input className="form-control form-control-lg form-control-solid" placeholder="Introduce tu CP" value={postalCode} onChange={ (e) =>setPostalCode(e.target.value)}/>
                        </div>

                        <div className="text-end">
                            <button type="submit" id="kt_sign_in_submit" className="btn btn-primary me-1">
                                {
                                    loading ? (
                                        <span className="indicator-progress">Espere por favor...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                ) :
                                (<span className="indicator-label">Crear</span>)
                                }
                            </button>
                            <button type="button" id="closeModal" className="btn btn-secondary ms-1" data-bs-dismiss="modal">Cerrar</button>

                        </div>
                        { errorCreate ? (
                            <AlertMessage variant={"danger"} message={errorCreate}/>
                        ) : ''}
                        
                    </form>
                    }
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
