import { COURSE_LIST_SUCCESS, COURSE_LIST_FAIL, COURSE_LIST_REQUEST, COURSE_DETAILS_REQUEST, COURSE_DETAILS_SUCCESS, COURSE_DETAILS_FAIL, COURSE_UPDATE_REQUEST, COURSE_UPDATE_SUCCESS, COURSE_UPDATE_FAIL, COURSE_CREATE_REQUEST, COURSE_CREATE_SUCCESS, COURSE_CREATE_FAIL, COURSE_DELETE_FAIL, COURSE_DELETE_SUCCESS, COURSE_DELETE_REQUEST } from '../constants/courseConstants'
import Axios from 'axios';

export const listCourses = () => async (dispatch) => {
    dispatch({ type: COURSE_LIST_REQUEST });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/courses');
        dispatch({ type: COURSE_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: COURSE_LIST_FAIL, payload: error.message });
    }
}

export const detailsCourse = (courseId) => async (dispatch) => {
    dispatch({ type: COURSE_DETAILS_REQUEST, payload: courseId });

    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/courses/${courseId}`);
        dispatch({ type: COURSE_DETAILS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: COURSE_DETAILS_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const updateCourse = (course) => async (dispatch, getState) => {
    dispatch({ type: COURSE_UPDATE_REQUEST, payload: course });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/courses/${course._id}`, course, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: COURSE_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message?
                error.response.data.message:
                error.message;
        dispatch({ type: COURSE_UPDATE_FAIL, error: message });
    }
};

export const createCourse = (course) => async (dispatch, getState) => {
    dispatch({ type: COURSE_CREATE_REQUEST });

    const { userSignin: { userInfo } } = getState();

    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/courses', course, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({ type: COURSE_CREATE_SUCCESS, payload: data.course })
    } catch (error) {
        dispatch({
            type: COURSE_CREATE_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const deleteCourse = (courseId) => async (dispatch, getState) => {
    dispatch({ type: COURSE_DELETE_REQUEST, payload: courseId });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        await Axios.delete(process.env.REACT_APP_DB_ADDRESS + `/api/courses/${courseId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: COURSE_DELETE_SUCCESS });
    } catch (error) {
        const message =
            error.response && error.response.data
                ? error.response.data
                : error.message;
        dispatch({ type: COURSE_DELETE_FAIL, payload: message });
    }
};