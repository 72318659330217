import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createCourse } from '../actions/courseActions';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar'
import Select from "react-select"
import { getTeachers } from '../actions/userActions';

export const CreateCourseScreen = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [tutor, setTutor] = useState(null)
    
    // const [teachers, setTeachers] = useState([])

    const courseCreated = useSelector(state => state.courseCreate)

    const { loading: loadingUsers, error: errorUsers, teachers: teachersFound } = useSelector(state => state.usersTeachers)

    const dispatch = useDispatch();

    // useEffect(() => {
    //     setTeachers(teachersFound.map(u => ({label: u.name, value: u._id})))
    // }, [teachersFound])

    // const handleChange = (selectedOption) => {
    //     setTutor(selectedOption)
    // }

    const submitHandler = (e) => {
        e.preventDefault();
        if(name !== ''){
            dispatch(createCourse({name, description, tutor: tutor?.value}))
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Introduzca un nombre al curso',
                showConfirmButton: true,
                confirmButtonText: 'De acuerdo'
            })
        }
    }

    useEffect(()=>{
        if (courseCreated?.course) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Curso creado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
            navigate(`/course/${courseCreated.course._id}/edit`)
        }
    }, [courseCreated, navigate])

    useEffect(() => {
        dispatch(getTeachers())
    }, [dispatch])

    return (
        <>
            <ToolBar titulo={"Crear curso"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Cursos", enlace: "/courselist" },
                { titulo: "Crear curso", enlace: "#" },
            ]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <form autoComplete='off' id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row" onSubmit={submitHandler}>
                        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-12">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="kt_ecommerce_add_product_general">
                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>General</h2>
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="mb-10 fv-row">
                                                    <label className="required form-label">Nombre del curso</label>
                                                    <input className="form-control mb-2" id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                                    <div className="text-muted fs-7">Se requiere un nombre de curso que sea único.</div>
                                                </div>
                                                <div className="mb-10 fv-row">
                                                    <label className="form-label">Descripción</label>
                                                    <textarea className="form-control mb-3" rows="5" data-kt-element="input" placeholder="Escribe una descripción" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                                </div>
                                                {/* <div className="col-lg-12 fv-row">
                                                    <label className="form-label">Tutor</label>
                                                    {loadingUsers ? (<LoadingBox variant={"primary"} />) :
                                                        errorUsers ? (<AlertMessage variant={"danger"} message={errorUsers} />) : (
                                                            <Select isClearable options={teachers} onChange={handleChange} value={tutor} placeholder="Escoja el tutor del curso" />
                                                        )}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Link to="/courselist" id="kt_ecommerce_add_product_cancel" className="btn btn-light me-5">Cancelar</Link>
                                <button type="submit" id="kt_ecommerce_add_product_submit" className="btn btn-primary">
                                    <span className="indicator-label">Guardar</span>
                                    <span className="indicator-progress">Espere por favor...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
