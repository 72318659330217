import Axios from 'axios'
import {MESSAGE_GET_ALL, MESSAGE_SEND_USER, MESSAGE_READ} from '../constants/messageConstants';

export const getMessagesUser = (user) => async (dispatch, getState) => {
    try {
        const {userSignin: {userInfo}} = getState();
        const {data} = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/messages/user/`+user, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({
            type: MESSAGE_GET_ALL,
            payload: {
                data
            }
        })
    } catch (error) {

    }

}

export const createMessageCourses = (message) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();

    try {
        const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/messages/registercourses', message, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({
            type: MESSAGE_SEND_USER,
            payload: data
        })
        dispatch(getMessagesUser());
    } catch (error) {}
}

export const createMessageExtracurricularActivities = (message) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();

    try {
        const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/messages/registerextracurricularactivities', message, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({
            type: MESSAGE_SEND_USER,
            payload: data
        })
        dispatch(getMessagesUser());
    } catch (error) {}
}

export const createMessageStudents = (message) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();

    try {
        const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/messages/registerstudents', message, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({
            type: MESSAGE_SEND_USER,
            payload: data
        })
        dispatch(getMessagesUser());
    } catch (error) {}
}

export const responseMessage = (datos) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();

    try {
        const {data} = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/messages/'+datos.idmessage, {
            sender: datos.receiver,
            receiver: datos.sender,
            message: datos.message
        }, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({
            type: MESSAGE_SEND_USER,
            payload: data
        })
        dispatch(getMessagesUser());
    } catch (error) {}
}

export const readMessage = (id) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();
    try {
        const {data} = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/messages/${id}/read`, null, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch(getMessagesUser(userInfo._id))
        dispatch({
            type: MESSAGE_READ,
            payload: {
                message: data.message
            }
        })
    } catch (error) {

    }
}

export const favouriteMessage = (id) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();
    try {
        const {data} = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/messages/${id}/favourite`, {
            user: userInfo._id
        }, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch(getMessagesUser(userInfo._id))
        dispatch({
            type: MESSAGE_READ,
            payload: {
                message: data.message
            }
        })
    } catch (error) {

    }
}

export const removeMessage = (id) => async (dispatch, getState) => {
    const {userSignin: {userInfo}} = getState();
    try {
        const {data} = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/messages/${id}/remove`, {
            user: userInfo._id
        }, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch(getMessagesUser(userInfo._id))
        dispatch({
            type: MESSAGE_READ,
            payload: {
                message: data.message
            }
        })
    } catch (error) {

    }
}