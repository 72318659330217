import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2';
import { getNotificationsUser } from '../actions/notificationActions';
import { changeToPaid, listOrdersNotPaid } from '../actions/orderActions';
// import { deleteOrder, listOrders } from '../actions/orderActions';
import { AlertMessage } from '../components/AlertMessage';
import { CustomDataTable } from '../components/CustomDataTable';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar';
import { ORDER_CHANGE_TO_PAID_RESET } from '../constants/orderConstants';
import removeAccents from '../utils/removeAccents';

export const OrdersNotPaidListScreen = () => {
  const orderList = useSelector(state => state.orderListNotPaid);
  const {loading, error, orders} = orderList;

  const [ordersFiltered, setOrdersFiltered] = useState([])
  const [minDate, setMinDate] = useState('')
  const [maxDate, setMaxDate] = useState('')

  const ordersChangeToPaid = useSelector(state => state.ordersChangeToPaid)    
  const { loading: loadingChanged, success: successChanged} = ordersChangeToPaid;

  const closeModalBtn = useRef();
  const [orderSelected, setOrderSelected] = useState(null)
  const [dateSelected, setDateSelected] = useState('')

  const dispatch = useDispatch();


  const columns = [
      {          
        name: 'REFERENCIA',
        cell: row => <span>{row.orderReference}</span>,
        selector: (row) => row.orderReference,
        sortable: true,          
      },
      {
        name: "CLIENTE",
        selector: (row) => removeAccents(row.user?.name),
        sortable: true,
        cell: (row) => <span>{row.user?.name}</span>,
      },
      {
        name: "FECHA",
        cell: row => <span>{row.createdAt.substring(0, 10)}</span>,
        selector: (row) => row.createdAt.substring(0, 10),
        sortable: true,
        right: true
      },
      {
        name: "TOTAL",
        selector: (row) => row.totalPrice,
        sortable: true,
        right: true,
        cell: (row) => <span>{row.totalPrice.toFixed(2)} €</span>,
      },
      {
        name: "PRODUCTOS",
        selector: (row) => row.orderItems.map((i, k) => (<div className='mb-1' key={k}>{removeAccents(i.name)}</div>)),
        sortable: true,
        right: true,
        cell: (row) => <div>{row.orderItems.map((i, k) => (<div className='mb-1' key={k}>{i.name}</div>))}</div>,
      },
      {
        name: "",
        cell: row => 
          <div className="row">
              <div className="col-12">
                <i className="bi bi-credit-card-2-back-fill px-2" onClick={() => {setOrderSelected(row)}} style={{fontSize: '1.4rem', cursor: 'pointer'}} data-bs-toggle="modal" data-bs-target="#staticBackdrop"></i>                
              </div>
          </div>,
        center: true,
      }
    ];

    const submitChangeToPaid = (e) => {
      e.preventDefault()
      dispatch(changeToPaid(orderSelected._id, dateSelected !== '' ? new Date(dateSelected) : new Date()))
    }

    useEffect(() => {
      dispatch(listOrdersNotPaid());
      if (successChanged) {
        dispatch({ type: ORDER_CHANGE_TO_PAID_RESET});
        dispatch(getNotificationsUser());
        setDateSelected('')
        setOrderSelected(null)
        closeModalBtn.current.click()
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Pedido actualizado correctamente',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }, [dispatch, successChanged])

    useEffect(() => {
      setOrdersFiltered(orders)
    }, [orders])

    useEffect(() => {
      let ordersCopy = orders
      console.log(ordersCopy)
      if (minDate.trim() !== '') {
        ordersCopy = ordersCopy?.filter(o => new Date(o.createdAt) >= new Date(minDate))
      }
      if (maxDate.trim() !== '') {
        let dateObj = new Date(maxDate)
        ordersCopy = ordersCopy?.filter(o => new Date(o.createdAt) <= dateObj.setDate(dateObj.getDate() + 1))
      }
      setOrdersFiltered(ordersCopy || [])
    }, [minDate, maxDate, orders])

    return (
    <>
    <ToolBar titulo={"Pedidos"} hitos={[
        {titulo: "Inicio", enlace: "/" },
        {titulo: "Pedidos", enlace: "#" }]}/>
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
      <div className="content flex-row-fluid" id="kt_content">        
        <div className="card card-flush">
            <div className="card-body">
              <div className='row mb-6'>
                <div className='col-6'>
                  <label className='text-muted'>Fecha de comienzo</label>
                  <input className='form-control' type="date" name='minDate' value={minDate} onChange={(e) => {setMinDate(e.target.value)}}/>
                </div>
                <div className='col-6'>
                  <label className='text-muted'>Fecha de fin</label>
                  <input className='form-control' type="date" name='maxDate' value={maxDate} onChange={(e) => {setMaxDate(e.target.value)}}/>
                </div>
              </div>
              { loading ?  
                  <LoadingBox variant={"primary"}/> : 
              error? 
                  <AlertMessage variant={"danger"} message={error}/> : 
                (
                    <CustomDataTable data={ordersFiltered} columns={columns}/>
                )
              }
              <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Pedido {orderSelected?.orderReference}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        <form autoComplete='off' className="form w-100 row" id="kt_sign_in_form" onSubmit={submitChangeToPaid}>
                            <div className="mb-10 fv-row">
                                <label className="required form-label">Fecha de pago</label>
                                <input required className="form-control mb-2" id="name" type="date" value={dateSelected} onChange={(e) => setDateSelected(e.target.value)} />
                            </div>
                            <div className="text-end">
                                <button type="submit" id="kt_sign_in_submit" className="btn btn-primary me-1" disabled={loadingChanged ? 'disabled' : ''}>
                                {
                                  loadingChanged ? (
                                        <span className="indicator-progress">Espere por favor...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    ) :
                                    (<span className="indicator-label">Aceptar</span>)
                                }
                                </button>
                                <button ref={closeModalBtn} type="button" id="closeModal" className="btn btn-secondary ms-1" data-bs-dismiss="modal">Cerrar</button>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    </>
    )
}
