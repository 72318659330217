import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { signin } from '../actions/userActions'
import { ToolBar } from '../components/ToolBar'

export const SigninScreen = (props) => {

    const [username, setUsername] = useState(localStorage.getItem('username') || '')
    const [password, setPassword] = useState('')
    const [passwordShow, setPasswordShow] = useState(false)

    const navigate = useNavigate();
    const userSignin = useSelector((state) => state.userSignin);
    const {userInfo, error, loading} = userSignin

    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(signin(username, password));
    }

    useEffect(() => {
      if (userInfo) {
          if (userInfo.isFirstLogin) {
          navigate('/profile?isFirstLogin=true')
          }
          else {
            navigate('/')
          }
      }          
    }, [userInfo, navigate])
    
    return (
    <>      
    <ToolBar titulo={"Iniciar sesión"} hitos={[{titulo: "Inicio", enlace: "/" }]}/>
    <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">      
        <form autoComplete='off' className="form w-100" noValidate="novalidate" id="kt_sign_in_form" onSubmit={submitHandler}>
            <div className="text-center mb-10">
                <Link to={`/`} className="symbol symbol-50px mb-3">
                    <span className="symbol-label" style={{ height: "150px", width: "150px", backgroundColor: "white",backgroundImage: `url(/assets/media/logos/logo-elcarmen.png)`, backgroundSize: 'contain'}}></span>
                </Link>
                <h1 className="text-dark mb-3">Iniciar sesión</h1>
                
                {/* <div className="text-gray-400 fw-bold fs-4">¿Eres nuevo?
                <Link to="/register" className="link-primary fw-bolder"> Crear cuenta</Link>
                </div> */}
            </div>
            <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bolder text-dark">Usuario</label>
                <input type="text" id="username" placeholder="Usuario" className="form-control form-control-lg form-control-solid" required onChange={ e => setUsername(e.target.value)} value={username}/>
            </div>
            <div className="fv-row mb-10">
                <div className="d-flex flex-stack mb-2">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">Contraseña</label>
                    {/* <a href="../../demo2/dist/authentication/layouts/basic/password-reset.html" className="link-primary fs-6 fw-bolder">¿Has olvidado tu contraseña?</a> */}
                </div>
                <div className='position-relative'>
                    <input type={passwordShow ? 'text' : 'password'} id="password" placeholder="Contraseña" className="form-control form-control-lg form-control-solid" required onChange={ e => setPassword(e.target.value)}/>
                    <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={() => {setPasswordShow(!passwordShow)}}>
                        {
                        passwordShow 
                        ? <i className="bi bi-eye-slash fs-2"></i>
                        : <i className="bi bi-eye fs-2"></i>
                        }
                    </span>
                </div>

            </div>
            <div className="text-center">
                <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
                    {
                        loading ? (
                            <span className="indicator-progress">Espere por favor...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    ) :
                    (<span className="indicator-label">Iniciar sesión</span>)
                    }
                </button>
                <Link to={'/forgotpassword'} className="link-primary fs-6 fw-bolder">¿Has olvidado tu contraseña?</Link>
            </div>
            { error ? (
                <div className="alert alert-danger">
                    <div className="d-flex flex-column">
                        <span>{error}</span>
                    </div>  
                </div>
        ) : ''}
        
        </form>
    </div>
    </>
    )
}
