import Axios from "axios"
import { SUBSCRIPTION_CANCEL_FAIL, SUBSCRIPTION_CANCEL_REQUEST, SUBSCRIPTION_CANCEL_SUCCESS, SUBSCRIPTION_LIST_FAIL, SUBSCRIPTION_LIST_REQUEST, SUBSCRIPTION_LIST_SUCCESS, SUBSCRIPTION_MINE_LIST_FAIL, SUBSCRIPTION_MINE_LIST_REQUEST, SUBSCRIPTION_MINE_LIST_SUCCESS, SUBSCRIPTION_PAID_BY_DATE_FAIL, SUBSCRIPTION_PAID_BY_DATE_REQUEST, SUBSCRIPTION_PAID_BY_DATE_SUCCESS, SUBSCRIPTION_PAYMENT_SUCCESS, SUBSCRIPTION_REQUEST_CANCEL_FAIL, SUBSCRIPTION_REQUEST_CANCEL_REQUEST, SUBSCRIPTION_REQUEST_CANCEL_SUCCESS, SUBSCRIPTION_SALES_SUBSCRIPTIONPRODUCT_REQUEST, SUBSCRIPTION_SALES_SUBSCRIPTIONPRODUCT_SUCCESS } from "../constants/subscriptionConstants";

/**
 * 
 * @param {string} productId 
 * @returns 
 */
export const getSalesSubscriptionProduct = (subscriptionProductId) => async(dispatch, getState) => {
    dispatch({type: SUBSCRIPTION_SALES_SUBSCRIPTIONPRODUCT_REQUEST })
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/subscriptions/subscription/subscriptionproduct/${subscriptionProductId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({type: SUBSCRIPTION_SALES_SUBSCRIPTIONPRODUCT_SUCCESS, payload: data })
    } catch (error) {
        console.log(error);
    }
}

export const paySubscription = (subscriptionId, cancelAt) => async (dispatch, getState) => {
    const { userSignin: { userInfo } } = getState();
    try {
        const {data} = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/subscriptions/${subscriptionId}/pay`, {cancelAt}, {
            headers: {
                Authorization: `Bearer ${userInfo?.token}`,
            }
        })

        dispatch({type: SUBSCRIPTION_PAYMENT_SUCCESS, payload: data})
    } catch (error) {
        console.error(error)
    }
}

export const listSubscriptionMine = () => async(dispatch, getState) => {
    dispatch({ type: SUBSCRIPTION_MINE_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/subscriptions/mine', {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: SUBSCRIPTION_MINE_LIST_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: SUBSCRIPTION_MINE_LIST_FAIL, payload: message })
    }
}

export const cancelSubscription = (stripeId, alternativePayment) => async(dispatch, getState) => {
    dispatch({type: SUBSCRIPTION_CANCEL_REQUEST})

    const { userSignin: { userInfo } } = getState();

    try {
        const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/subscriptions/cancel', {stripeId, alternativePayment}, {
            headers: {
                Authorization: `Bearer ${userInfo?.token}`,
            }
        })

        dispatch({type: SUBSCRIPTION_CANCEL_SUCCESS, payload: data})
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: SUBSCRIPTION_CANCEL_FAIL, payload: message })
    }
}

export const requestCancelSubscription = (id) => async(dispatch, getState) => {
	dispatch({type: SUBSCRIPTION_REQUEST_CANCEL_REQUEST})

	const { userSignin: { userInfo } } = getState();

	try {
		const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/subscriptions/requestcancel', {id}, {
			headers: {
				Authorization: `Bearer ${userInfo?.token}`,
			}
		})

		dispatch({type: SUBSCRIPTION_REQUEST_CANCEL_SUCCESS, payload: data})
	} catch (error) {
		const message =
			error.response && error.response.data.message?
			error.response.data.message:
			error.message;
		dispatch({ type: SUBSCRIPTION_REQUEST_CANCEL_FAIL, payload: message })
	}
}

export const listSubscriptions = () => async(dispatch, getState) => {
    dispatch({ type: SUBSCRIPTION_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/subscriptions', {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: SUBSCRIPTION_LIST_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: SUBSCRIPTION_LIST_FAIL, payload: message })
    }
}

/**
 * 
 * @param {JSON} params 
 * @returns 
 */
export const findSubscriptionsPaidByDate = (params) => async (dispatch, getState) => {
    dispatch({ type: SUBSCRIPTION_PAID_BY_DATE_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + `/api/subscriptions/paidbydate`, params, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: SUBSCRIPTION_PAID_BY_DATE_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: SUBSCRIPTION_PAID_BY_DATE_FAIL, payload: message })
    }
}