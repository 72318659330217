import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { listOrderMine } from '../actions/orderActions';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar';
import { CustomDataTable } from '../components/CustomDataTable';
import { getNotificationsUser } from '../actions/notificationActions';

export const OrderHistoryScreen = (props) => {
    const navigate = useNavigate();
    const orderMineList = useSelector(state => state.orderMineList);    
    const { orders, error, loading } = orderMineList;
    const dispatch = useDispatch();


    const columns = [
          {
          name: "REFERENCIA",
          selector: (row) => row.orderReference,
          sortable: true,        
          cell: (row) => <span>{row.orderReference}</span>,
          },
          {
          name: "FECHA",
          selector: (row) => row.createdAt,
          sortable: true,
          cell: (row) => <span>{row.createdAt.substring(0, 10)}</span>,
          },
          {
          name: "TOTAL",
          selector: (row) => row.totalPrice,
          sortable: true,          
          cell: (row) => <span>{row.totalPrice.toFixed(2)} €</span>,
          },          
          {
          name: "¿PAGADO?",
          selector: (row) => row.isPaid,
          sortable: true,          
          cell: (row) => <span>{row.isPaid ? row.paidAt?.substring(0, 10): 'No'}</span>,
          }, 
          {
          name: "MÉTODO DE PAGO",
          selector: (row) => row.isPaid ? row.paymentMethod: '-',
          sortable: true,          
          cell: (row) => <span>{row.isPaid ? row.paymentMethod: '-'}</span>,
          },
          {
          name: "",
          cell: row => 
            <div className="row">
                <div className="col-12">
                <i className="bi bi-eye-fill px-2" onClick={() => { navigate(`/order/${row._id}`); }} style={{fontSize: '1.4rem', cursor: 'pointer'}}></i>                
                </div>
            </div>,
            // <div className="dropdown dropup">
            //     <button className="btn btn-sm btn-light btn-active-light-primary" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            //         Acciones
            //         <span className="svg-icon svg-icon-5 m-0">
            //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            //                 <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
            //             </svg>
            //         </span> 
            //     </button>
            //     <ul className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" aria-labelledby="dropdownMenuButton1">                    
            //         <li className="menu-item px-3"><button className="dropdown-item menu-link px-3" onClick={() => { navigate(`/order/${row._id}`); }} >Detalles</button></li>
            //     </ul>
            // </div>,
          center: true,            
        }
      ];

    useEffect(() => {
      dispatch(listOrderMine())
      dispatch(getNotificationsUser());
    }, [dispatch])
    

    return ( 
    <>
    <ToolBar titulo={"Historial de pedidos"} hitos={[
        {titulo: "Inicio", enlace: "/" },
        {titulo: "Pedidos", enlace: "#" }]}/>
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">						
      <div className="content flex-row-fluid" id="kt_content">        
        <div className="card card-flush">            
            <div className="card-body">
            { loading ?  
                <LoadingBox variant={"primary"}/> : 
              error? 
                <AlertMessage variant={"danger"} message={error}/> : 
                (      
                <CustomDataTable data={orders} columns={columns}/>                       
                )
            }
            </div>    
        </div>
      </div>
    </div>
    </>        
    )
}