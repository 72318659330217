import { EXTRACURRICULARACTIVITY_LIST_SUCCESS, EXTRACURRICULARACTIVITY_LIST_FAIL, EXTRACURRICULARACTIVITY_DETAILS_SUCCESS, EXTRACURRICULARACTIVITY_DETAILS_FAIL, EXTRACURRICULARACTIVITY_LIST_REQUEST, EXTRACURRICULARACTIVITY_CREATE_REQUEST, EXTRACURRICULARACTIVITY_CREATE_FAIL, EXTRACURRICULARACTIVITY_CREATE_SUCCESS, EXTRACURRICULARACTIVITY_UPDATE_REQUEST, EXTRACURRICULARACTIVITY_UPDATE_SUCCESS, EXTRACURRICULARACTIVITY_UPDATE_FAIL, EXTRACURRICULARACTIVITY_DELETE_REQUEST, EXTRACURRICULARACTIVITY_DELETE_SUCCESS, EXTRACURRICULARACTIVITY_DELETE_FAIL, EXTRACURRICULARACTIVITY_DETAILS_REQUEST, EXTRACURRICULARACTIVITY_SPLITTED_LIST_REQUEST, EXTRACURRICULARACTIVITY_SPLITTED_LIST_SUCCESS, EXTRACURRICULARACTIVITY_SPLITTED_LIST_FAIL, EXTRACURRICULARACTIVITY_DUPLICATE_REQUEST, EXTRACURRICULARACTIVITY_DUPLICATE_SUCCESS, EXTRACURRICULARACTIVITY_DUPLICATE_FAIL } from '../constants/extracurricularActivityConstants'
import Axios from 'axios';

export const listExtracurricularActivities = () => async(dispatch) => {
    dispatch({ type: EXTRACURRICULARACTIVITY_LIST_REQUEST });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/extracurricularActivities');
        dispatch({ type: EXTRACURRICULARACTIVITY_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: EXTRACURRICULARACTIVITY_LIST_FAIL, payload: error.message });
    }
}

export const listCategoryExtracurricularActivities = (categoryId) => async(dispatch) => {
    dispatch({ type: EXTRACURRICULARACTIVITY_LIST_REQUEST });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/extracurricularActivities/category/' + categoryId);
        dispatch({ type: EXTRACURRICULARACTIVITY_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: EXTRACURRICULARACTIVITY_LIST_FAIL, payload: error.message });
    }
}

export const detailsExtracurricularActivity = (extracurricularActivityId) => async(dispatch) => {
    dispatch({ type: EXTRACURRICULARACTIVITY_DETAILS_REQUEST, payload: extracurricularActivityId });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/extracurricularActivities/${extracurricularActivityId}`);
        dispatch({ type: EXTRACURRICULARACTIVITY_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: EXTRACURRICULARACTIVITY_DETAILS_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const getAvailableSplittedExtracurricularActivities = () => async(dispatch) => {
    dispatch({ type: EXTRACURRICULARACTIVITY_SPLITTED_LIST_REQUEST });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/extracurricularActivities/available/splitted');
        dispatch({ type: EXTRACURRICULARACTIVITY_SPLITTED_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: EXTRACURRICULARACTIVITY_SPLITTED_LIST_FAIL, payload: error.message });
    }
}

export const getAvailableExtracurricularActivities = () => async(dispatch) => {
    dispatch({ type: EXTRACURRICULARACTIVITY_LIST_REQUEST });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/extracurricularActivities/available');
        dispatch({ type: EXTRACURRICULARACTIVITY_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: EXTRACURRICULARACTIVITY_LIST_FAIL, payload: error.message });
    }
}

export const createExtracurricularActivity = (extracurricularActivity) => async(dispatch, getState) => {
    dispatch({ type: EXTRACURRICULARACTIVITY_CREATE_REQUEST });
    const { userSignin: { userInfo } } = getState();

    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/extracurricularActivities', extracurricularActivity, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({ type: EXTRACURRICULARACTIVITY_CREATE_SUCCESS, payload: data.extracurricularActivity })
    } catch (error) {
        dispatch({
            type: EXTRACURRICULARACTIVITY_CREATE_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const deleteExtracurricularActivity = (extracurricularActivityId) => async (dispatch, getState) => {
    dispatch({ type: EXTRACURRICULARACTIVITY_DELETE_REQUEST, payload: extracurricularActivityId });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      await Axios.delete(process.env.REACT_APP_DB_ADDRESS + `/api/extracurricularActivities/${extracurricularActivityId}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({ type: EXTRACURRICULARACTIVITY_DELETE_SUCCESS });
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({ type: EXTRACURRICULARACTIVITY_DELETE_FAIL, payload: message });
    }
};

export const updateExtracurricularActivity = (extracurricularActivity) => async(dispatch, getState) => {
    dispatch({ type: EXTRACURRICULARACTIVITY_UPDATE_REQUEST, payload: extracurricularActivity });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/extracurricularActivities/${extracurricularActivity._id}`, extracurricularActivity, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: EXTRACURRICULARACTIVITY_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: EXTRACURRICULARACTIVITY_UPDATE_FAIL, error: message });
    }
};

export const duplicateExtracurricularActivity = (extracurricularActivity, copyPrice, copyCourses, copyStudents) => async(dispatch, getState) => {
    dispatch({ type: EXTRACURRICULARACTIVITY_DUPLICATE_REQUEST, payload: extracurricularActivity });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + `/api/extracurricularActivities/duplicate/${extracurricularActivity._id}`, {
            copyPrice: copyPrice,
            copyCourses: copyCourses,
            copyStudents: copyStudents
        }, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: EXTRACURRICULARACTIVITY_DUPLICATE_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: EXTRACURRICULARACTIVITY_DUPLICATE_FAIL, payload: message });
    }
}