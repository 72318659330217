import React, {useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { resetPassword } from '../actions/userActions'
import { AlertMessage } from '../components/AlertMessage'
import { ToolBar } from '../components/ToolBar'
import { useParams } from 'react-router';


export const ResetPasswordScreen = (props) => {
    const params = useParams();
    
    const userId = params.id;

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [passwordShow, setPasswordShow] = useState(false)
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)

    const dispatch = useDispatch();
    
    const userResetPassword = useSelector(state => state.userResetPassword);
    const { error: errorUpdate } = userResetPassword;

    const submitHandler = (e) => {
        e.preventDefault();
        // dispatch update
        if(password !== confirmPassword) {
            Swal.fire({
                showConfirmButton: true,
                icon: 'error',
                text: 'Las contraseñas no coinciden'
            })            
        } else {
            dispatch(resetPassword({ userId, password}))            
            setPassword('');
            setConfirmPassword('');
            
            Swal.fire({
                showConfirmButton: true,
                icon: 'success',
                text: 'Contraseña actualizada'
            })            
            
            
        }
    }
    
    return (
    <>
    <ToolBar titulo={"Recuperar contraseña"} hitos={[{titulo: "Inicio", enlace: "/" }, {titulo: "Recuperar contraseña", enlace: "#" }]}/>
    <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
        <form autoComplete='off' className="form w-100" noValidate="novalidate" id="kt_sign_in_form" onSubmit={submitHandler}>
            <div className="text-center mb-10">
                <Link to={`/`} className="symbol symbol-50px mb-3">
                    <span className="symbol-label" style={{ backgroundImage: `url(/assets/media/logos/logo-elcarmen.png)`, backgroundSize: 'contain'}}></span>
                </Link>
                <h1 className="text-dark mb-3">Nueva contraseña</h1>
            </div>
            <div className="row mb-6">
                <label className="col-lg-12 col-form-label required fw-bold fs-6">Contraseña</label>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12 fv-row position-relative">
                            <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={password} id="password" type={passwordShow ? 'text' : 'password'} placeholder="Introduce tu contraseña" onChange={ (e) => setPassword(e.target.value)} />
                            <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={() => {setPasswordShow(!passwordShow)}}>
                                {
                                passwordShow 
                                ? <i className="bi bi-eye-slash fs-2"></i>
                                : <i className="bi bi-eye fs-2"></i>
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-6">
                <label className="col-lg-12 col-form-label required fw-bold fs-6">Confirmar contraseña</label>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12 fv-row position-relative">
                            <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={confirmPassword} id="confirmPassword" type={confirmPasswordShow ? 'text' : 'password'} placeholder="Introduce de nuevo tu contraseña" onChange={ (e) => setConfirmPassword(e.target.value)} />
                            <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={() => {setConfirmPasswordShow(!confirmPasswordShow)}}>
                                {
                                confirmPasswordShow 
                                ? <i className="bi bi-eye-slash fs-2"></i>
                                : <i className="bi bi-eye fs-2"></i>
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="text-center">
                <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
                    <span className="indicator-progress">Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    
                    <span className="indicator-label">Cambiar contraseña</span> 
                    
                </button>
                    {errorUpdate && (
                        <AlertMessage variant={"danger"} message={errorUpdate}/>
                    )} 
                
            </div>
        </form>
    </div>
    </>
    )
}
