import React, {
    useEffect,
    useRef
} from 'react'
import ApexCharts from 'apexcharts'

export const BarChart = (props) => {
    const chartRef = useRef(null)

    useEffect(() => {
        const chartOptions = {
            series: [{
                    name: 'Ventas',
                    data: props.series,
                    margin: {
                        left: 5,
                        right: 5
                    }
                },
            ],
            chart: {
                fontFamily: 'inherit',
                type: 'bar',
                height: 80,
                toolbar: {
                    show: false,
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '35%',
                    borderRadius: 10,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: props.categories,
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    style: {
                        colors: 'rgb(53,149,246)',
                        fontSize: '12px',
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#009EF7',
                        fontSize: '12px',
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: function (val) {
                        return val
                    },
                },
            },
            colors: ['#009EF7', 'gray'],
            grid: {
                borderColor: 'lightgray',
                strokeDashArray: false,
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
            },
        }
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(chartRef.current, chartOptions)
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, props])
    return (
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '80px'}} />
    )
}