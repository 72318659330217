export const EXTRACURRICULARACTIVITYINSCRIPTION_SALES_EXTRACURRICULARACTIVITYINSCRIPTIONPRODUCT_REQUEST = 'EXTRACURRICULARACTIVITYINSCRIPTION_SALES_EXTRACURRICULARACTIVITYINSCRIPTIONPRODUCT_REQUEST'
export const EXTRACURRICULARACTIVITYINSCRIPTION_SALES_EXTRACURRICULARACTIVITYINSCRIPTIONPRODUCT_SUCCESS = 'EXTRACURRICULARACTIVITYINSCRIPTION_SALES_EXTRACURRICULARACTIVITYINSCRIPTIONPRODUCT_SUCCESS'

export const EXTRACURRICULARACTIVITYINSCRIPTION_PAYMENT_SUCCESS = 'EXTRACURRICULARACTIVITYINSCRIPTION_PAYMENT_SUCCESS'
export const EXTRACURRICULARACTIVITYINSCRIPTION_PAYMENT_RESET = 'EXTRACURRICULARACTIVITYINSCRIPTION_PAYMENT_RESET'

export const EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_REQUEST = 'EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_REQUEST'
export const EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_SUCCESS = 'EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_SUCCESS'
export const EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_FAIL = 'EXTRACURRICULARACTIVITYINSCRIPTION_MINE_LIST_FAIL'

export const EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_REQUEST = 'EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_REQUEST'
export const EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_SUCCESS = 'EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_SUCCESS'
export const EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_FAIL = 'EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_FAIL'
export const EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_RESET = 'EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_RESET'

export const EXTRACURRICULARACTIVITYINSCRIPTION_LIST_REQUEST = 'EXTRACURRICULARACTIVITYINSCRIPTION_LIST_REQUEST'
export const EXTRACURRICULARACTIVITYINSCRIPTION_LIST_SUCCESS = 'EXTRACURRICULARACTIVITYINSCRIPTION_LIST_SUCCESS'
export const EXTRACURRICULARACTIVITYINSCRIPTION_LIST_FAIL = 'EXTRACURRICULARACTIVITYINSCRIPTION_LIST_FAIL'

export const EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_REQUEST = 'EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_REQUEST'
export const EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_SUCCESS = 'EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_SUCCESS'
export const EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_FAIL = 'EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_FAIL'
export const EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_RESET = 'EXTRACURRICULARACTIVITYINSCRIPTION_ENROLL_RESET'

export const EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_REQUEST = 'EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_REQUEST'
export const EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_FAIL = 'EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_FAIL'
export const EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_RESET = 'EXTRACURRICULARACTIVITYINSCRIPTION_MANAGE_RESET'

export const EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_REQUEST = 'EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_REQUEST'
export const EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_SUCCESS = 'EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_SUCCESS'
export const EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_FAIL = 'EXTRACURRICULARACTIVITYINSCRIPTION_DETAILS_FAIL'

export const EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_REQUEST = 'EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_REQUEST'
export const EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_SUCCESS = 'EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_SUCCESS'
export const EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_FAIL = 'EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_FAIL'
export const EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_RESET = 'EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_RESET'

export const EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_REQUEST = 'EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_REQUEST'
export const EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_SUCCESS = 'EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_SUCCESS'
export const EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_FAIL = 'EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_FAIL'
export const EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_RESET = 'EXTRACURRICULARACTIVITYINSCRIPTION_PAID_BY_DATE_RESET'