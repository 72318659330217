import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AlertMessage } from '../components/AlertMessage';
import { CustomDataTable } from '../components/CustomDataTable';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar'
import Swal from 'sweetalert2';
import { getNotificationsUser } from '../actions/notificationActions';
import { createMessageExtracurricularActivities } from '../actions/messageActions';
import { Editor } from "@tinymce/tinymce-react";
import removeAccents from '../utils/removeAccents';
import Select from "react-select"
import { generatePath } from 'react-router-dom';
import { getAvailableSubscriptions } from '../actions/subscriptionProductActions';
import { getAvailableProducts } from '../actions/productActions';
import { getAvailableExtracurricularActivities, getAvailableSplittedExtracurricularActivities } from '../actions/extracurricularActivityActions';

export const ExtracurricularActivityMessageScreen = () => {
    const dispatch = useDispatch();
    
    const {loading, error, extracurricularActivitiesSplitted} = useSelector((state) => state.extracurricularActivitySplittedList);

    const {loading: loadingProducts, error: errorProducts, products} = useSelector((state) => state.productList);
    const {loading: loadingSubscriptionProducts, error: errorSubscriptionProducts, subscriptionProducts} = useSelector((state) => state.subscriptionProductList);
    const {loading: loadingExtracurricularActivities, error: errorExtracurricularActivities, extracurricularActivities} = useSelector((state) => state.extracurricularActivityList);

    const { userInfo } = useSelector(state => state.userSignin);
    const [message, setMessage] = useState('')
    const message1 = useRef(null);
    const [subject, setSubject] = useState('')
    const [sendingMessage, setsendingMessage] = useState(false)
    const [selectedExtracurricularActivities, setSelectedExtracurricularActivities] = useState([])

    const [update, forceUpdate] = useState(0)

    const [productOptions, setProductOptions] = useState([])
    const [productSelected, setProductSelected] = useState(null)

    const pushMessageHandler = async(e) => {
        e.preventDefault();
        if (selectedExtracurricularActivities.length > 0) {
            setsendingMessage(true)
            dispatch(createMessageExtracurricularActivities({
                sender: userInfo._id,
                subject: subject,
                extracurricularActivities: selectedExtracurricularActivities,
                message: message
            }))
            setsendingMessage(false)
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Mensajes enviados correctamente',
                showConfirmButton: false,
                timer: 1500
            })
        }

        setSelectedExtracurricularActivities([]);
        setMessage('')
        setSubject('')
    }

    const addExtracurricularActivity = async(e, extracurricularActivitySelected) => {
        if (e.target.checked) {
            setSelectedExtracurricularActivities([...selectedExtracurricularActivities, extracurricularActivitySelected])
        }else{
            setSelectedExtracurricularActivities(selectedExtracurricularActivities?.filter(extracurricularActivity => extracurricularActivity._id !== extracurricularActivitySelected._id || extracurricularActivity.fee !== extracurricularActivitySelected.fee))
        }
    }

    const columns = [
        {
            name: 'Seleccionado para enviar',
            cell: row =>
                <div className="fv-row">
                    <label className='form-check form-switch form-check-custom form-check-solid'>
                        <input className='form-check-input' checked={selectedExtracurricularActivities.find(e => e._id === row._id && e.fee === row.fee) ? true : false} type='checkbox' onChange={e => addExtracurricularActivity(e, row)}/>
                    </label>
                </div>,
            selector: (row) => removeAccents(row.name),
            sortable: true,
        },
        {
            name: 'Actividad extraescolar',
            cell: row =>
                <div className="d-flex align-items-center">                    
                    <div className="ms-5">
                        <span className="text-gray-800 text-hover-primary fs-5 fw-bolder">{row.name}</span>
                    </div>
                </div>,
            selector: (row) => removeAccents(row.name),
            sortable: true,
        }
    ];

    const addProduct = () => {
        if (productSelected) {
            const productLink = generatePath(`/${productSelected.type}/:id`, {
                id: productSelected._id
            })
            let messageParts = message.split('</p>')

            if (messageParts.length === 1 && messageParts[0] === '') {
                messageParts[0] = '<p>' + messageParts[0]
            }
            
            if (messageParts[messageParts.length - 1] === '' && messageParts.length !== 1) {
                messageParts.splice(messageParts.length - 1, 1)
            }

            messageParts[messageParts.length - 1] += ` <a href="${productLink}">${productSelected.name}</a>&nbsp;`
            setMessage(messageParts.join('</p>'))
            message1?.current?.setContent(messageParts.join('</p>'))

            setProductSelected(null)
            forceUpdate(update + 1)
        }
    }

    useEffect(() => {
        dispatch(getAvailableSplittedExtracurricularActivities())
        dispatch(getNotificationsUser());
        dispatch(getAvailableExtracurricularActivities())
        dispatch(getAvailableSubscriptions())
        dispatch(getAvailableProducts())
        setProductOptions([])
    }, [dispatch])

    useEffect(() => {
        let productToUse = []
        
        productToUse = productToUse.concat(products?.map(product => (
            {...product, label: product.name, value: product._id, type: 'product'}
        )))

        productToUse = productToUse.concat(subscriptionProducts?.map(subscriptionProduct => (
            {...subscriptionProduct, label: subscriptionProduct.name, value: subscriptionProduct._id, type: 'subscriptionProduct'}
        )))

        productToUse = productToUse.concat(extracurricularActivities?.map(extracurricularActivity => (
            {...extracurricularActivity, label: extracurricularActivity.name, value: extracurricularActivity._id, type: 'extracurricularActivity'}
        )))

        productToUse.sort((a, b) => {
            return a.name.localeCompare(b.name)
        })

        setProductOptions(productToUse)
    }, [products, subscriptionProducts, extracurricularActivities])

    return (
        <>
            <ToolBar titulo={"Mensajería"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Cursos", enlace: "#" },
            ]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <form autoComplete='off' className="form w-100" noValidate="novalidate" id="kt_sign_in_form" onSubmit={pushMessageHandler}>
                    <div className="content flex-row-fluid" id="kt_content">
                        {error ? <AlertMessage variant={"danger"} message={error} /> : <></>}
                        {errorProducts ? <AlertMessage variant={"danger"} message={errorProducts} /> : <></>}
                        {errorExtracurricularActivities ? <AlertMessage variant={"danger"} message={errorExtracurricularActivities} /> : <></>}
                        {errorSubscriptionProducts ? <AlertMessage variant={"danger"} message={errorSubscriptionProducts} /> : <></>}
                        {loading || loadingProducts || loadingExtracurricularActivities || loadingSubscriptionProducts ? (<LoadingBox variant={"primary"} />) :
                            (
                                <div className="card card-flush">
                                    <div className="card-body pt-0">
                                        <CustomDataTable data={extracurricularActivitiesSplitted} columns={columns} />
                                        <div className='row mb-4 mt-4'>
                                            <div className='col-12 col-md-4 mb-4 mb-md-0'>
                                                <input className="form-control form-control-lg form-control-solid" id="subject" type="text" placeholder="Asunto" onChange={ e =>setSubject(e.target.value)}/>
                                            </div>
                                            <div className='col-12 col-md-8'>
                                                <div className='row'>
                                                    <div className='col-12 col-md-10 mb-4 mb-md-0'>
                                                        <Select key={update} styles={{menuPortal: base => ({...base, position: 'relative'})}} className='react-select-control' placeholder="Añadir enlace a producto" options={productOptions} selected={productSelected} onChange={selectedValue => setProductSelected(selectedValue)} />
                                                    </div>
                                                    <div className='col-12 col-md-2 d-grid'>
                                                        <button type='button' className='btn btn-primary' onClick={addProduct}>Añadir</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Editor
                                            value={message}
                                            apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
                                            onInit={(evt, editor) => message1.current = editor}
                                            onChange={() => setMessage(message1?.current?.getContent())}
                                            init={{
                                            height: 300,
                                            menubar: false,
                                            plugins: [
                                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                            ],
                                            toolbar: 'undo redo | blocks | ' +
                                                'bold italic forecolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                        <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mt-4">
                                            {sendingMessage ? (<LoadingBox variant={"primary"}/>)
                                            :
                                            (<span className="indicator-label">Enviar mensaje</span>)}
                                        </button>
                                    </div>
                                </div>
                            )}
                    </div>
                </form>
            </div>
        </>
    )
}
