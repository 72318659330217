import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeToPaid, findOrderByCustomFilter, hideOrder } from '../actions/orderActions';
// import { deleteOrder, listOrders } from '../actions/orderActions';
import { AlertMessage } from '../components/AlertMessage';
import { CustomDataTable } from '../components/CustomDataTable';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar';
import removeAccents from '../utils/removeAccents';
import Swal from 'sweetalert2';
import { ORDER_HIDE_RESET } from '../constants/orderConstants';

export const DeleteOrderSelectionScreen = () => {
  const {orders, loading, loadingList, error, result} = useSelector(state => state.orderHide);

  const closeModalBtn = useRef();
  const [orderSelected, setOrderSelected] = useState(null)
  const [deletionType, setDeletionType] = useState('')

  const [idToSearch, setIdToSearch] = useState("")

  const dispatch = useDispatch();


  const columns = [
      {
        name: 'REFERENCIA',
        cell: row => <span>{row.orderReference}</span>,
        selector: (row) => row.orderReference,
        sortable: true,
      },
      {
        name: "CLIENTE",
        selector: (row) => removeAccents(row.user?.name),
        sortable: true,
        cell: (row) => <span>{row.user?.name}</span>,
      },
      {
        name: "FECHA",
        cell: row => <span>{row.createdAt.substring(0, 10)}</span>,
        selector: (row) => row.createdAt.substring(0, 10),
        sortable: true,
        right: true
      },
      {
        name: "TOTAL",
        selector: (row) => row.totalPrice,
        sortable: true,
        right: true,
        cell: (row) => <span>{row.totalPrice.toFixed(2)} €</span>,
      },
      {
        name: "PRODUCTOS",
        selector: (row) => row.orderItems.map((i, k) => (<div className='mb-1' key={k}>{removeAccents(i.name)}</div>)),
        sortable: true,
        right: true,
        cell: (row) => <div>{row.orderItems.map((i, k) => (<div className='mb-1' key={k}>{i.name}</div>))}</div>,
      },
      {
        name: "",
        cell: row => 
          <div className="row">
              <div className="col-12">
                <i className="bi bi-x-circle-fill px-2" onClick={() => {setOrderSelected(row)}} style={{fontSize: '1.4rem', cursor: 'pointer'}} data-bs-toggle="modal" data-bs-target="#staticBackdrop"></i>                
              </div>
          </div>,
        center: true,
      }
    ];

    const searchOrder = (e) => {
      e.preventDefault()
      dispatch(findOrderByCustomFilter(idToSearch))
    }

    const changeItemQuantity = (value, index) => {
      let orderSelectedCopy = Object.assign({}, orderSelected)
      let newPrice = orderSelectedCopy.totalPriceEdited || orderSelectedCopy.totalPrice
      orderSelectedCopy.orderItems = orderSelectedCopy.orderItems.map((item, itemIndex) => {
        if (index === itemIndex) {
          newPrice -= (item.qty - value) * item.price
          return {...item, qtyEdited: value}
        } else {
          return item
        }
      })

      orderSelectedCopy.totalPriceEdited = newPrice

      setOrderSelected(orderSelectedCopy)
    }

    const changeDeletionType = (e) => {
      let orderSelectedCopy = Object.assign({}, orderSelected)
      delete orderSelectedCopy.totalPriceEdited

      orderSelectedCopy.orderItems = orderSelectedCopy.orderItems.map(item => {
        delete item.qtyEdited
        return item
      })

      setOrderSelected(orderSelectedCopy)

      setDeletionType(e.target.value !== "" ? e.target.value : null)
    }

    const handleDeleteOrder = (e) => {
      e.preventDefault()
      let orderSelectedCopy = Object.assign({}, orderSelected)

      if (orderSelectedCopy.totalPriceEdited) {
        delete orderSelectedCopy.totalPriceEdited
      }

      orderSelectedCopy.orderItems = orderSelectedCopy.orderItems.map(item => {
        if (item.qtyEdited) {
          item.qty = item.qtyEdited
          delete item.qtyEdited
        }
        return item
      })
      dispatch(hideOrder({order: orderSelectedCopy, type: deletionType}))
    }

    useEffect(() => {
      if (result) {
        if (result === "success") {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Pedido eliminado correctamente',
            showConfirmButton: false,
            timer: 1500
          })
          closeModalBtn.current.click()
          dispatch(findOrderByCustomFilter(idToSearch))
          setOrderSelected(null)
          setDeletionType('')
          setIdToSearch("")
        }
        if (result === "error") {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ha ocurrido un error',
            text: "Compruebe los datos e inténtelo de nuevo más tarde",
            showConfirmButton: false,
            timer: 1500
          })
        }

        dispatch({type: ORDER_HIDE_RESET})
      }
    }, [result, dispatch, idToSearch])

    return (
    <>
    <ToolBar titulo={"Eliminar pedidos"} hitos={[
        {titulo: "Inicio", enlace: "/" },
        {titulo: "Eliminar pedidos", enlace: "#" }]}/>
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
      <div className="content flex-row-fluid" id="kt_content">        
        <div className="card card-flush">
            <div className="card-body">
              <form onSubmit={searchOrder}>
                <div className='row mb-6 align-items-end'>
                  <div className='col-9'>
                    <label className='text-muted'>Id de Stripe, Id de pedido o Referencia de pedido</label>
                    <input className='form-control' type="text" name='id' value={idToSearch} onChange={(e) => {setIdToSearch(e.target.value)}}/>
                  </div>
                  <div className='col-3'>
                    <button type='submit' className='btn btn-primary'>Buscar</button>
                  </div>
                </div>
              </form>
              { loadingList ?  
                  <LoadingBox variant={"primary"}/> : 
              error? 
                  <AlertMessage variant={"danger"} message={error}/> : 
                ( 
                    <CustomDataTable data={orders} columns={columns}/>
                )
              }
              <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Pedido {orderSelected?.orderReference} ({(orderSelected?.totalPriceEdited !== null && orderSelected?.totalPriceEdited !== undefined) ? orderSelected?.totalPriceEdited?.toFixed(2) : (orderSelected?.totalPrice?.toFixed(2) || '')}€)</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        <form autoComplete='off' className="form w-100 row" id="kt_sign_in_form" onSubmit={handleDeleteOrder}>
                            <div className="fv-row">
                                <label className="required form-label">Tipo de eliminación</label>
                                <select onChange={changeDeletionType} value={deletionType || ""} className='form-control'>
                                  <option value=""></option>
                                  <option value="complete">Completa</option>
                                  <option value="partial">Parcial</option>
                                </select>
                            </div>
                            {
                              deletionType === "partial" &&
                              <div className='row mt-6'>
                                {
                                  orderSelected?.orderItems?.map((item, index) => (
                                    <div className='col-4' key={index}>
                                      <div className='card'>
                                        {
                                          item.image && item.image !== "" &&
                                            <img className='card-img-top' src={item.image} alt={`${item.name} image`}/>
                                        }
                                        <div className='card-body'>
                                          <h5 className='card-title'>{item.name} ({item.price.toFixed(2)}€/ud.)</h5>
                                          <div className='card-text'>
                                            <div>{item.student?.name}</div>
                                            <div>
                                              <label>Cantidad</label>
                                              <select className='form-select' onChange={(e) => changeItemQuantity(e.target.value, index)} value={item.qtyEdited || item.qty || ''}>
                                                {
                                                  Array.from(Array(parseInt(item.qty) + 1).fill(true).keys()).map((qty, qIndex) => (
                                                    <option value={qty} key={`${index}-${qIndex}`}>{qty}</option>
                                                  ))
                                                }
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                            }
                            <div className="text-end mt-10">
                                <button type="submit" id="kt_sign_in_submit" className="btn btn-primary me-1" disabled={loading || !deletionType ? 'disabled' : ''}>
                                {
                                  loading ? (
                                        <span className="indicator-progress">Espere por favor...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    ) :
                                    (<span className="indicator-label">Aceptar</span>)
                                }
                                </button>
                                <button ref={closeModalBtn} type="button" id="closeModal" className="btn btn-secondary ms-1" data-bs-dismiss="modal">Cerrar</button>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    </>
    )
}
