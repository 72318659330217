import Axios from 'axios'
import Swal from 'sweetalert2';
import { CART_ADD_ITEM, CART_EMPTY, CART_REMOVE_ITEM, CART_SAVE_PAYMENT_METHOD, CART_SAVE_SHIPPING_ADDRESS, CART_UPDATE_PRODUCT } from '../constants/cartConstants';

export const addToCart = (productId, qty, student, attribute, dates) => async(dispatch, getState) => {
    if (!localStorage.getItem('orderId')) {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/products/${productId}`);
        dispatch({
            type: CART_ADD_ITEM,
            payload: {
                name: data.name,
                image: data.image,
                price: attribute ? attribute.priceImpact + data.price : data.price,
                countInStock: data.countInStock,
                product: data._id,
                qty,
                iva: data.iva ? data.iva : 0,
                student: student,
                attributes: attribute ? [attribute] : [],
                dates: dates ? dates : [],
                alternativePayment: data.alternativePayment,
                billable: data.billable || false
            }
        })
        localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
    } else {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'No se puede añadir el producto al carrito',
            text: 'Tiene un pedido pendiente de procesar. Termine el pedido o elimine los artículos del carrito para poder continuar',
            showConfirmButton: false,
            timer: 1500
        })
    }
}

export const updateQty = (productId, qty) => (dispatch, getState) => {
    dispatch({
        type: CART_UPDATE_PRODUCT,
        payload: {
            product: productId,
            qty: qty
        }
    })
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}


export const removeFromCart = (productPosition) => (dispatch, getState) => {
    dispatch({ type: CART_REMOVE_ITEM, payload: productPosition })
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
    if (getState().cart.cartItems < 1 && localStorage.getItem('orderId') !== null) {
        dispatch({ type: CART_EMPTY });
        localStorage.removeItem('orderId');
    }
}

export const saveShippingAddress = (data) => (dispatch) => {
    dispatch({ type: CART_SAVE_SHIPPING_ADDRESS, payload: data });
    localStorage.setItem('shippingAddress', JSON.stringify(data));
}

export const savePaymentMethod = (data) => (dispatch) => {
    dispatch({ type: CART_SAVE_PAYMENT_METHOD, payload: data });
}