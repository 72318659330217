import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { payExtracurricularActivityInscription } from '../actions/extracurricularActivityInscriptionActions';
import { listPaymentMethods } from '../actions/paymentMethodActions';

export const ExtracurricularActivityInscriptionButton = ({extracurricularActivityInscription, user}) => {
	const dispatch = useDispatch()
	const stripe = useStripe();
    const elements = useElements();

	const [loading, setLoading] = useState(false)

	const {userInfo} = useSelector((state) => state.userSignin);

	const [selectedCard, setSelectedCard] = useState("null")

	const {paymentMethods} = useSelector(state => state.paymentmethodList)

	const createSubscription = async (extracurricularActivityInscription) => {
	
		const {data} = await axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/extracurricularactivityinscription/subscribe', {
			extracurriculumActivityInscription: extracurricularActivityInscription?._id
		}, {
			headers: {
				Authorization: `Bearer ${userInfo?.token}`,
			}
		})
		return data
	}

	const [paymentError, setPaymentError] = useState(null)

	const subscribe = async() => {
		setLoading(true)
		const {subscription, clientSecret} = await createSubscription(extracurricularActivityInscription)

		if (clientSecret) {
			if (selectedCard === 'null') {
				const cardElement = elements.getElement(CardElement)

				const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
					payment_method: {
						card: cardElement,
						billing_details: {
							name: user
						}
					}
				})

				if (paymentIntent?.status === 'succeeded') {
					dispatch(payExtracurricularActivityInscription(extracurricularActivityInscription?._id))
				} else {
					setLoading(false)
				}
				if (error) {
					setPaymentError(error.message)
					setLoading(false)
				} else {
					setPaymentError(null)
				}
			} else {
				const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
					payment_method: extracurricularActivityInscription?.alternativePayment ? paymentMethods.find(p => p._id === selectedCard).alternativeStripeId : paymentMethods.find(p => p._id === selectedCard).stripeId
				})

				if (paymentIntent?.status === 'succeeded') {
					dispatch(payExtracurricularActivityInscription(extracurricularActivityInscription?._id))
				} else {
					setLoading(false)
				}
				if (error) {
					setPaymentError(error.message)
					setLoading(false)
				} else {
					setPaymentError(null)
				}
			}
				
		} else {
			dispatch(payExtracurricularActivityInscription(extracurricularActivityInscription?._id))
			setLoading(false)
		}
	}
	useEffect(() => {
		dispatch(listPaymentMethods())
	}, [dispatch])

	useEffect(() => {
		if (paymentMethods?.length > 0) {
			setSelectedCard(paymentMethods[0]?._id)	
		}
	}, [paymentMethods])

	return (
		<div className='d-grid gap-2 my-7'>
		{
			paymentMethods?.length > 0 &&
			<div className='my-5'>
				<select className='form-select' name="card" value={selectedCard} onChange={(e) => setSelectedCard(e.target.value)}>
					{
						paymentMethods.map((paymentMethod, ind) => (
							<option key={ind} value={paymentMethod._id}>{paymentMethod.name || paymentMethod.last4}</option>
						))
					}
					<option value="null">Otra</option>
				</select>
			</div>
		}
		{
			selectedCard === 'null' &&
			<CardElement options={{ hidePostalCode: true }} className='mb-4'/>
		}
			<button disabled={loading} type='button' className='btn btn-primary' onClick={() => subscribe()}>
				{
					loading
					?
						<>Espere, por favor...</>
					:
						<>Pagar</>
				}
			</button>
			{
				paymentError &&
				<span className='text-danger'>{paymentError}</span>
			}
		</div>
	)
}