import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cancelSubscription, listSubscriptionMine, requestCancelSubscription } from '../actions/subscriptionActions';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar';
import { CustomDataTable } from '../components/CustomDataTable';
import { getNotificationsUser } from '../actions/notificationActions';
import { SUBSCRIPTION_CANCEL_RESET } from '../constants/subscriptionConstants';
import Swal from 'sweetalert2';
import removeAccents from '../utils/removeAccents';

export const SubscriptionHistoryScreen = (props) => {
    const { subscriptions, error: errorList, loading: loadingList } = useSelector(state => state.subscriptionMine);

    const {error: errorCancel, success, loading: loadingCancel} = useSelector(state => state.subscriptionRequestCancel)
    const dispatch = useDispatch();

    const PERIODS = {"day": "Diario", "week": "Semanal", "month": "Mensual", "year": "Anual"}

    const columns = [
          {
          name: "REFERENCIA",
          selector: (row) => row.subscriptionReference,
          sortable: true,        
          cell: (row) => <span>{row.subscriptionReference}</span>,
          },
          {
          name: "ACTIVO DESDE",
          selector: (row) => row.isPaid,
          sortable: true,          
          cell: (row) => <span>{row.isPaid ? row.paidAt?.substring(0, 10): 'No'}</span>,
          },
          {
            name: "PRODUCTO",
            selector: (row) => removeAccents(`${row.subscriptionProduct?.name}, ${row.fee.name}`),
            sortable: true,
            cell: row => <span>{row.subscriptionProduct?.name}, {row.fee.name}</span>
          },
          {
            name: "RECURRENCIA",
            selector: row => removeAccents(`${row.fee.price.toFixed(2)}€, ${PERIODS[row.fee.period]}`),
            sortable: true,
            cell: row => <span>{`${row.fee.price.toFixed(2)}€, ${PERIODS[row.fee.period]}`}</span>
          },
          {
            name: "ALUMNO",
            selector: row => removeAccents(row.student?.name),
            sortable: true,
            cell: row => <span>{row.student?.name}</span>
          },
          {
          name: "",
          cell: row => 
          !row.requestedToCancel &&
            <div className="row">
                <div className="col-12">
                  <i className="bi bi-journal-x px-2" style={{fontSize: '1.4rem', cursor: 'pointer'}} title='Cancelar suscripción' onClick={() => handleSubscriptionCancel(row._id)}/>
                </div>
            </div>,
          center: true,
        }
    ];

    const handleSubscriptionCancel = (id) => {
      Swal.fire({
        title: `¿Desea solicitar la cancelación de esta suscripción?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Si',
        denyButtonText: 'No', 
        cancelButtonText: "Cancelar",
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(requestCancelSubscription(id))
        }
      })
    }

    useEffect(() => {
      dispatch(listSubscriptionMine())
      dispatch(getNotificationsUser());
    }, [dispatch])

    useEffect(() => {
      if (success) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Suscripción cancelada correctamente',
          showConfirmButton: false,
          timer: 1500
        })
        dispatch({type: SUBSCRIPTION_CANCEL_RESET})
        dispatch(listSubscriptionMine())
        dispatch(getNotificationsUser());
      }
    }, [dispatch, success])
    

    return ( 
    <>
    <ToolBar titulo={"Historial de suscripciones"} hitos={[
        {titulo: "Inicio", enlace: "/" },
        {titulo: "Suscripciones", enlace: "#" }]}/>
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
      <div className="content flex-row-fluid" id="kt_content">
        <div className="card card-flush">
            <div className="card-body">
            { loadingList || loadingCancel ?  
                <LoadingBox variant={"primary"}/> : 
              errorList || errorCancel? 
                <AlertMessage variant={"danger"} message={errorList || errorCancel}/> : 
                (      
                <CustomDataTable data={subscriptions} columns={columns}/>
                )
            }
            </div>    
        </div>
      </div>
    </div>
    </>        
    )
}