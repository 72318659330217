export const putNewLog = (typeerror, error, user, route) => {
    let logstring = ''

    if(localStorage.getItem('SDerrorlog') !== ''){
        logstring += '\n'
    }

    let day = new Date().getDay() < 10 ? '0'+new Date().getDay() : new Date().getDay()
    let month = new Date().getMonth() < 10 ? '0'+new Date().getMonth() : new Date().getMonth()
    let year = new Date().getFullYear().toString().substr(2, 2)
    let hour = new Date().getHours() < 10 ? '0'+new Date().getHours() : new Date().getHours()
    let minute = new Date().getMinutes() < 10 ? '0'+new Date().getMinutes() : new Date().getMinutes()
    let second = new Date().getSeconds() < 10 ? '0'+new Date().getSeconds() : new Date().getSeconds()

    logstring += '['+day+'/'+month+'/'+year+' '+hour+':'+minute+':'+second+'] - User: '+user+' | '

    if(typeerror !== 'Err'){
        logstring += typeerror+': '+error+' | Route: '+route
    }else{
        logstring += error+' | Route: '+route
    }

    localStorage.setItem('SDerrorlog', localStorage.getItem('SDerrorlog')+logstring)
}