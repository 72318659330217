import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Row, Col } from 'react-bootstrap'
import { CustomDataTable } from '../components/CustomDataTable';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar'
import Swal from 'sweetalert2';
import { getNotificationsUser } from '../actions/notificationActions';
import { favouriteMessage, getMessagesUser, readMessage, removeMessage, responseMessage } from '../actions/messageActions';
import { Editor } from "@tinymce/tinymce-react";
import removeAccents from '../utils/removeAccents';

export const MessageScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const messages = useSelector((state) => state.messageList)

    const { userInfo } = useSelector(state => state.userSignin)
    const [inbox, setInbox] = useState(true)
    const [messagesIn, setMessagesIn] = useState([])
    const [messagesOut, setMessagesOut] = useState([])

    const [sendingMessage, setsendingMessage] = useState(false)
    const [message, setMessage] = useState('')
    const message1 = useRef(null);
    const [lastestMessages, setLastestMessages] = useState('')
    const [messagesResume, setMessagesResume] = useState(null)
    const [messagesShow, setMessagesShow] = useState(null)


    const pushMessageHandler = async(e) => {
        e.preventDefault();
        if (message !== '') {
            setsendingMessage(true)
            if(messagesShow.sender._id === userInfo._id){
                dispatch(responseMessage({
                    idmessage: messagesShow.parent,
                    sender: messagesShow.receiver._id,
                    receiver: messagesShow.sender._id,
                    message: message
                }))
            }else{
                dispatch(responseMessage({
                    idmessage: messagesShow.parent,
                    sender: messagesShow.sender._id,
                    receiver: messagesShow.receiver._id,
                    message: message
                }))
            }
            setsendingMessage(false)
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Mensaje enviado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
        }
        setMessage('');
    }

    const setMessagesFavourite = async(message) => {
        dispatch(favouriteMessage(message))
    }

    const deleteMessage = async(message) => {
        
        Swal.fire({
            title: '¿Está seguro de eliminar este mensaje?',
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeMessage(message))
                Swal.fire('Eliminado correctamente', '', 'success')
            }
        })
    }

    useEffect(() => {
        dispatch(getMessagesUser(userInfo._id))
        dispatch(getNotificationsUser())
    }, [dispatch, navigate])

    useEffect(() => {
        if(messages){
            let arrayMessagesIn = []
            let arrayMessagesOut = []

            messages.messagesIn?.map((messages) => {
                arrayMessagesIn.push(messages)
            })
            setMessagesIn(arrayMessagesIn)

            messages.messagesOut?.map((messages) => {
                arrayMessagesOut.push(messages)
            })
            setMessagesOut(arrayMessagesOut)
        }
    }, [messages])

    useEffect(() => {
        if(messagesResume){
            let messagesStructure = '</br>'
            if(messagesResume.type === 'inbox'){
                let messageToShow = messagesIn.find(messageIn => messageIn._id === messagesResume._id)
                if(messageToShow?._id !== messagesShow?._id){
                    setMessagesShow(messageToShow)
                    dispatch(readMessage(messagesResume._id))

                    let timeToSave = 0

                    if(messageToShow?.messages){
                        for (let messageNow of messageToShow?.messages) {
                            if(messageNow._id === messagesResume._id){
                                timeToSave = 1
                            }
                            if(timeToSave === 1 && (messageNow.sender._id !== userInfo._id || messageNow.sender._id === userInfo._id && messageNow.sender_delete === false) && (messageNow.receiver._id !== userInfo._id || messageNow.receiver._id === userInfo._id && messageNow.receiver_delete === false)){
                                messagesStructure += '<hr style="border-style: dashed"/>'+
                                messageNow.message+'</br>'+
                                '<p>'+messageNow.sender.name+' - '+new Date(messageNow.date)?.toTimeString().substring(0, 5)+'    '+messageNow.date?.substr(8,2)+'/'+messageNow.date?.substr(5,2)+'/'+messageNow.date?.substr(0,4)+'</p>'
                            }
                        }
                    }
                    setLastestMessages(messagesStructure)
                }else{
                    setMessagesShow(null)
                    setLastestMessages('')
                }
            }else{
                let messageToShow = messagesOut.find(messageOut => messageOut._id === messagesResume._id)
                if(messageToShow !== messagesShow){
                    setMessagesShow(messageToShow)

                    let timeToSave = 0

                    if(messageToShow?.messages){
                        for (let messageNow of messageToShow?.messages) {
                            if(messageNow._id === messagesResume._id){
                                timeToSave = 1
                            }
                            if(timeToSave === 1 && (messageNow.sender._id !== userInfo._id || messageNow.sender._id === userInfo._id && messageNow.sender_delete === false) && (messageNow.receiver._id !== userInfo._id || messageNow.receiver._id === userInfo._id && messageNow.receiver_delete === false)){
                                messagesStructure += '<hr style="border-style: dashed"/>'+
                                messageNow.message+'</br>'+
                                '<p>'+messageNow.sender.name+' - '+new Date(messageNow.date)?.toTimeString().substring(0, 5)+'    '+messageNow.date?.substr(8,2)+'/'+messageNow.date?.substr(5,2)+'/'+messageNow.date?.substr(0,4)+'</p>'
                            }
                        }
                    }
                    setLastestMessages(messagesStructure)
                }else{
                    setMessagesShow(null)
                    setLastestMessages('')
                }
            }
        }
    }, [messagesResume])

    useEffect(() => {
        setMessagesShow(null)
    }, [inbox])

    const columnsIn = [
        {
            name: 'Favorito',
            cell: row =>
                <div className="d-flex align-items-center" onClick={() => setMessagesFavourite(row._id)}>
                    {(row.receiver._id === userInfo._id && row.receiver_fav)
                        ?
                            <><i className="fas fa-star text-warning"/></>
                        :
                            <><i className="far fa-star"/></>
                    }
                </div>,
            selector: (row) => row.receiver_fav,
            sortable: true,
        },
        {
            name: "Asunto",
            cell: row => 
                <div className="d-flex align-items-center" onClick={() => setMessagesResume({type: 'inbox', _id: row._id})}>
                    {inbox === true && row.viewed === false
                        ? <h2>{row.subject}</h2>
                        : row.subject
                    }
                </div>,
            selector: (row) => removeAccents(row.subject),
            sortable: true,
        },
        {
            name: 'Remitente',
            cell: row =>
                <div className="d-flex align-items-center" onClick={() => setMessagesResume({type: 'inbox', _id: row._id})}>
                    {row.sender.image
                        ?
                            <img className="h-40px w-40px rounded mx-3" src={row.sender.image}></img>
                        :
                            <img className="h-40px w-40px rounded mx-3" src="/assets/media/avatars/blank.png" alt="Avatar" />
                    }
                    {row.sender.name}
                </div>,
            selector: (row) => removeAccents(row.sender.name),
            sortable: true,
        },
        {
            name: 'Fecha',
            cell: row =>
                <div className="d-flex align-items-center" onClick={() => setMessagesResume({type: 'inbox', _id: row._id})}>
                    {new Date(row.date)?.toTimeString().substring(0, 5)+' ('+row.date?.substr(8,2)+'/'+row.date?.substr(5,2)+'/'+row.date?.substr(0,4)+')'}
                </div>,
            selector: (row) => row.date,
            sortable: true,
        },
        {
            name: 'Eliminar',
            cell: row =>
                <i className="fas fa-trash-alt" onClick={() => deleteMessage(row._id)}></i>,
            selector: (row) => row.date,
            sortable: false,
        }
    ]

    const columnsOut = [
        {
            name: 'Favorito',
            cell: row =>
                <div className="d-flex align-items-center" onClick={() => setMessagesFavourite(row._id)}>
                    {(row.sender._id === userInfo._id && row.sender_fav)
                        ?
                            <><i className="fas fa-star text-warning"/></>
                        :
                            <><i className="far fa-star"/></>
                    }
                </div>,
            selector: (row) => removeAccents(row.name),
            sortable: true,
        },
        {
            name: "Asunto",
            cell: row => 
                <div className="d-flex align-items-center" onClick={() => setMessagesResume({type: 'outbox', _id: row._id})}>
                    {row.subject}
                </div>,
            selector: (row) => removeAccents(row.subject),
            sortable: true,
        },
        {
            name: 'Destinatario',
            cell: row =>
                <div className="d-flex align-items-center" onClick={() => setMessagesResume({type: 'outbox', _id: row._id})}>
                    {row.receiver.image
                        ?
                            <img className="h-40px w-40px rounded mx-3" src={row.receiver.image}></img>
                        :
                            <img className="h-40px w-40px rounded mx-3" src="/assets/media/avatars/blank.png" alt="Avatar" />
                    }
                    {row.receiver.name}
                </div>,
            selector: (row) => removeAccents(row.receiver.name),
            sortable: true,
        },
        {
            name: 'Fecha',
            cell: row =>
                <div className="d-flex align-items-center" onClick={() => setMessagesResume({type: 'outbox', _id: row._id})}>
                    {new Date(row.date)?.toTimeString().substring(0, 5)+' ('+row.date?.substr(8,2)+'/'+row.date?.substr(5,2)+'/'+row.date?.substr(0,4)+')'}
                </div>,
            selector: (row) => removeAccents(row.name),
            sortable: true,
        },
        {
            name: 'Eliminar',
            cell: row =>
                <i className="fas fa-trash-alt" onClick={() => deleteMessage(row._id)}></i>,
            selector: (row) => row.date,
            sortable: false,
        }
    ]

    return (
        <>
            <ToolBar titulo={"Mensajería"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Mensajería", enlace: "#" },
            ]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <form autoComplete='off' className="form w-100" noValidate="novalidate" id="kt_sign_in_form" onSubmit={pushMessageHandler}>
                    <div className="content flex-row-fluid" id="kt_content">
                        <div className="card card-flush">
                            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                <div className="card-toolbar flex-row-fluid gap-5">
                                    <button type="button" className="btn btn-primary" onClick={() => setInbox(true)}>
                                        Bandeja de entrada
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={() => setInbox(false)}>
                                        Bandeja de salida
                                    </button>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                {inbox
                                    ?
                                        <>
                                            Mensajes en bandeja de entrada
                                            <CustomDataTable data={messagesIn} columns={columnsIn} onRowClicked={(row) => setMessagesResume({type: 'inbox', _id: row._id})}/>
                                        </>
                                    :
                                        <>
                                            Mensajes en bandeja de salida
                                            <CustomDataTable data={messagesOut} columns={columnsOut} onRowClicked={(row) => setMessagesResume({type: 'outbox', _id: row._id})}/>
                                        </>
                                }
                                {messagesShow
                                    ?
                                        <>
                                        <hr/>
                                            <Row>
                                                <Col className="text-start">
                                                    {messagesShow.sender._id === userInfo._id
                                                        ?
                                                        messagesShow.receiver.image
                                                            ?
                                                                <img className="h-40px w-40px rounded mx-3" src={messagesShow.receiver.image}></img>
                                                            :
                                                                <img className="h-40px w-40px rounded mx-3" src="/assets/media/avatars/blank.png" alt="Avatar" />
                                                        :
                                                        messagesShow.sender.image
                                                            ?
                                                                <img className="h-40px w-40px rounded mx-3" src={messagesShow.sender.image}></img>
                                                            :
                                                                <img className="h-40px w-40px rounded mx-3" src="/assets/media/avatars/blank.png" alt="Avatar" />
                                                    }
                                                    <b>{messagesShow.sender.name}</b>
                                                </Col>
                                                <Col className="text-center"><h3>Asunto:</h3> {messagesShow.subject}</Col>
                                                <Col className="text-end">
                                                    <h3>Hora - Fecha:</h3> {new Date(messagesShow.date)?.toTimeString().substring(0, 5)+'  -  '+messagesShow.date?.substr(8,2)+'/'+messagesShow.date?.substr(5,2)+'/'+messagesShow.date?.substr(0,4)}</Col>   
                                            </Row>
                                            {inbox
                                                ?
                                                    <>
                                                        <hr/>
                                                        <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">     
                                                            {sendingMessage ? (<LoadingBox variant={"primary"}/>)
                                                            :
                                                            (<span className="indicator-label">Enviar respuesta</span>)}
                                                        </button>
                                                        <Editor
                                                            apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
                                                            onInit={(evt, editor) => message1.current = editor}
                                                            onChange={() => setMessage(message1?.current?.getContent())}
                                                            init={{
                                                            height: 300,
                                                            menubar: false,
                                                            plugins: [
                                                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                            ],
                                                            toolbar: 'undo redo | blocks | ' +
                                                                'bold italic forecolor | alignleft aligncenter ' +
                                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                'removeformat | help',
                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                        />
                                                    </>
                                                :
                                                    <></>
                                            }
                                            <Row>
                                                <Col className="text-start p-4">
                                                    <p dangerouslySetInnerHTML={{__html: lastestMessages}}></p>
                                                </Col>
                                            </Row>
                                        </>
                                    :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
