import Axios from "axios"
import { CART_EMPTY } from "../constants/cartConstants";
import { ORDERS_IMPORT_FAIL, ORDERS_IMPORT_REQUEST, ORDERS_IMPORT_SUCCESS, ORDER_CHANGE_TO_PAID_FAIL, ORDER_CHANGE_TO_PAID_REQUEST, ORDER_CHANGE_TO_PAID_SUCCESS, ORDER_CREATE_FAIL, ORDER_CREATE_REQUEST, ORDER_CREATE_SUCCESS, ORDER_DELETE_FAIL, ORDER_DELETE_REQUEST, ORDER_DELETE_SUCCESS, ORDER_DELIVER_FAIL, ORDER_DELIVER_REQUEST, ORDER_DELIVER_SUCCESS, ORDER_DETAILS_FAIL, ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, ORDER_EDIT_FAIL, ORDER_EDIT_REQUEST, ORDER_EDIT_SUCCESS, ORDER_LIST_FAIL, ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS, ORDER_LIST_NOT_PAID_FAIL, ORDER_LIST_NOT_PAID_REQUEST, ORDER_LIST_NOT_PAID_SUCCESS, ORDER_MINE_LIST_FAIL, ORDER_MINE_LIST_REQUEST, ORDER_MINE_LIST_SUCCESS, ORDER_PAID_BY_DATE_FAIL, ORDER_PAID_BY_DATE_REQUEST, ORDER_PAID_BY_DATE_SUCCESS, ORDER_PAY_FAIL, ORDER_PAY_REQUEST, ORDER_PAY_SUCCESS, ORDER_SALES_INFO_REQUEST, ORDER_SALES_INFO_SUCCESS, ORDER_SALES_PRODUCT_REQUEST, ORDER_SALES_PRODUCT_SUCCESS, ORDER_STOCK_UPDATED, ORDER_FIND_BY_CUSTOM_FILTER_ERROR, ORDER_FIND_BY_CUSTOM_FILTER_SUCCESS, ORDER_FIND_BY_CUSTOM_FILTER_REQUEST, ORDER_HIDE_REQUEST, ORDER_HIDE_ERROR, ORDER_HIDE_SUCCESS } from "../constants/orderConstants"
import { putNewLog } from "../utils/logs";
import { sendLog } from "./logActions";
/**
 * 
 * @param {JSON} order 
 * @returns 
 */
export const createOrder = (order) => async(dispatch, getState) => {
    dispatch({ type: ORDER_CREATE_REQUEST, payload: order });
    try {
        const { userSignin: { userInfo } } = getState();
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/orders', order, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        });
        dispatch({ type: ORDER_CREATE_SUCCESS, payload: data.order });
        localStorage.setItem('orderId', data.order._id)
    } catch (error) {
        dispatch({
            type: ORDER_CREATE_FAIL,
            payload: error.response && error.response.data.message?
                error.response.data.message: error.message,
        });
    }
};

/**
 * 
 * @param {JSON} item 
 * @param {string} id 
 * @returns 
 */
export const updateStock = (item, id) => async(dispatch, getState) => {
    const { userSignin: { userInfo } } = getState();
    const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/products/${id}/stock`, item, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({type: ORDER_STOCK_UPDATED, payload: data})
}

/**
 * 
 * @param {string} orderId
 * @returns 
 */
export const detailsOrder = (orderId) => async(dispatch, getState) => {
    dispatch({ type: ORDER_DETAILS_REQUEST, payload: orderId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/orders/${orderId}`);
        dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: ORDER_DETAILS_FAIL, payload: message });
    }
};

/**
 * 
 * @param {JSON} order 
 * @param {JSON} paymentResult 
 * @returns 
 */
export const payOrder = (order, paymentResult) => async(
    dispatch,
    getState
) => {
    dispatch({ type: ORDER_PAY_REQUEST, payload: { order, paymentResult } });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data, status } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/orders/${order._id}/pay`, paymentResult, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_PAY_SUCCESS, payload: data });
        dispatch({ type: CART_EMPTY });
        localStorage.removeItem('cartItems');
        localStorage.removeItem('orderId');
        putNewLog('Suc', status, userInfo._id, `/api/orders/${order._id}/pay`)
        dispatch(sendLog(localStorage.getItem('SDerrorlog')))
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: ORDER_PAY_FAIL, payload: message });
    }
};

export const listOrderMine = () => async(dispatch, getState) => {
    dispatch({ type: ORDER_MINE_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/orders/mine', {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_MINE_LIST_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: ORDER_MINE_LIST_FAIL, payload: message })
    }
}

export const listOrders = () => async(dispatch, getState) => {
    dispatch({ type: ORDER_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/orders', {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_LIST_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: ORDER_LIST_FAIL, payload: message })
    }
}

/**
 * 
 * @param {string} orderId 
 * @returns 
 */
export const deleteOrder = (orderId) => async(dispatch, getState) => {
    dispatch({ type: ORDER_DELETE_REQUEST, payload: orderId })
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.delete(process.env.REACT_APP_DB_ADDRESS + `/api/orders/${orderId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_DELETE_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: ORDER_DELETE_FAIL, payload: message })
    }
}

/**
 * 
 * @param {string} orderId 
 * @returns 
 */
export const deliverOrder = (orderId) => async(dispatch, getState) => {
    dispatch({ type: ORDER_DELIVER_REQUEST, payload: orderId });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/orders/${orderId}/deliver`, {}, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_DELIVER_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: ORDER_DELIVER_FAIL, payload: message });
    }
};

/**
 * 
 * @param {string} productId 
 * @returns 
 */
export const getSalesInfo = (productId) => async(dispatch, getState) => {
    dispatch({ type: ORDER_SALES_INFO_REQUEST })
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/orders/product/${productId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_SALES_INFO_SUCCESS, payload: data })
    } catch (error) {}
}

/**
 * 
 * @param {string} productId 
 * @returns 
 */
export const getSalesProduct = (productId) => async(dispatch, getState) => {
    dispatch({ type: ORDER_SALES_PRODUCT_REQUEST })
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/orders/order/product/${productId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_SALES_PRODUCT_SUCCESS, payload: data })
    } catch (error) {}
}


/**
 * 
 * @param {string} orderId 
 * @param {JSON} order 
 * @returns 
 */
 export const editOrder = (orderId, order) => async(dispatch, getState) => {
    if (orderId) {
        dispatch({ type: ORDER_EDIT_REQUEST, payload: order });
        try {
            const { userSignin: { userInfo } } = getState();
            const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + '/api/orders/' + orderId, order, {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                },
            });
            dispatch({ type: ORDER_EDIT_SUCCESS, payload: data.order });
            localStorage.setItem('orderId', data.order._id)
        } catch (error) {
            dispatch({
                type: ORDER_EDIT_FAIL,
                payload: error.response && error.response.data.message?
                    error.response.data.message: error.message,
            });
        }
    }
    
};

/**
 * 
 * @param {string} orderId 
 * @param {string} paymentMethod 
 * @returns 
 */
export const updatePaymentMethod = (orderId, paymentMethod) => async(dispatch, getState) => {
    const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/orders/${orderId}/paymentmethod`, {paymentMethod});
    dispatch({type: ORDER_STOCK_UPDATED, payload: data})
}

/**
 * 
 * @param {Array} orders 
 * @returns 
 */
export const importOrders = (orders) => async(dispatch, getState) => {
    dispatch({ type: ORDERS_IMPORT_REQUEST, payload: orders });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + `/api/orders/import`, {orders: orders}, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });        
        dispatch({ type: ORDERS_IMPORT_SUCCESS, payload: data });

    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: ORDERS_IMPORT_FAIL, payload: message })
    }
}

/**
 * 
 * @param {JSON} params 
 * @returns 
 */
export const findOrdersPaidByDate = (params) => async (dispatch, getState) => {
    dispatch({ type: ORDER_PAID_BY_DATE_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + `/api/orders/paidbydate`, params, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: ORDER_PAID_BY_DATE_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: ORDER_PAID_BY_DATE_FAIL, payload: message })
    }
}

/**
 * 
 * @param {string} orderId 
 * @param {date} paidAt 
 * @returns 
 */
export const changeToPaid = (orderId, paidAt) => async(dispatch, getState) => {
    dispatch({type: ORDER_CHANGE_TO_PAID_REQUEST})
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/orders/${orderId}/changetopaid`, {paidAt: paidAt}, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: ORDER_CHANGE_TO_PAID_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: ORDER_CHANGE_TO_PAID_FAIL, payload: message })
    }
}

export const addPaymentIntent = (orderId, paymentIntent) => async (dispatch, getState) => {
    const { userSignin: {userInfo} } = getState()
    try {
        const {data} = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/orders/${orderId}/addpaymentintent`, {paymentId: paymentIntent}, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        })
    } catch (error) {
        
    }
}

export const listOrdersNotPaid = () => async(dispatch, getState) => {
    dispatch({ type: ORDER_LIST_NOT_PAID_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/orders/notpaid', {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_LIST_NOT_PAID_SUCCESS, payload: data })
    } catch (error) {
        const message =
            error.response && error.response.data.message?
            error.response.data.message:
            error.message;
        dispatch({ type: ORDER_LIST_NOT_PAID_FAIL, payload: message })
    }
}

export const findOrderByCustomFilter = (id) => async(dispatch, getState) => {
    dispatch({type: ORDER_FIND_BY_CUSTOM_FILTER_REQUEST})
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/orders/customFilter/' + id, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ORDER_FIND_BY_CUSTOM_FILTER_SUCCESS, payload: data })
    } catch (error) {
        let message = ''
        if (error.response && error.response.data?.message) {
            message = error.response.data.message
        } else {
            message = error.message
        }
        dispatch({
            type: ORDER_FIND_BY_CUSTOM_FILTER_ERROR,
            payload: message
        })
    }
}

export const hideOrder = (order) => async(dispatch, getState) => {
    dispatch({type: ORDER_HIDE_REQUEST})
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/orders/hide`, order, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({type: ORDER_HIDE_SUCCESS, payload: data})
    } catch (error) {
        let message = ''
        if (error.response && error.response.data?.message) {
            message = error.response.data.message
        } else {
            message = error.message
        }
        dispatch({
            type: ORDER_HIDE_ERROR,
            payload: message
        })
    }
}