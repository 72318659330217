import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { saveShippingAddress } from '../actions/cartActions'
import { ToolBar } from '../components/ToolBar'

export const ShippingAddressScreen = (props) => {
  const navigate = useNavigate();

  const userSignin = useSelector((state) => state.userSignin);
  const {userInfo} = userSignin;
  const cart = useSelector((state) => state.cart);
  const {shippingAddress} = cart;

  if (!userInfo) {
    navigate('/');
  }
  const [fullName, setFullName] = useState(shippingAddress.fullName || '');
  const [address, setAddress] = useState(shippingAddress.address || '');
  const [city, setCity] = useState(shippingAddress.city || '');
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode || '');
  const [country, setCountry] = useState(shippingAddress.country || '');

  const dispatch = useDispatch();

  const submitHandler = (e) => {
      e.preventDefault();
      dispatch(saveShippingAddress({fullName, address, city, postalCode, country}))
      navigate('/payment');
  }

  return (
    <>
    <ToolBar titulo={"Checkout"} hitos={[
        {titulo: "Inicio", enlace: "/" },
        {titulo: "Carrito", enlace: "cart" },
        {titulo: "Envío", enlace: "#" }]}/>
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">						
            <div className="content flex-row-fluid" id="kt_content">
                <div className="row g-7">
                    <div className="col-xl-12">        
                        <div className="card card-flush h-lg-100" id="kt_contacts_main">            
                            <div className="card-header pt-7" id="kt_chat_contacts_header">                
                                <div className="card-title">                    
                                    <span className="svg-icon svg-icon-1 me-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black" />
                                            <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black" />
                                        </svg>
                                    </span>                    
                                    <h2>Dirección de envío</h2>
                                </div>                
                            </div>                        
                            <div className="card-body pt-5">                
                                <form autoComplete='off' id="kt_ecommerce_settings_general_form" className="form" onSubmit={submitHandler}>  
                                    <div className="row">
                                        <div className="col-12 col-md-6 mb-7">                        
                                            <label className="fs-6 fw-bold form-label mt-3">
                                                <span className="required">Nombre y apellidos</span>
                                                <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Nombre y apellidos" ></i>
                                            </label>                                                
                                            <input className="form-control form-control-solid" type="text" id="fullName" placeholder="Nombre y apellidos" value={fullName} onChange={(e) => setFullName(e.target.value)} required />                        
                                        </div>
                                        <div className="col-12 col-md-6 mb-7">                        
                                            <label className="fs-6 fw-bold form-label mt-3">
                                                <span className="required">Dirección</span>
                                                <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Dirección" ></i>
                                            </label>                                                
                                            <input className="form-control form-control-solid" type="text" id="address" placeholder="Dirección" value={address} onChange={(e) => setAddress(e.target.value)} required />                        
                                        </div>
                                        <div className="col-12 col-md-6 mb-7">                        
                                            <label className="fs-6 fw-bold form-label mt-3">
                                                <span className="required">Ciudad</span>
                                                <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Dirección" ></i>
                                            </label>                                                
                                            <input className="form-control form-control-solid" type="text" id="city" placeholder="Ciudad" value={city} onChange={(e) => setCity(e.target.value)} required />                        
                                        </div>
                                        <div className="col-12 col-md-6 mb-7">                        
                                            <label className="fs-6 fw-bold form-label mt-3">
                                                <span className="required">Código postal</span>
                                                <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Código postal" ></i>
                                            </label>                                                
                                            <input className="form-control form-control-solid" type="text" id="postalCode" placeholder="Código postal" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} required />                        
                                        </div>
                                        <div className="col-12 col-md-6 mb-7">                        
                                            <label className="fs-6 fw-bold form-label mt-3">
                                                <span className="required">País</span>
                                                <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="País" ></i>
                                            </label>                                                
                                            <input className="form-control form-control-solid" type="text" id="country" placeholder="País" value={country} onChange={(e) => setCountry(e.target.value)} required />                        
                                        </div>                                                                                
                                        <div className="separator mb-6"></div>                                        
                                        <div className="d-flex justify-content-end">                        
                                            <button type="submit" data-kt-contacts-type="submit" className="btn btn-primary">
                                                <span className="indicator-label">Continuar</span>
                                                <span className="indicator-progress">Espere por favor...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>                        
                                        </div>                    
                                    </div>
                                </form>                
                            </div>            
                        </div>        
                    </div>
                </div>
            </div>
        </div>
        </>
  )
}
