import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer"
import { Table, TableHeader, TableCell } from "@david.kucsai/react-pdf-table"

export default function Report({order, productsList, attributesList}){
	// Cantidad de artículos máximos por página en la factura
	const MAX_ARTICLES = 14
	
	let dateorder = order.paidAt 
		? 
			order.paidAt?.substr(8,2)+'/'+order.paidAt?.substr(5,2)+'/'+order.paidAt?.substr(0,4)
		:
			order.updatedAt?.substr(8,2)+'/'+order.updatedAt?.substr(5,2)+'/'+order.updatedAt?.substr(0,4)
	
	let products = order.orderItems
	let invoiceCode = 'F / '
	invoiceCode += 'F / ' + order.paidAt 
		? 
			order.paidAt?.substr(0,4)
		:
			order.updatedAt?.substr(0,4)

	invoiceCode += ' / ' + order.invoice

	Font.register({
		family: 'Poppins',
		fonts: [
			{
			  	src: process.env.PUBLIC_URL+`/assets/fonts/Poppins-Regular.ttf`
			},
			{
			  	src: process.env.PUBLIC_URL+`/assets/fonts/Poppins-SemiBold.ttf`,
				fontWeight: 'semibold'
			},
			{
				src: process.env.PUBLIC_URL+`/assets/fonts/Poppins-Bold.ttf`,
				fontWeight: 'bold'
			}
		]
	})

	let styles = StyleSheet.create({
		logo: {
			width: 119,
			height: 70
		},
		datosCole: {
			marginTop: 20,
			marginRight: 20,
			marginLeft: 20,
			marginBottom: 20,
		},
		bloqueDatosCole: {
			display: "flex",
			flexDirection: 'row'
		},
		datosColeNegro: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 9
		},
		datosColeNormal: {
			fontFamily: 'Poppins',
			fontSize: 9
		},
		datosUserNegro: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			marginRight: 100,
			marginLeft: 100
		},
		datosUserNormal: {
			flex: 1,
			fontFamily: 'Poppins',
			fontSize: 8,
			marginRight: 100,
			marginLeft: 100
		},
		bloqueNumeroFactura: {
			width: 150,
			marginTop: 20
		},
		numeroFacturaNegro: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			marginTop: 5
		},
		numeroFacturaNormal: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			marginBottom: 5
		},
		bloqueFecha: {
			width: 180,
			marginTop: 15
		},
		fechaNegro: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			marginTop: 5
		},
		fechaNormal: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			marginBottom: 5
		},
		bloqueProductos: {
			width: 555,
			height: 440,
			marginTop: 15,
			border: 1
		},
		productoNormal: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			marginBottom: 5
		},
		bloqueProductosHeader: {
			width: 555,
			borderBottom: 1,
			display: "flex",
			flexDirection: 'row'
		},
		productoHeaderTextCenter: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 50,
			borderRight: 1
		},
		productoHeaderTextCenterLast: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 50
		},
		productoHeaderTextCenterLarge: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 305,
			borderRight: 1
		},
		bloqueProductosBody: {
			width: 555,
			height: 35,
			display: "flex",
			flexDirection: 'row',
			borderBottom: 0.2
		},
		productoBodyTextCenter: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			width: 50,
			borderRight: 1,
			paddingTop: 10,
			paddingBottom: 5
		},
		productoBodyTextCenterLast: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			width: 50,
			paddingTop: 10,
			paddingBottom: 5
		},
		productoBodyTextCenterLarge: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'left',
			width: 305,
			borderRight: 1,
			paddingTop: 5,
			paddingLeft: 10,
			paddingBottom: 5
		},
		bloqueDescuentos: {
			width: 152,
			height: 26,
			marginTop: 10,
			borderRight: 1,
			borderLeft: 1,
			borderTop: 1
		},
		bloqueDescuentosHeader: {
			width: 150,
			display: "flex",
			flexDirection: 'row',
			borderBottom: 1
		},
		descuentoHeaderTextCenter: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 50,
			borderRight: 1
		},
		descuentoHeaderTextCenterLast: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 50
		},
		bloqueDescuentosBody: {
			width: 150,
			display: "flex",
			flexDirection: 'row',
			borderBottom: 1
		},
		descuentoBodyTextCenter: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 50,
			borderRight: 1
		},
		descuentoBodyTextCenterLast: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 50
		},
		bloqueTotales: {
			width: 402,
			height: 52,
			marginTop: 10,
			border: 1
		},
		bloqueTotalesHeader: {
			width: 400,
			display: "flex",
			flexDirection: 'row',
			borderBottom: 1
		},
		bloqueTotalesBody: {
			width: 400,
			display: "flex",
			flexDirection: 'row'
		},
		totalHeaderTextCenter: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 100,
			borderRight: 1
		},
		totalHeaderTextCenterLast: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 100
		},
		totalBodyTextCenter: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			width: 100,
			borderRight: 1
		},
		totalBodyTextCenterColIz: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'left',
			width: 100,
			paddingLeft: 20
		},
		totalBodyTextCenterColDe: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'right',
			width: 100,
			borderRight: 1,
			paddingRight: 20
		},
		totalBodyTextCenterLast: {
			fontFamily: 'Poppins',
			fontSize: 8,
			textAlign: 'center',
			width: 100
		},
		totalHeaderTextCenterColIz: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'left',
			width: 100,
			paddingLeft: 20
		},
		totalHeaderTextCenterColDe: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'right',
			width: 100,
			borderRight: 1,
			paddingRight: 20
		},
		bloqueTotalesFooter: {
			width: 400,
			display: "flex",
			flexDirection: 'row'
		},
		totalFooterTextCenter: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 100,
			borderRight: 1,
			borderTop: 1
		},
		totalFooterTextCenterColIz: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'left',
			width: 100,
			paddingLeft: 20,
			borderTop: 1
		},
		totalFooterTextCenterColDe: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'right',
			width: 100,
			borderRight: 1,
			paddingRight: 20,
			borderTop: 1
		},
		totalFooterTextCenterLast: {
			fontFamily: 'Poppins',
			fontWeight: 'semibold',
			fontSize: 8,
			textAlign: 'center',
			width: 100,
			borderTop: 1
		},
		bloqueTotalFacturaText: {
			marginTop: 8
		},
		bloqueTotalFacturaValue: {
			marginTop: 5,
			border: 1,
			width: 300,
			textAlign: 'right',
			paddingTop: 5,
			paddingBottom: 2,
			paddingLeft: 5,
			paddingRight: 5
		}
	})

	// cantidad de productos sin IVA
	let noIVA = 0
	// cantidad de productos con IVA super reducido
	let sRIVA = 0
	// cantidad de productos con IVA reducido
	let rIVA = 0
	// cantidad de productos con IVA
	let IVA = 0
	
	// Productos sin IVA
	products.filter(p => (p.iva === 0 && p.billable === true) || (productsList.find(prod => prod._id === p.product)?.iva === 0 && productsList.find(prod => prod._id === p.product)?.billable === true)).forEach(product => {
		noIVA += parseFloat(product.price * product.qty)
	});

	// Productos con IVA super reducido
	products.filter(p => (p.iva === 4 && p.billable === true) || (productsList.find(prod => prod._id === p.product)?.iva === 4 && productsList.find(prod => prod._id === p.product)?.billable === true)).forEach(product => {
		sRIVA += parseFloat(product.price * product.qty)
	});

	// Productos con IVA reducido
	products.filter(p => (p.iva === 10 && p.billable === true) || (productsList.find(prod => prod._id === p.product)?.iva === 10 && productsList.find(prod => prod._id === p.product)?.billable === true)).forEach(product => {
		rIVA += parseFloat(product.price * product.qty)
	});

	// Productos con IVA
	products.filter(p => (p.iva === 21 && p.billable === true) || (productsList.find(prod => prod._id === p.product)?.iva === 21 && productsList.find(prod => prod._id === p.product)?.billable === true)).forEach(product => {
		IVA += parseFloat(product.price * product.qty)
	});
	
	order.items = []

	order.orderItems.filter(p => p.billable === true || productsList.find(prod => prod._id === p.product)?.billable === true).forEach((product, i) => {
		// Creamos el array donde irá el item
	if (!order.items[Math.trunc(i / MAX_ARTICLES)]) {
		order.items[Math.trunc(i / MAX_ARTICLES)] = []
	}

	order.items[Math.trunc(i / MAX_ARTICLES)].push(product)
	});
	
	return (
		<Document>
		{
			Object.keys(order.items)?.map(key => (
				<Page key={key} size="A4" style={styles.datosCole}>
					<View style={styles.bloqueDatosCole}>
						<View>
							<Image src={process.env.PUBLIC_URL+`/logo192.png`} style={styles.logo}></Image>
						</View>
						<View>
							<Text style={styles.datosColeNegro}>VIRGEN DEL CARMEN</Text>
							<Text style={styles.datosColeNormal}>CIF: R1400096B</Text>
							<Text style={styles.datosColeNormal}>C/ ALONSO EL SABIO 14</Text>
							<Text style={styles.datosColeNormal}>14001 CÓRDOBA</Text>
							<Text style={styles.datosColeNormal}>TEL.: 957482125</Text>
						</View>
						<View>
							<Text style={styles.datosUserNegro}>Dirección fiscal:</Text> 
							<Text style={styles.datosUserNormal}>{order.user.userAddress?.address+'\n'+order.user.userAddress?.city+' ('+order.user.userAddress?.state+')'}</Text>
							<Text style={styles.datosUserNegro}>Cliente:</Text> 
							<Text style={styles.datosUserNormal}>{order.user?.name || ''}</Text>
						</View>
					</View>
					<View style={styles.bloqueNumeroFactura}>
						<Table>
							<TableHeader>
								<TableCell>
									<Text style={styles.numeroFacturaNegro}>FRA. SIMPLIFICADA</Text>
									<Text style={styles.numeroFacturaNormal}>{invoiceCode}</Text>
								</TableCell>
							</TableHeader>
						</Table>
					</View>
					<View style={styles.bloqueFecha}>
						<Table>
							<TableHeader>
								<TableCell>
									<Text style={styles.fechaNegro}>FECHA</Text>
									<Text style={styles.fechaNormal}>{dateorder}</Text>
								</TableCell>
								<TableCell>
									<Text style={styles.fechaNegro}>FORMA DE PAGO</Text>
									<Text style={styles.fechaNormal}>Tarjeta de credito</Text>
								</TableCell>
							</TableHeader>
						</Table>
					</View>
					<View style={styles.bloqueProductos}>
						<View style={styles.bloqueProductosHeader}>
							<Text style={styles.productoHeaderTextCenter}>CÓDIGO</Text>
							<Text style={styles.productoHeaderTextCenterLarge}>DESCRIPCIÓN</Text>
							<Text style={styles.productoHeaderTextCenter}>CANT.</Text>
							<Text style={styles.productoHeaderTextCenter}>PRECIO</Text>
							<Text style={styles.productoHeaderTextCenter}>% DTO</Text>
							<Text style={styles.productoHeaderTextCenterLast}>IMPORTE €</Text>
						</View>
						{order.items[key]?.filter(p => p.billable === true || productsList.find(prod => prod._id === p.product)?.billable === true).map((product, index) =>(
							<View key={index} style={styles.bloqueProductosBody}>
								<Text style={styles.productoBodyTextCenter}>{productsList.find(p => p._id === product.product)?.sku}</Text>
								<View style={styles.productoBodyTextCenterLarge}>
									<Text style={styles.nombreProducto}>{product.name.toUpperCase()} {product.attributes?.length > 0 ? `${attributesList?.find(att => att._id === product.attributes[0].attribute)?.name?.toUpperCase()}: ${product.attributes[0].value?.toUpperCase()}` : ''}</Text>
									<Text>({product.student?.name})</Text>
								</View>
								<Text style={styles.productoBodyTextCenter}>{product.qty}</Text>
								<Text style={styles.productoBodyTextCenter}>{(product.price / (1 + (product.iva || 0) / 100)).toFixed(2)}</Text>
								<Text style={styles.productoBodyTextCenter}></Text>
								<Text style={styles.productoBodyTextCenterLast}>{((product.price / (1 + (product.iva || 0) / 100)) * product.qty).toFixed(2)}</Text>
							</View>
						))}
					</View>
					<View style={styles.bloqueTotales}>
						<View style={styles.bloqueTotalesHeader}>
							<Text style={styles.totalHeaderTextCenter}>BASE</Text>
							<Text style={styles.totalHeaderTextCenterColIz}>% IVA</Text>
							<Text style={styles.totalHeaderTextCenterColDe}>IMP.</Text>
							<Text style={styles.totalHeaderTextCenterColIz}>% REC</Text>
							<Text style={styles.totalHeaderTextCenterColDe}>IMP.</Text>
							<Text style={styles.totalHeaderTextCenterLast}>TOTAL</Text>
						</View>
						<View style={styles.bloqueTotalesBody}>
							<Text style={styles.totalBodyTextCenter}>{noIVA.toFixed(2)}</Text>
							<Text style={styles.totalBodyTextCenterColIz}>0</Text>
							<Text style={styles.totalBodyTextCenterColDe}>0</Text>
							<Text style={styles.totalBodyTextCenterColIz}></Text>
							<Text style={styles.totalBodyTextCenterColDe}></Text>
							<Text style={styles.totalBodyTextCenterLast}>{noIVA.toFixed(2)}</Text>
						</View>
						<View style={styles.bloqueTotalesBody}>
							<Text style={styles.totalBodyTextCenter}>{(sRIVA / (1 + (4) / 100)).toFixed(2)}</Text>
							<Text style={styles.totalBodyTextCenterColIz}>4</Text>
							<Text style={styles.totalBodyTextCenterColDe}>{(sRIVA - (sRIVA / (1 + (4) / 100))).toFixed(2)}</Text>
							<Text style={styles.totalBodyTextCenterColIz}></Text>
							<Text style={styles.totalBodyTextCenterColDe}></Text>
							<Text style={styles.totalBodyTextCenterLast}>{sRIVA.toFixed(2)}</Text>
						</View>
						<View style={styles.bloqueTotalesBody}>
							<Text style={styles.totalBodyTextCenter}>{(rIVA / (1 + (10) / 100)).toFixed(2)}</Text>
							<Text style={styles.totalBodyTextCenterColIz}>10</Text>
							<Text style={styles.totalBodyTextCenterColDe}>{(rIVA - (rIVA / (1 + (10) / 100))).toFixed(2)}</Text>
							<Text style={styles.totalBodyTextCenterColIz}></Text>
							<Text style={styles.totalBodyTextCenterColDe}></Text>
							<Text style={styles.totalBodyTextCenterLast}>{rIVA.toFixed(2)}</Text>
						</View>
						<View style={styles.bloqueTotalesBody}>
							<Text style={styles.totalBodyTextCenter}>{(IVA / (1 + (21) / 100)).toFixed(2)}</Text>
							<Text style={styles.totalBodyTextCenterColIz}>21</Text>
							<Text style={styles.totalBodyTextCenterColDe}>{(IVA - (IVA / (1 + (21) / 100))).toFixed(2)}</Text>
							<Text style={styles.totalBodyTextCenterColIz}></Text>
							<Text style={styles.totalBodyTextCenterColDe}></Text>
							<Text style={styles.totalBodyTextCenterLast}>{IVA.toFixed(2)}</Text>
						</View>
						<View style={styles.bloqueTotalesFooter}>
							<Text style={styles.totalFooterTextCenter}>{(noIVA + (sRIVA / (1 + (4) / 100)) + (rIVA / (1 + (10) / 100)) + (IVA / (1 + (21) / 100))).toFixed(2)}</Text>
							<Text style={styles.totalFooterTextCenterColIz}>% IVA</Text>
							<Text style={styles.totalFooterTextCenterColDe}>{((sRIVA - (sRIVA / (1 + (4) / 100))) + (rIVA - (rIVA / (1 + (10) / 100))) + (IVA - (IVA / (1 + (21) / 100)))).toFixed(2)}</Text>
							<Text style={styles.totalFooterTextCenterColIz}>% REC</Text>
							<Text style={styles.totalFooterTextCenterColDe}>IMP.</Text>
							<Text style={styles.totalFooterTextCenterLast}>{(parseFloat(noIVA) + parseFloat(sRIVA) + parseFloat(rIVA) + parseFloat(IVA)).toFixed(2)}</Text>
						</View>
					</View>
					<View>
						<Text style={styles.bloqueTotalFacturaText}>TOTAL FACTURA</Text>
						<Text style={styles.bloqueTotalFacturaValue}>{(parseFloat(noIVA) + parseFloat(sRIVA) + parseFloat(rIVA) + parseFloat(IVA)).toFixed(2)} €</Text>
					</View>
				</Page>
			))
		}
			
		</Document>
	)
}