import { MESSAGE_GET_ALL, MESSAGE_GET_USER, MESSAGE_READ } from "../constants/messageConstants";

export const messageReducer = (state = [], action) => {
    switch (action.type) {
        case MESSAGE_GET_ALL:
            return action.payload.data
        case MESSAGE_GET_USER:
            return action.payload.data
        case MESSAGE_READ:
            return state.map((message) => {
                if (message._id === action.payload.message._id) {
                    return {...message, read: true}
                } else {
                    return message
                }
            })
        default:
            return state;
    }
}