export const SUBSCRIPTION_SALES_SUBSCRIPTIONPRODUCT_REQUEST = 'SUBSCRIPTION_SALES_SUBSCRIPTIONPRODUCT_REQUEST'
export const SUBSCRIPTION_SALES_SUBSCRIPTIONPRODUCT_SUCCESS = 'SUBSCRIPTION_SALES_SUBSCRIPTIONPRODUCT_SUCCESS'

export const SUBSCRIPTION_PAYMENT_SUCCESS = 'SUBSCRIPTION_PAYMENT_SUCCESS'
export const SUBSCRIPTION_PAYMENT_RESET = 'SUBSCRIPTION_PAYMENT_RESET'

export const SUBSCRIPTION_MINE_LIST_REQUEST = 'SUBSCRIPTION_MINE_LIST_REQUEST'
export const SUBSCRIPTION_MINE_LIST_SUCCESS = 'SUBSCRIPTION_MINE_LIST_SUCCESS'
export const SUBSCRIPTION_MINE_LIST_FAIL = 'SUBSCRIPTION_MINE_LIST_FAIL'

export const SUBSCRIPTION_CANCEL_REQUEST = 'SUBSCRIPTION_CANCEL_REQUEST'
export const SUBSCRIPTION_CANCEL_SUCCESS = 'SUBSCRIPTION_CANCEL_SUCCESS'
export const SUBSCRIPTION_CANCEL_FAIL = 'SUBSCRIPTION_CANCEL_FAIL'
export const SUBSCRIPTION_CANCEL_RESET = 'SUBSCRIPTION_CANCEL_RESET'

export const SUBSCRIPTION_LIST_REQUEST = 'SUBSCRIPTION_LIST_REQUEST'
export const SUBSCRIPTION_LIST_SUCCESS = 'SUBSCRIPTION_LIST_SUCCESS'
export const SUBSCRIPTION_LIST_FAIL = 'SUBSCRIPTION_LIST_FAIL'

export const SUBSCRIPTION_PAID_BY_DATE_REQUEST = 'SUBSCRIPTION_PAID_BY_DATE_REQUEST'
export const SUBSCRIPTION_PAID_BY_DATE_SUCCESS = 'SUBSCRIPTION_PAID_BY_DATE_SUCCESS'
export const SUBSCRIPTION_PAID_BY_DATE_FAIL = 'SUBSCRIPTION_PAID_BY_DATE_FAIL'
export const SUBSCRIPTION_PAID_BY_DATE_RESET = 'SUBSCRIPTION_PAID_BY_DATE_RESET'

export const SUBSCRIPTION_REQUEST_CANCEL_REQUEST = 'SUBSCRIPTION_REQUEST_CANCEL_REQUEST'
export const SUBSCRIPTION_REQUEST_CANCEL_SUCCESS = 'SUBSCRIPTION_REQUEST_CANCEL_SUCCESS'
export const SUBSCRIPTION_REQUEST_CANCEL_FAIL = 'SUBSCRIPTION_REQUEST_CANCEL_FAIL'
export const SUBSCRIPTION_REQUEST_CANCEL_RESET = 'SUBSCRIPTION_REQUEST_CANCEL_RESET'