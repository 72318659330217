import { CART_ADD_ITEM, CART_EMPTY, CART_REMOVE_ITEM, CART_SAVE_PAYMENT_METHOD, CART_SAVE_SHIPPING_ADDRESS, CART_UPDATE_PRODUCT } from "../constants/cartConstants";

export const cartReducer = (state = { cartItems: [] }, action) => {
    switch (action.type) {
        case CART_ADD_ITEM:
            const item = action.payload;
            return {...state, cartItems: [...state.cartItems, item] }
        case CART_REMOVE_ITEM:
            // return {
            //     ...state,
            //     cartItems: state.cartItems.filter((x) => x.product !== action.payload)
            // }
            let items = []
            state.cartItems.forEach((ele, ind) =>{
                if (ind !== action.payload) {
                    items.push(ele)
                }
            })
            return {
                ...state,
                cartItems: items
            }
        case CART_SAVE_SHIPPING_ADDRESS:
            return {
                ...state,
                shippingAddress: action.payload
            }
        case CART_SAVE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload
            }
        case CART_UPDATE_PRODUCT:
            const prod = action.payload
            return {...state, cartItems: state.cartItems.map((item) => {
                if (item.product === prod.product) {
                    let productItem = item
                    productItem.qty = prod.qty
                    return productItem
                } else {
                    return item
                }
            }) }
        case CART_EMPTY:
            return {
                ...state,
                cartItems: [],
                paymentMethod: null
            }
        default:
            return state;
    }
}