import React, {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { savePaymentMethod } from '../actions/cartActions';
import { getNotificationsUser } from '../actions/notificationActions';
import { createOrder, updateStock } from '../actions/orderActions';
import { AlertMessage } from '../components/AlertMessage';
import { ToolBar } from '../components/ToolBar';
import { ORDER_CREATE_RESET } from '../constants/orderConstants';

export const PaymentMethodScreen = () => {

    const cart = useSelector((state) => state.cart);

    const orderCreate = useSelector((state) => state.orderCreate);
    const {success, error, order} = orderCreate;

    const navigate = useNavigate();
    
    const [selectedPaymentMethod, setPaymentMethod] = useState('PayPal');

    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(savePaymentMethod(selectedPaymentMethod));
    }

    useEffect(()=>{
        if (selectedPaymentMethod && cart.paymentMethod) {
            const toPrice = (num) => Number(num.toFixed(2)); // 5.123 => "5.12" => 5.12
  
            let totalPrice = 0
            let totalTaxes = 0

            cart.cartItems.forEach(item => {
                let priceWithoutTaxes = item.price / (1 + item.iva / 100)
                let taxesPrice = item.price - priceWithoutTaxes

                totalPrice += item.qty * priceWithoutTaxes
                totalTaxes += item.qty * taxesPrice
            });

            // Precio total de los artículos
            cart.itemsPrice = toPrice(totalPrice)
            //Precio total del iva de los artículos
            cart.taxPrice = toPrice(totalTaxes)
            // Coste de envío
            cart.shippingPrice = 0;
            // Total: precio de los articulos + coste de envío + impuestos
            cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;
            dispatch(createOrder({...cart, orderItems: cart.cartItems}))
            cart.cartItems.forEach(item => {
                let fields = {attr: false, stock: item.qty, value: null}
                if (item.attributes.length > 0) {
                    fields.attr = true
                    fields.value = item.attributes[0].value
                }
                dispatch(updateStock(fields, item.product))
            });
        }
    }, [dispatch, selectedPaymentMethod, cart])

    useEffect(() => {
        if(success) {        
            navigate(`/order/${order._id}`);
            dispatch({type: ORDER_CREATE_RESET});
        }
        dispatch(getNotificationsUser());
      }, [dispatch, order, success, navigate])

    return ( 
        
    <>
    <ToolBar titulo={"Checkout"} hitos={[
        {titulo: "Inicio", enlace: "/" },
        {titulo: "Carrito", enlace: "cart" },
        {titulo: "Método de pago", enlace: "#" }]}/>
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">						
      <div className="content flex-row-fluid" id="kt_content">
        <div className="row g-7">
            <div className="col-xl-12">        
                <div className="card card-flush h-lg-100" id="kt_contacts_main">            
                    <div className="card-header pt-7" id="kt_chat_contacts_header">                
                        <div className="card-title">                    
                            <span className="svg-icon svg-icon-1 me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black" />
                                    <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black" />
                                </svg>
                            </span>                    
                            <h2>Método de pago</h2>
                        </div>                
                    </div>                        
                    <div className="card-body pt-5">                
                        <form autoComplete='off' id="kt_ecommerce_settings_general_form" className="form" onSubmit={submitHandler}>                                                                              
                        
                        <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                            <div className="col-12 col-md-4">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-4">
                                <span className="required">Seleccione un método de pago</span>
                                <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Selecciona un método de pago" aria-label="Selecciona un método de pago"></i>
                            </label>
                            <label className="d-flex flex-stack cursor-pointer mb-5">    
                                <span className="d-flex align-items-center me-2">        
                                    <span className="symbol symbol-50px me-6">
                                        <span className="symbol-label bg-light-primary">
                                            <img src="/assets/media/svg/payment-methods/paypal.svg" alt="Avatar"/>
                                        </span>
                                    </span>                
                                    <span className="d-flex flex-column">
                                        <span className="fw-bolder fs-6">PayPal</span>            
                                    </span>        
                                </span>        
                                <span className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" id="paypal" value="PayPal" name="paymentMethod" required defaultChecked onClick={ (e) => setPaymentMethod(e.target.value)}/>
                                </span>    
                            </label>
                            <label className="d-flex flex-stack cursor-pointer mb-5">    
                                <span className="d-flex align-items-center me-2">        
                                    <span className="symbol symbol-50px me-6">
                                        <span className="symbol-label bg-light-primary">
                                            <img src="/assets/media/svg/payment-methods/visa.svg" alt="Avatar"/>
                                        </span>
                                    </span>                
                                    <span className="d-flex flex-column">
                                        <span className="fw-bolder fs-6">Tarjeta de crédito/débito</span>            
                                    </span>        
                                </span>        
                                <span className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="radio" id="card" value="Tarjeta crédito/débito" name="paymentMethod" required onClick={ (e) => setPaymentMethod(e.target.value)}/>
                                </span>    
                            </label>
                            </div>
                        </div>
                                                                                                                
                        <div className="separator mb-6"></div>                                        
                            <div className="d-flex justify-content-end">                        
                                <button type="submit" data-kt-contacts-type="submit" className="btn btn-primary">
                                    <span className="indicator-label">Continuar</span>
                                    <span className="indicator-progress">Espere por favor...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                                {error && (
                                <AlertMessage variant={"danger"} message={error}/>
                                )}
                            </div>                    
                        </form>                
                    </div>            
                </div>        
            </div>
        </div>
      </div>
    </div>
    </>
    )
}