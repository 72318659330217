import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { detailsOrder } from '../actions/orderActions'
import { LoadingBox } from '../components/LoadingBox'
import { AlertMessage } from '../components/AlertMessage'
import { listAttributes } from '../actions/attributeActions';
import { PDFDownloadLink } from '@react-pdf/renderer'
import Invoice from '../components/Invoice'
import { listProducts } from '../actions/productActions'
import { ToolBar } from '../components/ToolBar'

export const DownloadOrderScreen = (props) => {
    const params = useParams();

    const orderId = params.id;
    const attributeList = useSelector((state) => state.attributeList);
    const { attributes } = attributeList;

    const orderDetails = useSelector(state => state.orderDetails);
    const { loading, order, error } = orderDetails;

    const {products} = useSelector(state => state.productList)

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(detailsOrder(orderId))
        dispatch(listAttributes())
        dispatch(listProducts())
    }, [dispatch, orderId])

    useEffect(() => {
        if (order?.isPaid && products && attributes && document.getElementById('dlink').lastChild?.tagName === 'A') {
            setTimeout(() => {
                document.getElementById('dlink').lastChild.click()
            }, 200)
        }
    },[order, products, attributes])

    return (
        <>
            <ToolBar hitos={[]}/>
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    {loading ? (
                        <LoadingBox variant={"primary"} />
                    ) : error ? (
                        <AlertMessage variant={"danger"} message={error} />
                    ) : (
                        <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                            <div className="col-12">
                                <div className="card card-flush">
                                    <div className="card-header pt-5" id='dlink'>
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bolder text-dark">Descarga de factura</span>
                                        </h3>
                                        {
                                            order.isPaid && products && attributes
                                            ?
                                                <PDFDownloadLink document={<Invoice order={order} productsList={products} attributesList={attributes} />} fileName={'factura_.pdf'}>
                                                    <button type="button" className="btn btn-primary btn-block">Descargar factura</button>
                                                </PDFDownloadLink>
                                            :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
        </>
    )
}
