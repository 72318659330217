import Axios from 'axios'
import { USERS_IMPORT_FAIL, USERS_IMPORT_REQUEST, USERS_IMPORT_SUCCESS, USER_DELETE_FAIL, USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DETAILS_FAIL, USER_DETAILS_REQUEST, USER_DETAILS_SUCCESS, USER_FORGOT_PASSWORD_FAIL, USER_FORGOT_PASSWORD_REQUEST, USER_FORGOT_PASSWORD_SUCCESS, USER_LIST_FAIL, USER_LIST_REQUEST, USER_LIST_SUCCESS, USER_LIST_TEACHERS_FAIL, USER_LIST_TEACHERS_REQUEST, USER_LIST_TEACHERS_SUCCESS, USER_REGISTER_FAIL, USER_REGISTER_SUCCESS, USER_RESET_PASSWORD_FAIL, USER_RESET_PASSWORD_REQUEST, USER_RESET_PASSWORD_RESET, USER_SIGNIN_FAIL, USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS, USER_SIGNOUT, USER_UPDATE_FAIL, USER_UPDATE_PROFILE_FAIL, USER_UPDATE_PROFILE_REQUEST, USER_UPDATE_PROFILE_SUCCESS, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS } from '../constants/userConstants'
import { getUserToken, removeUserToken } from '../firebase';


export const signin = (username, password) => async (dispatch) => {
    dispatch({ type: USER_SIGNIN_REQUEST, payload: { username, password } });
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/users/signin', { username, password });
        sessionStorage.setItem('userInfo', JSON.stringify(data));
        localStorage.setItem('username', username)
        dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
        getUserToken(data._id)
    } catch (error) {
        dispatch({
            type: USER_SIGNIN_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message
        })
    }
}

export const register = (name, email, password, dni, phone, selectedUsers, address, city, state, postalCode) => async (dispatch) => {
    dispatch({ type: USER_REGISTER_SUCCESS, payload: { name, email, password, dni, phone, selectedUsers, address, city, state, postalCode } });
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/users/register', { name, email, password, dni, phone, selectedUsers, address, city, state, postalCode, active: true });
        dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message
        })
    }
}

export const forgotPassword = (username) => async (dispatch) => {
    dispatch({ type: USER_FORGOT_PASSWORD_REQUEST, payload: { username } });
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/users/forgotpassword', { username });        
        dispatch({ type: USER_FORGOT_PASSWORD_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_FORGOT_PASSWORD_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message
        })
    }
}

export const resetPassword = (userId, password) => async (dispatch) => {
    dispatch({ type: USER_RESET_PASSWORD_REQUEST, payload: { userId, password } });
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/users/resetpassword', { userId, password });        
        dispatch({ type: USER_RESET_PASSWORD_RESET, payload: data });
    } catch (error) {
        dispatch({
            type: USER_RESET_PASSWORD_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message
        })
    }
}

export const signout = () => (dispatch) => {
    sessionStorage.removeItem('userInfo');
    localStorage.removeItem('orderId');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('paymentMethod');
    removeUserToken()
    dispatch({ type: USER_SIGNOUT });
    document.location.href = '/';
};


export const detailsUser = (userId) => async (dispatch, getState) => {
    dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/users/${userId}`, {
            headers: { Authorization: `Bearer ${userInfo?.token}` },
        });
        dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data
                ? error.response.data
                : error.message;
        dispatch({ type: USER_DETAILS_FAIL, payload: message });
    }
};





export const updateUserProfile = (user) => async (dispatch, getState) => {
    console.log(user);
    dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/users/profile`, user, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
        dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
        dispatch(detailsUser(user.userId))
        sessionStorage.setItem('userInfo', JSON.stringify(data));

    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message })
    }
}

export const listUsers = () => async (dispatch, getState) => {
    dispatch({ type: USER_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/users`, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: USER_LIST_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: USER_LIST_FAIL, payload: message })
    }
}

export const deleteUser = (userId) => async (dispatch, getState) => {
    dispatch({ type: USER_DELETE_REQUEST, payload: userId })
    const { userSignin: { userInfo } } = getState();

    try {
        const { data } = await Axios.delete(process.env.REACT_APP_DB_ADDRESS + `/api/users/${userId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: USER_DELETE_SUCCESS, payload: data })
    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: USER_DELETE_FAIL, payload: message })
    }
}

export const updateUser = (user) => async (dispatch, getState) => {
    dispatch({ type: USER_UPDATE_REQUEST, payload: user });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/users/${user._id}`, user, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: USER_UPDATE_SUCCESS, payload: data });

    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: USER_UPDATE_FAIL, payload: message })
    }
}

export const getTeachers = () => async (dispatch, getState) => {
    dispatch({type: USER_LIST_TEACHERS_REQUEST})
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/users/teachers`, {
        headers: { Authorization: `Bearer ${userInfo.token}` }
    });
    dispatch({ type: USER_LIST_TEACHERS_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: USER_LIST_TEACHERS_FAIL, payload: message })
    }
}

/**
 * Importa los estudiantes desde CSV
 * @param {*} users 
 * @returns 
 */
 export const importUsers = (users) => async (dispatch, getState) => {
    dispatch({ type: USERS_IMPORT_REQUEST, payload: users });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + `/api/users/import`, {users: users}, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });        
        dispatch({ type: USERS_IMPORT_SUCCESS, payload: data });

    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: USERS_IMPORT_FAIL, payload: message })
    }
}