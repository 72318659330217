import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { listUsers } from '../actions/userActions';
import { AlertMessage } from '../components/AlertMessage'
import { LoadingBox } from '../components/LoadingBox'
import { ToolBar } from '../components/ToolBar';
import Select from "react-select"
import { listCourses } from '../actions/courseActions';
import { STUDENT_UPDATE_RESET } from '../constants/studentConstants';
import { detailsStudent, listStudents, updateStudent } from '../actions/studentActions';
import Swal from 'sweetalert2';
import { getNotificationsUser } from '../actions/notificationActions';

export const StudentEditScreen = () => {
    const navigate = useNavigate();
    const params = useParams();
    const studentId = params.id;

    const [name, setName] = useState('');
    const [code, setCode] = useState('');

    const studentDetails = useSelector(state => state.studentDetails);
    const { loading, error, student } = studentDetails;

    const studentUpdate = useSelector(state => state.studentUpdate);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = studentUpdate;

    const userList = useSelector(state => state.userList);
    const { loading: loadingUsers, error: errorUsers, users } = userList;
    

    const courseList = useSelector((state) => state.courseList);
    const { loading: loadingCourses, error: errorCourses, courses } = courseList;

    const dispatch = useDispatch();

    // Select de Usuarios 
    
    const [usersOptions, setUsersOptions] = useState([])
    const selectedUsers = [];
    const [selectedOptions, setSelectedOptions] = useState([])

    // Select de Cursos
    let selectedCourse = null;
    // const courseOptions = []
    const [courseOptions, setCourseOptions] = useState([])
    const [selectedCourseOptions, setSelectedCourseOptions] = useState([])

    // Emergency phone options
    const [emergencyPhoneOptions, setEmergencyPhoneOptions] = useState([])
    const [selectedEmergencyPhoneOptions, setSelectedEmergencyPhoneOptions] = useState([])
    

    useEffect(() => {
        // Rellenamos el select de padres
        if (users) {
            setUsersOptions([])
            users.map((usuario) => (
                setUsersOptions((usersOptions) => [...usersOptions, { value: usuario._id, label: usuario.name }])
            ))            
        }
        
        // Rellenamos el select de cursos
        if (courses) {
            setCourseOptions([])
            courses.map((course) => (
                setCourseOptions((courseOptions) => [...courseOptions, { value: course._id, label: course.name }])            
            ))
        }
        
        // Rellenamos el select de teléfonos de emergencias
        if (student) {
            setEmergencyPhoneOptions([])
            student.userParents.map((parent) => (
                setEmergencyPhoneOptions((emergencyPhoneOptions) => [...emergencyPhoneOptions, { value: parent.phone, label: `${parent.phone} - ${parent.name}` }])
            ))
        }
 
    }, [users, courses, student])

    useEffect(() => {
        if (errorUpdate) {
            dispatch({ type: STUDENT_UPDATE_RESET });
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: errorUpdate,
                showConfirmButton: false,
                timer: 1500
            })
        }
        if (successUpdate === true) {
            dispatch({ type: STUDENT_UPDATE_RESET });
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Alumno editado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(detailsStudent(studentId))
        }

        if (!student) {
            dispatch(detailsStudent(studentId))
        }
        else {
            setName(student.name)                                    
            setCode(student.code)
            // if (student.userParents && !loading) {
            //     student.userParents.map((parent) => (
            //         emergencyPhoneOptions.push({ value: parent.phone, label: parent.phone })
            //     ))
            // }
        }
        dispatch(listStudents())
        dispatch(listCourses())
        dispatch(listUsers())
        dispatch(getNotificationsUser());

    }, [dispatch, student, studentId, navigate, successUpdate, errorUpdate])


    useEffect(() => {    
        if (users && student) {
           
            // Recuperamos el curso del alumno
            if (student.course) {                
                setSelectedCourseOptions(courseOptions.find(option => option.value === student.course._id));
            }

            if (student.userParents) {
                // Recuperamos el teléfono de emergencias del alumno
                setSelectedEmergencyPhoneOptions(emergencyPhoneOptions.find(option => option.value === student.emergencyPhone));

                // Recuperamos los padres del alumno
                let arrayValores = [];
                student.userParents.map((parent) => (
                    arrayValores.push(usersOptions.find(option => option.value === parent._id))
                ))
                setSelectedOptions(arrayValores);
            }
            
            
        }
    }, [users, student, courseOptions, usersOptions])

    useEffect(()=>{
        let options = []
        selectedOptions.forEach(element => {
            let parent = users?.find(us => us._id === element?.value)
            if (parent) {
                options.push({value: parent.phone, label: `${parent.phone} - ${parent.name}`})
            }
        })
        setEmergencyPhoneOptions(options)
    }, [ selectedOptions, users])

    const submitHandler = (e) => {
        e.preventDefault();

        if (name !== '') {
            if (code !== '') {
                if (selectedOptions.length > 0) {
                    if (selectedCourseOptions) {
                        // Añadir objetos de hijos al padre
                        for (const user of users) {
                            for (const selectedOption of selectedOptions) {
                                if (user._id === selectedOption.value) {
                                    selectedUsers.push(user)
                                }                
                            }
                        }
                        
                        // Añadir cursos al usuario
                        if (selectedCourseOptions !== null) {
                            selectedCourse = courses.find(course => course._id === selectedCourseOptions.value)
                        }else {
                            selectedCourse = '';
                        }
                        dispatch(updateStudent({
                            _id: studentId,
                            name,
                            userParents: selectedUsers,
                            course: selectedCourse,
                            emergencyPhone: selectedEmergencyPhoneOptions ? selectedEmergencyPhoneOptions.value : null,
                            code
                        }));
                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonText: 'De acuerdo',
                            title: 'Debes asignar un curso al alumno'
                        })
                    }
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: 'De acuerdo',
                        title: 'Debes seleccionar al menos un padre al alumno'
                    })
                }
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonText: 'De acuerdo',
                    title: 'El alumno debe tener un código único'
                })
            }
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonText: 'De acuerdo',
                title: 'El alumno debe tener un nombre'
            })
        }
    }

    const handleCourseChange = (selectedCourseOptions) => {
        setSelectedCourseOptions(selectedCourseOptions)
    }

    const handleChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions)
    }

    const handleEmergencyPhoneChange = (selectedPhoneOptions) => {
        setSelectedEmergencyPhoneOptions(selectedPhoneOptions)
    }

    

    return (
        <>
            <ToolBar titulo={"Editar alumno"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Alumnos", enlace: "/studentlist" },
                { titulo: "Editar alumno", enlace: "#" }]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <div>
                        {loadingUpdate && <LoadingBox variant={"primary"} />}
                        {errorUpdate && <AlertMessage variant={"danger"} message={errorUpdate} />}
                    </div>
                    {
                        loading ? <LoadingBox variant={"primary"} /> :
                        error ? <AlertMessage variant={"danger"} message={error} /> :
                        <>
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-body pt-9 pb-0">
                                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                                        <div className="me-7 mb-4">
                                            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                                <img src="/assets/media/avatars/blank.png" alt="Avatar" />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                <div className="d-flex flex-column">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <h1 className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{name}</h1>
                                                    </div>
                                                    {student.userParents.length > 0 &&
                                                        <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                                            <div className="text-hover-primary fs-3 fw-bolder me-1">Padres</div>
                                                            {student.userParents.map((padre) => (
                                                                <div className="col-12" key={padre.name}>{padre.name}</div>
                                                            ))}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                                    <div className="card-title m-0">
                                        <h3 className="fw-bolder m-0">Datos del alumno</h3>
                                    </div>
                                </div>
                                <div id="kt_account_settings_profile_details" className="collapse show">
                                    <form autoComplete='off' id="kt_account_profile_details_form" className="form" onSubmit={submitHandler}>
                                        <div className="card-body border-top p-9">
                                            <h3 className="fw-bolder mx-0 my-5">Datos generales</h3>                 
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label required fw-bold fs-6">Nombre</label>
                                                <div className="col-lg-8">
                                                    <div className="row">
                                                        <div className="col-lg-12 fv-row">
                                                            <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="name" type="text" placeholder="Introduce el nombre" value={name} onChange={(e) => setName(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                                                                                        
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label required fw-bold fs-6">Código</label>
                                                <div className="col-lg-8">
                                                    <div className="row">
                                                        <div className="col-lg-12 fv-row">
                                                            <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="code" type="text" placeholder="Introduce el codigo escolar" value={code} onChange={(e) => setCode(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label required fw-bold fs-6">Padres</label>
                                                <div className="col-lg-8">
                                                    <div className="row">
                                                        <div className="col-lg-12 fv-row">
                                                            {loadingUsers ? (<LoadingBox variant={"primary"} />) :
                                                                errorUsers ? (<AlertMessage variant={"danger"} message={errorUsers} />) : (
                                                                    <Select isMulti isClearable options={usersOptions} onChange={handleChange} value={selectedOptions} placeholder="Escoja los padres del alumno" />
                                                                    
                                                                )}
                                                                <div className="text-muted fs-7">Escoja uno a uno los padres del alumno. Tambien puede buscarlos escribiendo parte del nombre.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">Teléfono de emergencias</label>
                                                <div className="col-lg-8">
                                                    <div className="row">
                                                        <div className="col-lg-12 fv-row">
                                                        {loading ? (<LoadingBox variant={"primary"} />) :
                                                                error ? (<AlertMessage variant={"danger"} message={error} />) : (
                                                                    <Select isClearable options={emergencyPhoneOptions} value={selectedEmergencyPhoneOptions} onChange={handleEmergencyPhoneChange}  placeholder="Teléfono de emergencias del alumno" />
                                                                )}
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label required fw-bold fs-6">Curso escolar</label>
                                                <div className="col-lg-8">
                                                    <div className="row">
                                                        <div className="col-lg-12 fv-row">

                                                            {loadingCourses ? (<LoadingBox variant={"primary"} />) :
                                                                errorCourses ? (<AlertMessage variant={"danger"} message={errorCourses} />) : (
                                                                    <Select isClearable options={courseOptions} onChange={handleCourseChange} value={selectedCourseOptions} placeholder="Curso escolar del alumno" />
                                                                )}
                                                                <div className="text-muted fs-7">Escoja un curso. Tambien puede buscarlo escribiendo parte del nombre.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                            <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit">Actualizar usuario</button>
                                            <Link id="kt_ecommerce_add_product_cancel" className="btn btn-light ms-5" to={`/studentlist`}>Cancelar</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}
