import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { detailsCategory, listCategories, updateCategory } from '../actions/categoryActions';
import { getNotificationsUser } from '../actions/notificationActions';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar'
import { CATEGORIE_UPDATE_RESET } from '../constants/categorieConstants';
import Select from "react-select"

export const EditCategoryScreen = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { id: categoryId } = params;



    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [show, setShow] = useState('')
    const [image, setImage] = useState('')
    const [parents, setParents] = useState({options: [], value: null})

    const categoryDetails = useSelector(state => state.categoryDetails);
    const { loading, error, category } = categoryDetails;

    const { loading: loadingCategories, error: errorCategories, categories } = useSelector((state) => state.categorieList);

    const categoryUpdate = useSelector(state => state.categoryUpdate);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = categoryUpdate;

    const dispatch = useDispatch();

    useEffect(() => {
        if (successUpdate) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Categoría editada correctamente',
                showConfirmButton: false,
                timer: 1500
            })
        }
        if (!category || category._id !== categoryId || successUpdate) {
            dispatch({ type: CATEGORIE_UPDATE_RESET });

            dispatch(detailsCategory(categoryId));
        }
        else {            
            setName(category.name);
            setDescription(category.description);
            setShow(category.show)
            setImage(category.image);
        }
        dispatch(getNotificationsUser());
    }, [category, dispatch, categoryId, navigate, successUpdate])

    useEffect(() => {
        dispatch(listCategories());
    }, [dispatch])

    useEffect(() => {
        if (categories && category) {
            let categoriesOptions = categories.map(cat => (
                {label: cat.name, value: cat._id}
            ))
            let categorySelected = categoriesOptions.find(c => c.value === category.parent)
            setParents({options: categoriesOptions, value: categorySelected})
        }
    }, [categories, category])

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateCategory({
            _id: categoryId,
            name,
            description,
            show,
            image,
            parent: parents.value?.value || null
        }))
    }

    return (
        <>
            <ToolBar titulo={"Editar categoría"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Editar categoría", enlace: "#" },
            ]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    {loadingUpdate && (
                        <LoadingBox variant={"primary"} />
                    )}

                    {errorUpdate && (
                        <AlertMessage variant={"danger"} message={errorUpdate} />
                    )}
                    {loading ? (
                        <LoadingBox variant={"primary"} />
                    ) :
                        error ? (
                            <AlertMessage variant={"danger"} message={error} />
                        ) :
                            <>
                                <form autoComplete='off' id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row" onSubmit={submitHandler}>
                                    <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                                        {/* <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Miniatura</h2>
                                                </div>
                                            </div>
                                            <div className="card-body text-center pt-0">
                                                <div className="image-input image-input-empty image-input-outline mb-3" data-kt-image-input="true">
                                                    <div className="image-input-wrapper w-150px h-150px" style={{ backgroundImage: `url('${image || category.image}')` }} />
                                                    <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                                        <i className="bi bi-pencil-fill fs-7" /> */}
                                                        {/* <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={uploadFileHandler} /> */}
                                                        {/* <input type="hidden" name="avatar_remove" /> */}
                                                        {/* {loadingUpload && <LoadingBox variant={"primary"} />}
                                                        {errorUpload && <AlertMessage variant={"danger"} message={errorUpload} />} */}
                                                    {/* </label>
                                                    <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                                                        <i className="bi bi-x fs-2" />
                                                    </span>
                                                    <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                                                        <i className="bi bi-x fs-2" />
                                                    </span>
                                                </div>
                                                <div className="text-muted fs-7">Establezca la imagen en miniatura de la categoría. Solo se aceptan archivos de imagen *.png, *.jpg y *.jpeg</div>
                                            </div>
                                        </div> */}
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Estado</h2>
                                                </div>
                                                <div className="card-toolbar">
                                                    <div className={`rounded-circle ${show ? 'bg-success' : 'bg-danger'} w-15px h-15px`} id="kt_ecommerce_add_product_status" />
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <select className="form-select mb-2" data-control="select2" data-hide-search="true" data-placeholder="Select an option" id="kt_ecommerce_add_product_status_select" value={show} onChange={(e) => setShow(e.target.value)}>
                                                    <option value={true}>Mostrada</option>
                                                    <option value={false}>Oculta</option>
                                                </select>
                                                <div className="text-muted fs-7">Mostrar/Ocultar categoría.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="kt_ecommerce_add_product_general">
                                                <div className="d-flex flex-column gap-7 gap-lg-10">
                                                    <div className="card card-flush py-4">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>General</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="mb-10 fv-row">
                                                                <label className="required form-label">Nombre de la categoría</label>
                                                                <input className="form-control mb-2" id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                                                <div className="text-muted fs-7">Se requiere un nombre de categoría que sea único.</div>
                                                            </div>
                                                            <div className="mb-10 fv-row">
                                                                <label className="form-label">Descripción</label>
                                                                <textarea className="form-control mb-3" rows="5" data-kt-element="input" placeholder="Escribe una descripción" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                                            </div>
                                                            {
                                                                loadingCategories ? (<LoadingBox variant={"primary"} />) :
                                                                errorCategories ? <AlertMessage variant={"danger"} message={errorCategories} /> :
                                                                <div className="mb-10 fv-row">
                                                                    <label className="required form-label">Categoía padre</label>
                                                                        <Select isClearable options={parents.options} onChange={(selectedValue) => setParents({...parents, value: selectedValue})} value={parents.value} placeholder="Escoja la categoría padre" />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* <div className="card card-flush py-4">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>Media</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="fv-row mb-2">
                                                                <label htmlFor="imageFile" className="form-label">Eligir la imagen del producto</label>
                                                                <input className="form-control" type="file" id="imageFile" onChange={uploadFileHandler}></input>
                                                                {loadingUpload && <LoadingBox variant={"primary"} />}
                                                                {errorUpload && <div>{errorUpload}</div>}
                                                            </div>
                                                            <div className="text-muted fs-7">Configure la galería multimedia del producto.</div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <Link to="/categorylist" id="kt_ecommerce_add_product_cancel" className="btn btn-light me-5">Cancelar</Link>
                                            <button type="submit" id="kt_ecommerce_add_product_submit" className="btn btn-primary">
                                                <span className="indicator-label">Actualizar</span>
                                                <span className="indicator-progress">Espere por favor...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </>
                    }
                </div>
            </div>
        </>
    )
}
