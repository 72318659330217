
const { PAYMENTMETHOD_LIST_SUCCESS, PAYMENTMETHOD_LIST_FAIL, PAYMENTMETHOD_DETAILS_REQUEST, PAYMENTMETHOD_DETAILS_SUCCESS, PAYMENTMETHOD_DETAILS_FAIL, PAYMENTMETHOD_LIST_REQUEST, PAYMENTMETHOD_CREATE_REQUEST, PAYMENTMETHOD_CREATE_SUCCESS, PAYMENTMETHOD_CREATE_FAIL, PAYMENTMETHOD_CREATE_RESET, PAYMENTMETHOD_DELETE_REQUEST, PAYMENTMETHOD_DELETE_SUCCESS, PAYMENTMETHOD_DELETE_FAIL, PAYMENTMETHOD_DELETE_RESET } = require('../constants/paymentMethodConstants')

export const paymentmethodListReducer = (state = { paymentMethods: [], },action) => {
	switch (action.type) {
		case PAYMENTMETHOD_LIST_REQUEST:
			return { loading: true };
		case PAYMENTMETHOD_LIST_SUCCESS:
			return { loading: false, paymentMethods: action.payload };
		case PAYMENTMETHOD_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

export const paymentmethodDetailsReducer = (state = { loading: true }, action) => {
	switch (action.type) {
		case PAYMENTMETHOD_DETAILS_REQUEST:
			return { loading: true };
		case PAYMENTMETHOD_DETAILS_SUCCESS:
			return { loading: false, paymentmethod: action.payload };
		case PAYMENTMETHOD_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}


export const paymentmethodCreateReducer = (state = {}, action) => {
	switch (action.type) {
		case PAYMENTMETHOD_CREATE_REQUEST:
			return { loading: true };
		case PAYMENTMETHOD_CREATE_SUCCESS:
			return { loading: false, success: true, paymentmethod: action.payload };
		case PAYMENTMETHOD_CREATE_FAIL:
			return { loading: false, error: action.payload };
		case PAYMENTMETHOD_CREATE_RESET:
			return {};
		default:
			return state;
	}
}

export const paymentmethodDeleteReducer = (state = {}, action) => {
	switch (action.type) {
		case PAYMENTMETHOD_DELETE_REQUEST:
			return { loading: true };
		case PAYMENTMETHOD_DELETE_SUCCESS:
			return { loading: false, success: true };
		case PAYMENTMETHOD_DELETE_FAIL:
			return { loading: false, error: action.payload };
		case PAYMENTMETHOD_DELETE_RESET:
			return {};
		default:
			return state;
	}
};
