import React from 'react'



export const OrderConfirmation = () => {


  return (
    <>
      <h1>GUAY O NO GUAY</h1>
    </>
    
  )
}
