const { EXTRACURRICULARACTIVITY_DETAILS_SUCCESS, EXTRACURRICULARACTIVITY_DETAILS_FAIL, EXTRACURRICULARACTIVITY_LIST_REQUEST, EXTRACURRICULARACTIVITY_DETAILS_REQUEST, EXTRACURRICULARACTIVITY_CREATE_REQUEST, EXTRACURRICULARACTIVITY_CREATE_SUCCESS, EXTRACURRICULARACTIVITY_CREATE_FAIL, EXTRACURRICULARACTIVITY_CREATE_RESET, EXTRACURRICULARACTIVITY_UPDATE_REQUEST, EXTRACURRICULARACTIVITY_UPDATE_SUCCESS, EXTRACURRICULARACTIVITY_UPDATE_FAIL, EXTRACURRICULARACTIVITY_UPDATE_RESET, EXTRACURRICULARACTIVITY_DELETE_REQUEST, EXTRACURRICULARACTIVITY_DELETE_SUCCESS, EXTRACURRICULARACTIVITY_DELETE_FAIL, EXTRACURRICULARACTIVITY_DELETE_RESET, EXTRACURRICULARACTIVITY_LIST_SUCCESS, EXTRACURRICULARACTIVITY_LIST_FAIL, EXTRACURRICULARACTIVITY_SPLITTED_LIST_REQUEST, EXTRACURRICULARACTIVITY_SPLITTED_LIST_SUCCESS, EXTRACURRICULARACTIVITY_SPLITTED_LIST_FAIL, EXTRACURRICULARACTIVITY_DUPLICATE_REQUEST, EXTRACURRICULARACTIVITY_DUPLICATE_FAIL, EXTRACURRICULARACTIVITY_DUPLICATE_SUCCESS, EXTRACURRICULARACTIVITY_DUPLICATE_RESET } = require('../constants/extracurricularActivityConstants');

export const extracurricularActivityListReducer = (state = { extracurricularActivities: [], },action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITY_LIST_REQUEST:
            return { loading: true };
        case EXTRACURRICULARACTIVITY_LIST_SUCCESS:
            return { loading: false, extracurricularActivities: action.payload };
        case EXTRACURRICULARACTIVITY_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const extracurricularActivitySplittedListReducer = (state = { extracurricularActivitiesSplitted: [], },action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITY_SPLITTED_LIST_REQUEST:
            return { loading: true };
        case EXTRACURRICULARACTIVITY_SPLITTED_LIST_SUCCESS:
            return { loading: false, extracurricularActivitiesSplitted: action.payload };
        case EXTRACURRICULARACTIVITY_SPLITTED_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const extracurricularActivityDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITY_DETAILS_REQUEST:
            return { loading: true };
        case EXTRACURRICULARACTIVITY_DETAILS_SUCCESS:
            return { loading: false, extracurricularActivity: action.payload };
        case EXTRACURRICULARACTIVITY_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}


export const extracurricularActivityCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITY_CREATE_REQUEST:
            return { loading: true };
        case EXTRACURRICULARACTIVITY_CREATE_SUCCESS:
            return { loading: false, success: true, extracurricularActivity: action.payload };
        case EXTRACURRICULARACTIVITY_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case EXTRACURRICULARACTIVITY_CREATE_RESET:
            return {};
        default:
            return state;
    }
}

export const extracurricularActivityDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case EXTRACURRICULARACTIVITY_DELETE_REQUEST:
        return { loading: true };
      case EXTRACURRICULARACTIVITY_DELETE_SUCCESS:
        return { loading: false, success: true };
      case EXTRACURRICULARACTIVITY_DELETE_FAIL:
        return { loading: false, error: action.payload };
      case EXTRACURRICULARACTIVITY_DELETE_RESET:
        return {};
      default:
        return state;
    }
  };

export const extracurricularActivityUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITY_UPDATE_REQUEST:
            return { loading: true };
        case EXTRACURRICULARACTIVITY_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case EXTRACURRICULARACTIVITY_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case EXTRACURRICULARACTIVITY_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};

export const extracurricularActivityDuplicateReducer = (state = {loading: false, result: null, error: null}, action) => {
    switch (action.type) {
        case EXTRACURRICULARACTIVITY_DUPLICATE_REQUEST:
            return {...state, loading: true}
        case EXTRACURRICULARACTIVITY_DUPLICATE_FAIL:
            return {...state, loading: false, error: action.payload}
        case EXTRACURRICULARACTIVITY_DUPLICATE_SUCCESS:
            return {...state, loading: false, result: 'duplicated'}
        case EXTRACURRICULARACTIVITY_DUPLICATE_RESET:
            return {...state, loading: false, error: null, result: null}
        default:
            return state
    }
}