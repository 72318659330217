import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cancelExtracurricularActivityInscription, listExtracurricularActivityInscriptionMine, requestCancelExtracurricularActivityInscription } from '../actions/extracurricularActivityInscriptionActions';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar';
import { CustomDataTable } from '../components/CustomDataTable';
import { getNotificationsUser } from '../actions/notificationActions';
import { EXTRACURRICULARACTIVITYINSCRIPTION_CANCEL_RESET, EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_RESET } from '../constants/extracurricularActivityInscriptionConstants';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import removeAccents from '../utils/removeAccents';

export const ExtracurricularActivityHistoryScreen = (props) => {
    const { extracurricularActivityInscriptions, error: errorList, loading: loadingList } = useSelector(state => state.extracurricularActivityInscriptionMine);

    const {error: errorCancel, success, loading: loadingCancel} = useSelector(state => state.extracurricularActivityInscriptionRequestCancel)
    const dispatch = useDispatch();

    const PERIODS = {"day": "Diario", "week": "Semanal", "month": "Mensual", "year": "Anual"}

    const columns = [
          {
          name: "REFERENCIA",
          selector: (row) => row.extracurricularactivityinscriptionReference,
          sortable: true,        
          cell: (row) => <span>{row.extracurricularactivityinscriptionReference}</span>,
          },
          {
            name: "ACTIVO DESDE",
            selector: (row) => row.isPaid,
            sortable: true,
            cell: (row) => <span>{row.isPaid ? row.paidAt?.substring(0, 10): 'No'}</span>,
          },
          {
            name: "PRODUCTO",
            selector: (row) => removeAccents(`${row.extracurricularActivity?.name}, ${row.fee.name}`),
            sortable: true,
            cell: row => <span>{row.extracurricularActivity?.name}, {row.fee.name}</span>
          },
          {
            name: "RECURRENCIA",
            selector: row => removeAccents(`${row.fee.price.toFixed(2)}€, ${PERIODS[row.fee.period]}`),
            sortable: true,
            cell: row => <span>{`${row.fee.price.toFixed(2)}€, ${PERIODS[row.fee.period]}`}</span>
          },
          {
            name: "ALUMNO",
            selector: row => removeAccents(row.student?.name),
            sortable: true,
            cell: row => <span>{row.student?.name}</span>
          },
          {
          name: "",
          cell: row => 
            !row.requestedToCancel
            ?
              row.active
              ?
                !row.requestedToCancel &&
                <div className="row">
                    <div className="col-12">
                      <i className="bi bi-journal-x px-2" style={{fontSize: '1.4rem', cursor: 'pointer'}} title='Cancelar suscripción' onClick={() => handleExtracurricularActivityInscriptionCancel(row._id)}/>
                    </div>
                </div>
              :
                <div className='row'>
                  <div className="col-12">
                    <Link to={`/extracurricularactivityinscription/${row._id}/pay`}>
                      <i className="bi bi-credit-card-2-back-fill px-2" style={{fontSize: '1.4rem'}} title='Pagar Actividad extraescolar'/>
                    </Link>
                  </div>
                </div>
              :
                <></>
            ,
          center: true,
        }
    ];

    const handleExtracurricularActivityInscriptionCancel = (id) => {
      Swal.fire({
        title: `¿Desea solicitar la cancelación de esta actividad extraescolar?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Si',
        denyButtonText: 'No', 
        cancelButtonText: "Cancelar",
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(requestCancelExtracurricularActivityInscription(id))
        }
      })
    }

    useEffect(() => {
      dispatch(listExtracurricularActivityInscriptionMine())
      dispatch(getNotificationsUser());
    }, [dispatch])

    useEffect(() => {
      if (success) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Solicitud de cancelación enviada correctamente',
          showConfirmButton: false,
          timer: 1500
        })
        dispatch({type: EXTRACURRICULARACTIVITYINSCRIPTION_REQUEST_CANCEL_RESET})
        dispatch(listExtracurricularActivityInscriptionMine())
        dispatch(getNotificationsUser());
      }
    }, [dispatch, success])
    
    return ( 
    <>
    <ToolBar titulo={"Historial de inscripciones de actividades extraescolares"} hitos={[
        {titulo: "Inicio", enlace: "/" },
        {titulo: "Actividades extraescolares", enlace: "#" }]}/>
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
      <div className="content flex-row-fluid" id="kt_content">
        <div className="card card-flush">
            <div className="card-body">
            { loadingList || loadingCancel ?  
                <LoadingBox variant={"primary"}/> : 
              errorList || errorCancel? 
                <AlertMessage variant={"danger"} message={errorList || errorCancel}/> : 
                (      
                <CustomDataTable data={extracurricularActivityInscriptions} columns={columns}/>
                )
            }
            </div>    
        </div>
      </div>
    </div>
    </>        
    )
}