export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL';
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL';

export const ORDER_MINE_LIST_REQUEST = 'ORDER_MINE_LIST_REQUEST'
export const ORDER_MINE_LIST_SUCCESS = 'ORDER_MINE_LIST_SUCCESS'
export const ORDER_MINE_LIST_FAIL = 'ORDER_MINE_LIST_FAIL'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL'

export const ORDER_DELETE_REQUEST = 'ORDER_DELETE_REQUEST'
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS'
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL'
export const ORDER_DELETE_RESET = 'ORDER_DELETE_RESET'

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST'
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS'
export const ORDER_DELIVER_FAIL = 'ORDER_DELIVER_FAIL'
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET'

export const ORDER_SALES_INFO_REQUEST = 'ORDER_SALES_INFO_REQUEST'
export const ORDER_SALES_INFO_SUCCESS = 'ORDER_SALES_INFO_SUCCESS'

export const ORDER_SALES_PRODUCT_REQUEST = 'ORDER_SALES_PRODUCT_REQUEST'
export const ORDER_SALES_PRODUCT_SUCCESS = 'ORDER_SALES_PRODUCT_SUCCESS'

export const ORDER_STOCK_UPDATED = 'ORDER_STOCK_UPDATED'

export const ORDER_EDIT_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_EDIT_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_EDIT_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_EDIT_RESET = 'ORDER_CREATE_RESET';

export const ORDERS_IMPORT_REQUEST = 'ORDERS_IMPORT_REQUEST'
export const ORDERS_IMPORT_SUCCESS = 'ORDERS_IMPORT_SUCCESS'
export const ORDERS_IMPORT_FAIL = 'ORDERS_IMPORT_FAIL'
export const ORDER_IMPORT_RESET = 'ORDER_IMPORT_RESET'

export const ORDER_PAID_BY_DATE_REQUEST = 'ORDER_PAID_BY_DATE_REQUEST'
export const ORDER_PAID_BY_DATE_SUCCESS = 'ORDER_PAID_BY_DATE_SUCCESS'
export const ORDER_PAID_BY_DATE_FAIL = 'ORDER_PAID_BY_DATE_FAIL'
export const ORDER_PAID_BY_DATE_RESET = 'ORDER_PAID_BY_DATE_RESET'

export const ORDER_CHANGE_TO_PAID_REQUEST = 'ORDER_CHANGE_TO_PAID_REQUEST'
export const ORDER_CHANGE_TO_PAID_SUCCESS = 'ORDER_CHANGE_TO_PAID_SUCCESS'
export const ORDER_CHANGE_TO_PAID_FAIL = 'ORDER_CHANGE_TO_PAID_FAIL'
export const ORDER_CHANGE_TO_PAID_RESET = 'ORDER_CHANGE_TO_PAID_RESET'

export const ORDER_LIST_NOT_PAID_REQUEST = 'ORDER_LIST_NOT_PAID_REQUEST'
export const ORDER_LIST_NOT_PAID_SUCCESS = 'ORDER_LIST_NOT_PAID_SUCCESS'
export const ORDER_LIST_NOT_PAID_FAIL = 'ORDER_LIST_NOT_PAID_FAIL'
export const ORDER_LIST_NOT_PAID_RESET = 'ORDER_LIST_NOT_PAID_RESET'

export const ORDER_FIND_BY_CUSTOM_FILTER_REQUEST = 'ORDER_FIND_BY_CUSTOM_FILTER_REQUEST'
export const ORDER_FIND_BY_CUSTOM_FILTER_SUCCESS = 'ORDER_FIND_BY_CUSTOM_FILTER_SUCCESS'
export const ORDER_FIND_BY_CUSTOM_FILTER_ERROR = 'ORDER_FIND_BY_CUSTOM_FILTER_ERROR'

export const ORDER_HIDE_REQUEST = 'ORDER_HIDE_REQUEST'
export const ORDER_HIDE_ERROR = 'ORDER_HIDE_ERROR'
export const ORDER_HIDE_SUCCESS = 'ORDER_HIDE_SUCCESS'
export const ORDER_HIDE_RESET = 'ORDER_HIDE_RESET'