import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Row, Col } from 'react-bootstrap'
import { CustomDataTable } from '../components/CustomDataTable';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar'
import Swal from 'sweetalert2';
import { getNotificationsUser } from '../actions/notificationActions';
import { getStopbullyings, getStopbullyingsUser, createStopbullying, readStopbullying, responseStopbullying } from '../actions/stopbullyingActions';
import { Editor } from "@tinymce/tinymce-react";
import removeAccents from '../utils/removeAccents';

export const StopbullyingScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stopbullyings = useSelector((state) => state.stopbullyingList)

    const { userInfo } = useSelector(state => state.userSignin)
    const [inbox, setInbox] = useState(true)
    const [stopbullyingsIn, setStopbullyingsIn] = useState([])
    const [stopbullyingsOut, setStopbullyingsOut] = useState([])

    const [sendingStopbullying, setsendingStopbullying] = useState(false)
    const stopbullying1 = useRef(null);
    const [lastestStopbullyings, setLastestStopbullyings] = useState('')
    const [lastestStopbullyingMedia, setLastestStopbullyingMedia] = useState([])
    const [stopbullyingsResume, setStopbullyingsResume] = useState(null)
    const [stopbullyingsShow, setStopbullyingsShow] = useState(null)

    const [stopbullying, setStopbullying] = useState('')
    const [fileList, setFileList] = useState(null)
    const files = fileList ? [...fileList] : []

    const [newStopbullying, setNewStopbullying] = useState('')
    const [subjectStopbullying, setSubjectStopbullying] = useState('')
    const [newfileList, setNewFileList] = useState(null)
    const newfiles = newfileList ? [...newfileList] : []

    const pushStopbullyingHandler = async(e) => {
        e.preventDefault();

        if (stopbullying !== '') {
            setsendingStopbullying(true)
            if(stopbullyingsShow.sender._id === userInfo._id){
                dispatch(responseStopbullying({
                    idstopbullying: stopbullyingsShow.parent,
                    sender: userInfo._id,
                    receiver: stopbullyingsShow.sender._id,
                    message: stopbullying,
                    media: files
                }))
            }else{
                dispatch(responseStopbullying({
                    idstopbullying: stopbullyingsShow.parent,
                    sender: userInfo._id,
                    receiver: stopbullyingsShow.receiver._id,
                    message: stopbullying,
                    media: files
                }))
            }
            setsendingStopbullying(false)
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Mensaje enviado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
        }
        setStopbullying('')
    }

    const createStopbullyingHandler = async() => {
        if (newStopbullying !== '') {
            setsendingStopbullying(true)
            dispatch(createStopbullying({
                sender: userInfo._id,
                subject: subjectStopbullying,
                message: newStopbullying,
                media: newfiles
            }))
            setsendingStopbullying(false)
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Mensaje enviado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
        }
        setNewStopbullying('')
    }

    useEffect(() => {
        dispatch(getStopbullyingsUser(userInfo._id))
        dispatch(getNotificationsUser())
    }, [dispatch, navigate])

    useEffect(() => {
        if(stopbullyings){
            let arrayStopbullyingsIn = []
            let arrayStopbullyingsOut = []

            stopbullyings.stopbullyingsIn?.map((stopbullyings) => {
                arrayStopbullyingsIn.push(stopbullyings)
            })
            setStopbullyingsIn(arrayStopbullyingsIn)

            stopbullyings.stopbullyingsOut?.map((stopbullyings) => {
                arrayStopbullyingsOut.push(stopbullyings)
            })
            setStopbullyingsOut(arrayStopbullyingsOut)
        }
    }, [stopbullyings])

    useEffect(() => {
        if(stopbullyingsResume){
            let stopbullyingsStructure = '</br>'
            if(stopbullyingsResume.type === 'inbox'){
                let stopbullyingToShow = stopbullyingsIn.find(stopbullyingIn => stopbullyingIn._id === stopbullyingsResume._id)
                if(stopbullyingToShow?._id !== stopbullyingsShow?._id){
                    setStopbullyingsShow(stopbullyingToShow)
                    dispatch(readStopbullying(stopbullyingsResume._id))

                    let timeToSave = 0

                    console.log('stopbullyingToShow', stopbullyingToShow)
                    console.log('stopbullyingToShow?.stopbullyings', stopbullyingToShow?.stopbullyings)
                    if(stopbullyingToShow?.stopbullyings){
                        for (let stopbullyingNow of stopbullyingToShow?.stopbullyings) {
                            if(stopbullyingNow._id === stopbullyingsResume._id){
                                timeToSave = 1
                            }
                            if(timeToSave === 1){
                                stopbullyingsStructure += '<hr style="border-style: dashed"/>'+
                                stopbullyingNow.message+'</br></br>';
                                if(stopbullyingNow.media?.length > 0){
                                    stopbullyingNow.media?.map((imagen) => {
                                        stopbullyingsStructure += '<a href="'+imagen+'" target="_blank"><img className="w-50px h-50px mx-3" src="'+imagen+'"/></a>'
                                    })
                                }

                                stopbullyingsStructure += '<p>'+new Date(stopbullyingNow.date)?.toTimeString().substring(0, 5)+'    '+stopbullyingNow.date?.substr(8,2)+'/'+stopbullyingNow.date?.substr(5,2)+'/'+stopbullyingNow.date?.substr(0,4)+'</p>'
                            }
                        }
                    }
                    setLastestStopbullyings(stopbullyingsStructure)

                }else{
                    setStopbullyingsShow(null)
                    setLastestStopbullyings('')
                    setLastestStopbullyingMedia([])
                }
            }else{
                let stopbullyingToShow = stopbullyingsOut.find(stopbullyingOut => stopbullyingOut._id === stopbullyingsResume._id)
                if(stopbullyingToShow !== stopbullyingsShow){
                    setStopbullyingsShow(stopbullyingToShow)

                    let timeToSave = 0

                    if(stopbullyingToShow?.stopbullyings){
                        for (let stopbullyingNow of stopbullyingToShow?.stopbullyings) {
                            if(stopbullyingNow._id === stopbullyingsResume._id){
                                timeToSave = 1
                            }
                            if(timeToSave === 1){
                                stopbullyingsStructure += '<hr style="border-style: dashed"/>'+
                                stopbullyingNow.message+'</br></br>';
                                if(stopbullyingNow.media?.length > 0){
                                    stopbullyingNow.media?.map((imagen) => {
                                        stopbullyingsStructure += '<a href="'+imagen+'" target="_blank"><img className="w-50px h-50px mx-3" src="'+imagen+'"/></a>'
                                    })
                                }

                                stopbullyingsStructure += '<p>'+new Date(stopbullyingNow.date)?.toTimeString().substring(0, 5)+'    '+stopbullyingNow.date?.substr(8,2)+'/'+stopbullyingNow.date?.substr(5,2)+'/'+stopbullyingNow.date?.substr(0,4)+'</p>'
                            }
                        }
                    }
                    setLastestStopbullyings(stopbullyingsStructure)
                }else{
                    setStopbullyingsShow(null)
                    setLastestStopbullyings('')
                    setLastestStopbullyingMedia([])
                }
            }
        }
    }, [stopbullyingsResume])
  
    const handleFileChange = (e) => {
        setFileList(e.target.files)
    }
  
    const handleNewFileChange = (e) => {
        setNewFileList(e.target.files)
    }

    useEffect(() => {
        setStopbullyingsShow(null)
    }, [inbox])

    const columnsIn = [
        {
            name: "Asunto",
            cell: row => 
                <div className="d-flex align-items-center" onClick={() => setStopbullyingsResume({type: 'inbox', _id: row._id})}>
                    {inbox === true && row.receiver_read === false
                        ? <h2>{row.subject}</h2>
                        : row.subject
                    }
                </div>,
            selector: (row) => removeAccents(row.subject),
            sortable: true,
        },
        {
            name: 'Fecha',
            cell: row =>
                <div className="d-flex align-items-center" onClick={() => setStopbullyingsResume({type: 'inbox', _id: row._id})}>
                    {new Date(row.date)?.toTimeString().substring(0, 5)+' ('+row.date?.substr(8,2)+'/'+row.date?.substr(5,2)+'/'+row.date?.substr(0,4)+')'}
                </div>,
            selector: (row) => row.date,
            sortable: true,
        }
    ]

    const columnsOut = [
        {
            name: "Asunto",
            cell: row => 
                <div className="d-flex align-items-center" onClick={() => setStopbullyingsResume({type: 'outbox', _id: row._id})}>
                    {row.subject}
                </div>,
            selector: (row) => removeAccents(row.subject),
            sortable: true,
        },
        {
            name: 'Fecha',
            cell: row =>
                <div className="d-flex align-items-center" onClick={() => setStopbullyingsResume({type: 'outbox', _id: row._id})}>
                    {new Date(row.date)?.toTimeString().substring(0, 5)+' ('+row.date?.substr(8,2)+'/'+row.date?.substr(5,2)+'/'+row.date?.substr(0,4)+')'}
                </div>,
            selector: (row) => row.name,
            sortable: true,
        }
    ]

    return (
        <>
            <ToolBar titulo={"No al Bullying"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "No al Bullying", enlace: "#" },
            ]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <form autoComplete='off' className="form w-100" noValidate="novalidate" id="kt_sign_in_form" onSubmit={pushStopbullyingHandler}>
                    <div className="content flex-row-fluid" id="kt_content">
                        <div className="card card-flush">
                            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                <div className="card-toolbar flex-row-fluid gap-5">
                                    <button type="button" className="btn btn-primary" onClick={() => setInbox(true)}>
                                        Bandeja de entrada
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={() => setInbox(false)}>
                                        Bandeja de salida
                                    </button>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <Row>
                                    <Col>
                                        <h3>Reportar de forma anónima un caso de acoso</h3>
                                        <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="subject" type="text" placeholder="Asunto" onChange={ e =>setSubjectStopbullying(e.target.value)}/>
                                        <textarea className="form-control mb-3" rows="5" data-kt-element="input" placeholder="Describa lo ocurrido" onChange={(e) => setNewStopbullying(e.target.value)}></textarea>
                                        <input type="file" accept="image/*" onChange={handleNewFileChange} multiple />
                                        <ul>
                                            {newfiles.map((file, i) => (
                                            <li key={i}>
                                                {file.name}
                                            </li>
                                            ))}
                                        </ul>
                                        <button type="button" className="btn btn-primary" onClick={() => createStopbullyingHandler()}>
                                            Enviar
                                        </button>
                                    </Col>
                                </Row>
                                <hr/>
                                {inbox
                                    ?
                                        <>
                                            Mensajes en bandeja de entrada
                                            <CustomDataTable data={stopbullyingsIn} columns={columnsIn} onRowClicked={(row) => setStopbullyingsResume({type: 'inbox', _id: row._id})}/>
                                        </>
                                    :
                                        <>
                                            Mensajes en bandeja de salida
                                            <CustomDataTable data={stopbullyingsOut} columns={columnsOut} onRowClicked={(row) => setStopbullyingsResume({type: 'outbox', _id: row._id})}/>
                                        </>
                                }
                                {stopbullyingsShow
                                    ?
                                        <>
                                        <hr/>
                                            <Row>
                                                <Col className="text-start">
                                                    <b>Administración</b>
                                                </Col>
                                                <Col className="text-center"><h3>Asunto:</h3> {stopbullyingsShow.subject}</Col>
                                                <Col className="text-end">
                                                    <h3>Hora - Fecha:</h3> {new Date(stopbullyingsShow.date)?.toTimeString().substring(0, 5)+'  -  '+stopbullyingsShow.date?.substr(8,2)+'/'+stopbullyingsShow.date?.substr(5,2)+'/'+stopbullyingsShow.date?.substr(0,4)}</Col>   
                                            </Row>
                                            {inbox
                                                ?
                                                    <>
                                                        <hr/>
                                                        <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">     
                                                            {sendingStopbullying ? (<LoadingBox variant={"primary"}/>)
                                                            :
                                                            (<span className="indicator-label">Enviar respuesta</span>)}
                                                        </button>
                                                        <textarea className="form-control mb-3" rows="5" data-kt-element="input" onChange={(e) => setStopbullying(e.target.value)}></textarea>
                                                        <input type="file" onChange={handleFileChange} multiple />
                                                        <ul>
                                                            {files.map((file, i) => (
                                                            <li key={i}>
                                                                {file.name}
                                                            </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                :
                                                    <></>
                                            }
                                            <Row>
                                                <Col className="text-start p-4">
                                                    <p dangerouslySetInnerHTML={{__html: lastestStopbullyings}}></p>
                                                </Col>
                                            </Row>
                                        </>
                                    :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
