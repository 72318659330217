import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AlertMessage } from '../components/AlertMessage';
import Swal from 'sweetalert2';
import { createPaymentMethod, listPaymentMethods } from '../actions/paymentMethodActions';
import { PAYMENTMETHOD_CREATE_RESET } from '../constants/paymentMethodConstants';

export const AddPaymentMethod = ({hideSubmitBtn = false, innerRef = null}) => {
	const dispatch = useDispatch();

	const paymentmethodCreate = useSelector((state) => state.paymentmethodCreate);
	const {loading: loadingCreate, error: errorCreate, success: successCreate, paymentmethod: createdPaymentMethod } = paymentmethodCreate;

	const [name, setName] = useState()
	const [number, setNumber] = useState()
	const [cvc, setCvc] = useState()
	const [month, setMonth] = useState()
	const [year, setYear] = useState()

	const submitHandler = (e) => {
		e.preventDefault()
		let numberSplitted = number?.split(' ')
		let numberFormated = ''
		for (let index = 0; index < 4; index++) {
			numberFormated += numberSplitted[index]
		}
		dispatch(createPaymentMethod({
			number: numberFormated,
			exp_month: month,
			exp_year: year,
			cvc: cvc,
			name: name
		}))
	}

	const changeNumber = (e) => {
		setNumber(e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim())
	}

	useEffect(() => {
		if(successCreate) {
			if (!hideSubmitBtn) {
				document.getElementById('closeModal').click()
				Swal.fire({
					position: 'center',
					icon: 'success',
					title: 'Tarjeta creada correctamente',
					showConfirmButton: false,
					timer: 1500
				})
			dispatch({ type: PAYMENTMETHOD_CREATE_RESET});
			}
			dispatch(listPaymentMethods());
		}
	}, [dispatch, createdPaymentMethod, successCreate])



	return (
		<div className="modal-body">
			<form autoComplete='off' className="form w-100 row" id="kt_sign_in_form" onSubmit={submitHandler}>
				<div className="col-12 mb-5">
					<label className="form-label fs-6 fw-bolder text-dark"><span className="required">Nombre</span></label>
					<input type="text" className="form-control form-control-lg form-control-solid" placeholder="Nombre" value={name || ''} required onChange={ e =>setName(e.target.value)}/>
				</div>
				<div className={`col-12 mb-5 ${hideSubmitBtn ? '' : 'col-md-6'}`}>
					<label className="form-label fs-6 fw-bolder text-dark"><span className="required">Número</span></label>
					<input type="text" className="form-control form-control-lg form-control-solid" maxLength={19} placeholder="Numero de la tarjeta" pattern='[0-9][0-9][0-9][0-9]\s[0-9][0-9][0-9][0-9]\s[0-9][0-9][0-9][0-9]\s[0-9][0-9][0-9][0-9]' title='NNNNNNNNNNNNNNNN' value={number || ''} required onChange={ e => changeNumber(e)}/>
				</div>
				<div className={`col-12 mb-5 ${hideSubmitBtn ? '' : 'col-md-6'}`}>
					<label className="form-label fs-6 fw-bolder text-dark"><span className="required">CVC</span></label>
					<input type="text" className="form-control form-control-lg form-control-solid" maxLength={3} placeholder="NNN" value={cvc || ''} pattern='[0-9]{3}' title='NNN' required onChange={ e =>setCvc(e.target.value)}/>
				</div>
				<div className="col-12 col-md-6 mb-5">
					<label className="form-label fs-6 fw-bolder text-dark"><span className="required">Mes</span></label>
					<input type="text" className="form-control form-control-lg form-control-solid" maxLength={2} placeholder="MM" value={month || ''} title='MM' required onChange={ e =>setMonth(e.target.value)}/>
				</div>
				<div className="col-12 col-md-6 mb-5">
					<label className="form-label fs-6 fw-bolder text-dark"><span className="required">Año</span></label>
					<input type="text" className="form-control form-control-lg form-control-solid" maxLength={2} placeholder="YY" value={year || ''}  title='YY' required onChange={ e =>setYear(e.target.value)}/>
				</div>
				{ errorCreate ? (
					<AlertMessage variant={"danger"} message={errorCreate}/>
				) : ''}
				<div className={`text-end ${hideSubmitBtn ? 'd-none' : ''}`}>
					<button type="submit" id="kt_sign_in_submit" className="btn btn-primary me-1" ref={innerRef}>
						{
							loadingCreate ? (
								<span className="indicator-progress">Espere por favor...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
							) :
								(<span className="indicator-label">Crear</span>)
						}
					</button>
					<button type="button" id="closeModal" className="btn btn-secondary ms-1" data-bs-dismiss="modal">Cerrar</button>
				</div>
			</form>
		</div>
	)
}
