import Axios from 'axios';
import { ATTRIBUTE_CREATE_FAIL, ATTRIBUTE_CREATE_REQUEST, ATTRIBUTE_CREATE_SUCCESS, ATTRIBUTE_DELETE_FAIL, ATTRIBUTE_DELETE_REQUEST, ATTRIBUTE_DELETE_SUCCESS, ATTRIBUTE_DETAILS_FAIL, ATTRIBUTE_DETAILS_REQUEST, ATTRIBUTE_DETAILS_SUCCESS, ATTRIBUTE_LIST_FAIL, ATTRIBUTE_LIST_REQUEST, ATTRIBUTE_LIST_SUCCESS, ATTRIBUTE_UPDATE_FAIL, ATTRIBUTE_UPDATE_REQUEST, ATTRIBUTE_UPDATE_SUCCESS } from '../constants/attributeConstants';

export const listAttributes = () => async (dispatch) => {
    dispatch({ type: ATTRIBUTE_LIST_REQUEST });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/attributes');
        dispatch({ type: ATTRIBUTE_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ATTRIBUTE_LIST_FAIL, payload: error.message });
    }
}

export const detailsAttribute = (attributeId) => async (dispatch) => {
    dispatch({ type: ATTRIBUTE_DETAILS_REQUEST, payload: attributeId });

    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/attributes/${attributeId}`);
        dispatch({ type: ATTRIBUTE_DETAILS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: ATTRIBUTE_DETAILS_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const updateAttribute = (attribute) => async (dispatch, getState) => {
    dispatch({ type: ATTRIBUTE_UPDATE_REQUEST, payload: attribute });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/attributes/${attribute._id}`, attribute, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ATTRIBUTE_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message?
                error.response.data.message:
                error.message;
        dispatch({ type: ATTRIBUTE_UPDATE_FAIL, error: message });
    }
};

export const createAttribute = (attribute) => async (dispatch, getState) => {
    dispatch({ type: ATTRIBUTE_CREATE_REQUEST });

    const { userSignin: { userInfo } } = getState();

    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/attributes', attribute, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({ type: ATTRIBUTE_CREATE_SUCCESS, payload: data.attribute })
    } catch (error) {
        dispatch({
            type: ATTRIBUTE_CREATE_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const deleteAttribute = (attributeId) => async (dispatch, getState) => {
    dispatch({ type: ATTRIBUTE_DELETE_REQUEST, payload: attributeId });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        await Axios.delete(process.env.REACT_APP_DB_ADDRESS + `/api/attributes/${attributeId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: ATTRIBUTE_DELETE_SUCCESS });
    } catch (error) {
        const message =
            error.response && error.response.data
                ? error.response.data
                : error.message;
        dispatch({ type: ATTRIBUTE_DELETE_FAIL, payload: message });
    }
};