import Axios from 'axios'
import { STUDENTS_IMPORT_FAIL, STUDENTS_IMPORT_REQUEST, STUDENTS_IMPORT_SUCCESS, STUDENT_DELETE_FAIL, STUDENT_DELETE_REQUEST, STUDENT_DELETE_SUCCESS, STUDENT_DETAILS_FAIL, STUDENT_DETAILS_REQUEST, STUDENT_DETAILS_SUCCESS, STUDENT_LIST_FAIL, STUDENT_LIST_REQUEST, STUDENT_LIST_SUCCESS, STUDENT_REGISTER_FAIL, STUDENT_REGISTER_REQUEST, STUDENT_REGISTER_SUCCESS, STUDENT_UPDATE_FAIL, STUDENT_UPDATE_REQUEST, STUDENT_UPDATE_SUCCESS } from '../constants/studentConstants';


export const registerStudent = (name, code, emergencyPhone, selectedCourse, selectedUsers) => async (dispatch) => {
    dispatch({ type: STUDENT_REGISTER_REQUEST, payload: { name, code, emergencyPhone, selectedCourse, selectedUsers } });
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/students/register', { name, code, emergencyPhone, selectedCourse, selectedUsers });
        dispatch({ type: STUDENT_REGISTER_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: STUDENT_REGISTER_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message
        })
    }
}

export const detailsStudent = (studentId) => async (dispatch, getState) => {
    dispatch({ type: STUDENT_DETAILS_REQUEST, payload: studentId });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/students/${studentId}`, {
            headers: { Authorization: `Bearer ${userInfo?.token}` },
        });
        dispatch({ type: STUDENT_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data
                ? error.response.data
                : error.message;
        dispatch({ type: STUDENT_DETAILS_FAIL, payload: message });
    }
};


// export const updateUserProfile = (student) => async (dispatch, getState) => {
//     dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: student });
//     const { userSignin: { userInfo } } = getState();
//     try {
//         const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/users/profile`, student, {
//             headers: { Authorization: `Bearer ${userInfo.token}` }
//         });
//         dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
//         dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
//         sessionStorage.setItem('userInfo', JSON.stringify(data));

//     } catch (error) {
//         const message = error.response && error.response.data.message? error.response.data.message: error.message;
//         dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message })
//     }
// }

export const listStudents = () => async (dispatch, getState) => {
    dispatch({ type: STUDENT_LIST_REQUEST });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/students`, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: STUDENT_LIST_SUCCESS, payload: data });
    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: STUDENT_LIST_FAIL, payload: message })
    }
}

export const deleteStudent = (studentId) => async (dispatch, getState) => {
    dispatch({ type: STUDENT_DELETE_REQUEST, payload: studentId })
    const { userSignin: { userInfo } } = getState();

    try {
        const { data } = await Axios.delete(process.env.REACT_APP_DB_ADDRESS + `/api/students/${studentId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });
        dispatch({ type: STUDENT_DELETE_SUCCESS, payload: data })
    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: STUDENT_DELETE_FAIL, payload: message })
    }
}

export const updateStudent = (student) => async (dispatch, getState) => {
    dispatch({ type: STUDENT_UPDATE_REQUEST, payload: student });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/students/${student._id}`, student, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });        
        dispatch({ type: STUDENT_UPDATE_SUCCESS, payload: data });

    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: STUDENT_UPDATE_FAIL, payload: message })
    }
}

/**
 * Importa los estudiantes desde CSV
 * @param {*} students 
 * @returns 
 */
export const importStudents = (students) => async (dispatch, getState) => {
    dispatch({ type: STUDENTS_IMPORT_REQUEST, payload: students });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + `/api/students/import`, {students}, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });        
        dispatch({ type: STUDENTS_IMPORT_SUCCESS, payload: data });

    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: STUDENTS_IMPORT_FAIL, payload: message })
    }
}

/**
 * Promociona los estudiantes de curso desde CSV
 * @param {*} students 
 * @returns 
 */
 export const promoteStudents = (students) => async (dispatch, getState) => {
    dispatch({ type: STUDENTS_IMPORT_REQUEST, payload: students });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + `/api/students/promote`, {students}, {
            headers: { Authorization: `Bearer ${userInfo.token}` }
        });        
        dispatch({ type: STUDENTS_IMPORT_SUCCESS, payload: data });

    } catch (error) {
        const message = error.response && error.response.data.message? error.response.data.message: error.message;
        dispatch({ type: STUDENTS_IMPORT_FAIL, payload: message })
    }
}