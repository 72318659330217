export const SUBSCRIPTIONPRODUCT_LIST_REQUEST = 'SUBSCRIPTIONPRODUCT_LIST_REQUEST';
export const SUBSCRIPTIONPRODUCT_LIST_SUCCESS = 'SUBSCRIPTIONPRODUCT_LIST_SUCCESS';
export const SUBSCRIPTIONPRODUCT_LIST_FAIL = 'SUBSCRIPTIONPRODUCT_LIST_FAIL';

export const SUBSCRIPTIONPRODUCT_DETAILS_REQUEST = 'SUBSCRIPTIONPRODUCT_DETAILS_REQUEST';
export const SUBSCRIPTIONPRODUCT_DETAILS_SUCCESS = 'SUBSCRIPTIONPRODUCT_DETAILS_SUCCESS';
export const SUBSCRIPTIONPRODUCT_DETAILS_FAIL = 'SUBSCRIPTIONPRODUCT_DETAILS_FAIL';

export const SUBSCRIPTIONPRODUCT_CREATE_REQUEST = 'SUBSCRIPTIONPRODUCT_CREATE_REQUEST';
export const SUBSCRIPTIONPRODUCT_CREATE_SUCCESS = 'SUBSCRIPTIONPRODUCT_CREATE_SUCCESS';
export const SUBSCRIPTIONPRODUCT_CREATE_FAIL = 'SUBSCRIPTIONPRODUCT_CREATE_FAIL';
export const SUBSCRIPTIONPRODUCT_CREATE_RESET = 'SUBSCRIPTIONPRODUCT_CREATE_RESET';

export const SUBSCRIPTIONPRODUCT_DELETE_REQUEST = 'SUBSCRIPTIONPRODUCT_DELETE_REQUEST';
export const SUBSCRIPTIONPRODUCT_DELETE_SUCCESS = 'SUBSCRIPTIONPRODUCT_DELETE_SUCCESS';
export const SUBSCRIPTIONPRODUCT_DELETE_FAIL = 'SUBSCRIPTIONPRODUCT_DELETE_FAIL';
export const SUBSCRIPTIONPRODUCT_DELETE_RESET = 'SUBSCRIPTIONPRODUCT_DELETE_RESET';

export const SUBSCRIPTIONPRODUCT_UPDATE_REQUEST = 'SUBSCRIPTIONPRODUCT_UPDATE_REQUEST';
export const SUBSCRIPTIONPRODUCT_UPDATE_SUCCESS = 'SUBSCRIPTIONPRODUCT_UPDATE_SUCCESS';
export const SUBSCRIPTIONPRODUCT_UPDATE_FAIL = 'SUBSCRIPTIONPRODUCT_UPDATE_FAIL';
export const SUBSCRIPTIONPRODUCT_UPDATE_RESET = 'SUBSCRIPTIONPRODUCT_UPDATE_RESET';

export const SUBSCRIPTIONPRODUCT_DUPLICATE_REQUEST = 'SUBSCRIPTIONPRODUCT_DUPLICATE_REQUEST'
export const SUBSCRIPTIONPRODUCT_DUPLICATE_FAIL = 'SUBSCRIPTIONPRODUCT_DUPLICATE_FAIL'
export const SUBSCRIPTIONPRODUCT_DUPLICATE_SUCCESS = 'SUBSCRIPTIONPRODUCT_DUPLICATE_SUCCESS'
export const SUBSCRIPTIONPRODUCT_DUPLICATE_RESET = 'SUBSCRIPTIONPRODUCT_DUPLICATE_RESET'