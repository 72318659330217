import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar';
import Swal from 'sweetalert2';
import { CustomDataTable } from '../components/CustomDataTable';
import Select from "react-select"
import { listCourses } from '../actions/courseActions';
import { deleteStudent, listStudents, registerStudent } from '../actions/studentActions';
import { STUDENT_DETAILS_RESET, STUDENT_REGISTER_RESET } from '../constants/studentConstants';
import { listUsers } from '../actions/userActions';
import { getNotificationsUser } from '../actions/notificationActions';
import removeAccents from '../utils/removeAccents';


export const StudentListScreen = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const studentList = useSelector(state => state.studentList);
    const { loading, error, students } = studentList;

    const userList = useSelector(state => state.userList);
    const { loading: loadingUsers, error: errorUsers, users } = userList;
    
    const [name, setName] = useState('')
    const [code, setCode] = useState('')

    // Emergency phone options
    const [emergencyPhoneOptions, setEmergencyPhoneOptions] = useState([])
    const [selectedEmergencyPhoneOptions, setSelectedEmergencyPhoneOptions] = useState([])


    const { loading: loadingCreate, error: errorCreate, success: successCreate } = useSelector((state) => state.registerStudent);

    const columns = [
        {
            name: "NOMBRE DEL ALUMNO",
            selector: (row) => removeAccents(row.name),
            sortable: true,
            grow: 1.5,
            cell: row => <span> {row.name}</span>
        },
        {
            name: "CODIGO",
            selector: (row) => removeAccents(row.code),
            sortable: true,
            cell: row => <span> {row.code}</span>
        },
        {
            name: "CURSO",
            selector: (row) => removeAccents(row.course?.name),
            sortable: true,
            cell: row => <span> {row.course && (row.course.name)}</span>
        },
        {
            name: "TLF. EMERGENCIAS",
            selector: (row) => row.emergencyPhone,
            cell: row => <span>{row.emergencyPhone}</span>,
            sortable: true
        },
        {
            name: "",
            cell: row =>
            <div className="row">
                <div className="col-12">
                    <i className="bi bi-pencil px-2" onClick={() => navigate(`/student/${row._id}/edit`)} style={{fontSize: '1.4rem', cursor: 'pointer'}}></i>
                    <i className="bi bi-trash-fill px-2" onClick={() => deleteHandler(row)} style={{fontSize: '1.4rem', cursor: 'pointer'}}></i>
                </div>
            </div>,
            center: true,

        }
    ];

    // Select de Usuarios 
    // const options = []
    const [usersOptions, setUsersOptions] = useState([])
    let selectedUsers = [];
    const [selectedOptions, setSelectedOptions] = useState([])

    const handleChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions)
    }

    // Select de Cursos
    const courseList = useSelector((state) => state.courseList);
    const { loading: loadingCourses, error: errorCourses, courses } = courseList;

    let selectedCourse = null;
    // const courseOptions = []
    const [courseOptions, setCourseOptions] = useState([])
    const [selectedCourseOptions, setSelectedCourseOptions] = useState([])


    const handleCourseChange = (selectedCourseOptions) => {
        setSelectedCourseOptions(selectedCourseOptions)
    }

    const userDelete = useSelector(state => state.userDelete);
    const { loading: loadingDelete, error: errorDelete, success: successDelete } = userDelete;

    useEffect(() => {
        dispatch(listStudents());
        dispatch({ type: STUDENT_DETAILS_RESET });
        dispatch(listCourses())
        dispatch(listUsers())
        dispatch(getNotificationsUser());
    }, [dispatch, successDelete])


    useEffect(() => {
        if (users) {
            setUsersOptions([])
            users.map((usuario) => (
                setUsersOptions((usersOptions) => [...usersOptions, { value: usuario._id, label: usuario.name }])
            ))  
        }

        if (courses) {         
            setCourseOptions([])   
            courses.map((course) => (
                setCourseOptions((courseOptions) => [...courseOptions, { value: course._id, label: course.name }])            
            ))
        }

    }, [users, courses])

    useEffect(()=>{
        let options = []
        selectedOptions.forEach(element => {
            let parent = users?.find(us => us._id === element?.value)
            if (parent) {
                options.push({value: parent.phone, label: `${parent.phone} - ${parent.name}`})
            }
        })
        setEmergencyPhoneOptions(options)
    }, [ selectedOptions, users])

    useEffect(() => {
        if (errorCreate) {
            dispatch({type: STUDENT_REGISTER_RESET})
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: errorCreate,
                showConfirmButton: false,
                timer: 1500
            })
        }
    }, [errorCreate, dispatch])

    useEffect(() => {
        if (successCreate) {
            dispatch({type: STUDENT_REGISTER_RESET})
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Alumno creado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }, [dispatch, successCreate])


    const deleteHandler = (student) => {
        Swal.fire({
            title: `¿Desea eliminar al estudiante ${student.name} ?`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
            cancelButtonText: "Cancelar",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteStudent(student._id));
                dispatch(listStudents())
                Swal.fire('Usuario eliminado', '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    const submitHandler = (e) => {
        e.preventDefault();

        if (name !== '') {
            if (code !== '') {
                if (selectedOptions.length > 0) {
                    if (selectedCourseOptions) {
                        // Añadir objetos de hijos al padre
                        for (const user of users) {
                            for (const selectedOption of selectedOptions) {
                                if (user._id === selectedOption.value) {
                                    selectedUsers.push(user)
                                }
                            }
                        }

                        // Añadir cursos al estudiante
                        if (selectedCourseOptions !== null) {
                            selectedCourse = courses.find(course => course._id === selectedCourseOptions.value)
                        } else {
                            selectedCourse = '';
                        }
                        dispatch(registerStudent(name, code, selectedEmergencyPhoneOptions.value, selectedCourse, selectedUsers));
                        // Reseteamos los campos
                        dispatch(listStudents());
                        setName('');
                        setCode('');
                        setEmergencyPhoneOptions([]);
                        setSelectedCourseOptions([]);
                        setSelectedOptions([]);
                        setSelectedEmergencyPhoneOptions('')
                        

                        document.getElementById('closeModal').click()
                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonText: 'De acuerdo',
                            title: 'Debes asignar un curso al alumno'
                        })
                    }
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: 'De acuerdo',
                        title: 'Debes seleccionar al menos un padre al alumno'
                    })
                }
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonText: 'De acuerdo',
                    title: 'El alumno debe tener un código único'
                })
            }
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonText: 'De acuerdo',
                title: 'El alumno debe tener un nombre'
            })
        }
    }

    const handleEmergencyPhoneChange = (selectedPhoneOptions) => {
        setSelectedEmergencyPhoneOptions(selectedPhoneOptions)
    }

    return (
        <>
            <ToolBar titulo={"Lista de alumnos"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Lista de alumnos", enlace: "#" }]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">

                    {loadingDelete && <LoadingBox variant={"primary"} />}
                    {errorDelete && <AlertMessage variant={"danger"} message={errorDelete} />}
                    {
                        loading ? (<LoadingBox variant={"primary"} />) :
                            error ? (<AlertMessage variant={"danger"} message={error} />) :
                                (
                                    <div className="card card-flush">
                                        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                            <div className="card-title">
                                            </div>
                                            <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                    Crear alumno
                                                </button>
                                            </div>
                                        </div>
                                        <div className="card-body pt-0">
                                            <CustomDataTable data={students} columns={columns} />
                                        </div>
                                    </div>
                                )
                    }
                </div>
            </div>
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Crear estudiante</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {loadingCreate ? <LoadingBox /> :
                                <form autoComplete='off' className="form w-100 row" id="kt_sign_in_form" onSubmit={submitHandler}>
                                    <h3 className="fw-bolder mx-0 mb-5">Datos generales</h3>
                                    <div className="col-12 mb-5">
                                        <label className="form-label fs-6 fw-bolder text-dark"><span className="required">Apellidos, Nombre</span></label>
                                        <input className="form-control form-control-lg form-control-solid" placeholder="Nombre" value={name} required onChange={e => setName(e.target.value)} />
                                    </div>
                                    

                                    <div className="col-12 col-md-6 mb-5">
                                        <label className="form-label fs-6 fw-bolder text-dark required">Código</label>
                                        <input className="form-control form-control-lg form-control-solid" placeholder="Código" value={code} onChange={e => setCode(e.target.value)} />
                                    </div>
                                    
                                    <div className="col-12 col-md-6 mb-5">
                                        <label className="form-label fs-6 fw-bolder text-dark required">Padres</label>
                                        {loadingUsers ? (<LoadingBox variant={"primary"} />) :
                                            errorUsers ? (<AlertMessage variant={"danger"} message={errorUsers} />) : (
                                                <Select isMulti isClearable options={usersOptions} onChange={handleChange} value={selectedOptions} placeholder="Escoja los padres del estudiante" />
                                            )}
                                    </div>
                                    <div className="col-12 col-md-6 mb-5">
                                        <label className="form-label fs-6 fw-bolder text-dark required">Curso escolar</label>
                                        {loadingCourses ? (<LoadingBox variant={"primary"} />) :
                                            errorCourses ? (<AlertMessage variant={"danger"} message={errorCourses} />) : (
                                                <Select isClearable options={courseOptions} onChange={handleCourseChange} value={selectedCourseOptions} placeholder="Curso escolar del estudiante" />
                                            )}
                                    </div>

                                    <div className="col-12 col-md-6 mb-5">
                                        <label className="form-label fs-6 fw-bolder text-dark">Teléfono de emergencias</label>
                                        <Select isClearable options={emergencyPhoneOptions} value={selectedEmergencyPhoneOptions} onChange={handleEmergencyPhoneChange}  placeholder="Teléfono de emergencias del alumno" />
                                    </div>

                                    <div className="text-end">
                                        <button type="submit" id="kt_sign_in_submit" className="btn btn-primary me-1">
                                            {
                                                loading ? (
                                                    <span className="indicator-progress">Espere por favor...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                ) :
                                                    (<span className="indicator-label">Crear</span>)
                                            }
                                        </button>
                                        <button type="button" id="closeModal" className="btn btn-secondary ms-1" data-bs-dismiss="modal">Cerrar</button>

                                    </div>
                                    {errorCreate ? (
                                        <AlertMessage variant={"danger"} message={errorCreate} />
                                    ) : ''}

                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
