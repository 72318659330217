import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createCategory, listCategories } from '../actions/categoryActions';
import { ToolBar } from '../components/ToolBar'
import Select from "react-select"
import { LoadingBox } from '../components/LoadingBox';
import { AlertMessage } from '../components/AlertMessage';

export const CreateCategoryScreen = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [parents, setParents] = useState({options: [], value: null})
    const [show, setShow] = useState(false)

    // const categoryDetails = useSelector(state => state.categoryDetails);
    // const { loading, error, category } = categoryDetails;
    const categoryCreated = useSelector(state => state.categoryCreate)
    const { loading, error, categories } = useSelector((state) => state.categorieList);

    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault();
        if(name !== ''){
            dispatch(createCategory({
                name,
                description,
                show,
                parent: parents.value?.value || null
            }))
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Introduzca un nombre a la categoría',
                showConfirmButton: true,
                confirmButtonText: 'De acuerdo'
            })
        }
    }

    useEffect(() => {
        if (categories) {
            let categoriesOptions = categories.map(cat => (
                {label: cat.name, value: cat._id}
            ))
            setParents({options: categoriesOptions, value: null})
        }
    }, [categories])

    useEffect(()=>{
        if (categoryCreated?.category) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Categoría creada correctamente',
                showConfirmButton: false,
                timer: 1500
            })
            navigate(`/categorie/${categoryCreated.category._id}/edit`)
        }
    }, [categoryCreated, navigate])

    useEffect(() => {
        dispatch(listCategories());
    }, [dispatch])

    return (
        <>
            <ToolBar titulo={"Editar categoría"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Editar categoría", enlace: "#" },
            ]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <form autoComplete='off' id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row" onSubmit={submitHandler}>
                        <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                            <div className="card card-flush py-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h2>Estado</h2>
                                    </div>
                                    <div className="card-toolbar">
                                    <div className={`rounded-circle ${show === 'true' ? 'bg-success' : 'bg-danger'} w-15px h-15px`} id="kt_ecommerce_add_product_status" />
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <select className="form-select" value ={show} onChange={e => setShow(e.target.value)}>
                                        <option value={true}>Mostrada</option>
                                        <option value={false}>Oculta</option>
                                    </select>
                                    <div className="text-muted fs-7">Mostrar/Ocultar categoría.</div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="kt_ecommerce_add_product_general">
                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>General</h2>
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="mb-10 fv-row">
                                                    <label className="required form-label">Nombre de la categoría</label>
                                                    <input className="form-control mb-2" id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                                    <div className="text-muted fs-7">Se requiere un nombre de categoría que sea único.</div>
                                                </div>
                                                <div className="mb-10 fv-row">
                                                    <label className="form-label">Descripción</label>
                                                    <textarea className="form-control mb-3" rows="5" data-kt-element="input" placeholder="Escribe una descripción" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                                </div>
                                                {
                                                    loading ? (<LoadingBox variant={"primary"} />) :
                                                    error ? <AlertMessage variant={"danger"} message={error} /> :
                                                    <div className="mb-10 fv-row">
                                                        <label className="required form-label">Categoía padre</label>
                                                            <Select isClearable options={parents.options} onChange={(selectedValue) => setParents({...parents, value: selectedValue})} value={parents.value} placeholder="Escoja la categoría padre" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Link to="/categorylist" id="kt_ecommerce_add_product_cancel" className="btn btn-light me-5">Cancelar</Link>
                                <button type="submit" id="kt_ecommerce_add_product_submit" className="btn btn-primary">
                                    <span className="indicator-label">Guardar</span>
                                    <span className="indicator-progress">Espere por favor...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
