import { STOPBULLYING_GET_ALL, STOPBULLYING_GET_USER, STOPBULLYING_READ } from "../constants/stopbullyingConstants";

export const stopbullyingReducer = (state = [], action) => {
    switch (action.type) {
        case STOPBULLYING_GET_ALL:
            return action.payload.data
        case STOPBULLYING_GET_USER:
            return action.payload.data
        case STOPBULLYING_READ:
            return state.map((stopbullying) => {
                if (stopbullying._id === action.payload.stopbullying._id) {
                    return {...stopbullying, read: true}
                } else {
                    return stopbullying
                }
            })
        default:
            return state;
    }
}