import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { deleteCategory, listCategories } from '../actions/categoryActions';
import { AlertMessage } from '../components/AlertMessage';
import { CustomDataTable } from '../components/CustomDataTable';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar'
import Swal from 'sweetalert2';
import { CATEGORIE_CREATE_RESET, CATEGORIE_DELETE_RESET } from '../constants/categorieConstants';
import { getNotificationsUser } from '../actions/notificationActions';
import removeAccents from '../utils/removeAccents';

export const CategoryListScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const categorieList = useSelector((state) => state.categorieList);
    const { loading, error, categories } = categorieList;

    const categoryCreate = useSelector((state) => state.categoryCreate);
    const {loading: loadingCreate, error: errorCreate, success: successCreate, category: createdCategory } = categoryCreate;
  
    const categoryDelete = useSelector((state) => state.categoryDelete);
    const { loading: loadingDelete, error: errorDelete, success: successDelete } = categoryDelete;

    const columns = [
        {
            name: 'Categoría',
            cell: row =>
                <div className="d-flex align-items-center">
                    
                    <div className="">
                        <a href={`/categorie/${row._id}/edit`} className="text-gray-800 text-hover-primary fs-5 fw-bolder" data-kt-ecommerce-product-filter="categorie_name">{row.name}</a>
                    </div>
                </div>,
            selector: (row) => removeAccents(row.name),
            sortable: true,
            grow: 2,
        },

        {
            name: "Descripción",
            cell: row =>
                <span>{row.description}</span>
            ,
            selector: (row) => removeAccents(row.description),
            sortable: true,
        },
        {
            name: "Categoría padre",
            cell: row =>
                <span>{categories?.find(c => c._id === row.parent)?.name}</span>
            ,
            selector: (row) => removeAccents(categories?.find(c => c._id === row.parent)?.name),
            sortable: true,
        },
        {
            name: "Estado",
            selector: (row) => removeAccents(row.show),
            cell: row => row.show ? (<div className="rounded-circle bg-success w-15px h-15px" id="kt_ecommerce_add_product_status"></div>) : <div className="rounded-circle bg-danger w-15px h-15px" id="kt_ecommerce_add_product_status"></div>,
            sortable: true,
            right: true
        },
        {
            name: "",
            cell: row =>
            <div className="row">
                <div className="col-12">
                <i className="bi bi-pencil px-2" onClick={() => navigate(`/categorie/${row._id}/edit`)} style={{fontSize: '1.4rem', cursor: 'pointer'}}></i>
                <i className="bi bi-trash-fill px-2" onClick={() => deleteHandler(row)} style={{fontSize: '1.4rem', cursor: 'pointer'}}></i>
                </div>
            </div>,
                // <div className="dropdown dropup">
                //     <button className="btn btn-sm btn-light btn-active-light-primary" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                //         Acciones
                //         <span className="svg-icon svg-icon-5 m-0">
                //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                //                 <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                //             </svg>
                //         </span>
                //     </button>
                //     <ul className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" aria-labelledby="dropdownMenuButton1">
                //         <li className="menu-item px-3"><button className="dropdown-item menu-link px-3" onClick={() => navigate(`/categorie/${row._id}/edit`)} >Editar</button></li>
                //         <li className="menu-item px-3"><button className="dropdown-item menu-link px-3" onClick={() => deleteHandler(row)} >Eliminar</button></li>
                //     </ul>
                // </div>,
            right: true,
            sortable: false
        }
    ];

    const deleteHandler = (category) => {
        Swal.fire({
            title: `¿Desea eliminar la categoría ${category.name} ?`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
            cancelButtonText: "Cancelar",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCategory(category._id));
                Swal.fire('Categoría eliminada', '', 'success')
            }
        })
    }

    useEffect(() => {
        if(successCreate) {
        dispatch({ type: CATEGORIE_CREATE_RESET});
        navigate(`/product/${createdCategory._id}/edit`)
        }
        if (successDelete) {
            dispatch({ type: CATEGORIE_DELETE_RESET });
        }
        dispatch(listCategories());
        dispatch(getNotificationsUser());
    }, [dispatch, createdCategory, navigate, successCreate, successDelete])



    return (
        <>
            <ToolBar titulo={"Categorías"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Categorías", enlace: "#" },
            ]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    {loadingDelete && (<LoadingBox variant={"primary"}/>)}
                    {errorDelete && (<AlertMessage variant={"danger"} message={errorDelete}/>)}
                    {loadingCreate && (<LoadingBox variant={"primary"}/>)}
                    {errorCreate && (<AlertMessage variant={"danger"} message={errorCreate}/>)}
                    {loading ? (<LoadingBox variant={"primary"} />) :
                        error ? (<AlertMessage variant={"danger"} message={error} />) : (
                            <div className="card card-flush">
                                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                                        <Link to="/categorie/new" className="btn btn-primary" >Crear categoría</Link>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <CustomDataTable data={categories} columns={columns} />
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    )
}
