const { SUBSCRIPTIONPRODUCT_DETAILS_SUCCESS, SUBSCRIPTIONPRODUCT_DETAILS_FAIL, SUBSCRIPTIONPRODUCT_LIST_REQUEST, SUBSCRIPTIONPRODUCT_DETAILS_REQUEST, SUBSCRIPTIONPRODUCT_CREATE_REQUEST, SUBSCRIPTIONPRODUCT_CREATE_SUCCESS, SUBSCRIPTIONPRODUCT_CREATE_FAIL, SUBSCRIPTIONPRODUCT_CREATE_RESET, SUBSCRIPTIONPRODUCT_UPDATE_REQUEST, SUBSCRIPTIONPRODUCT_UPDATE_SUCCESS, SUBSCRIPTIONPRODUCT_UPDATE_FAIL, SUBSCRIPTIONPRODUCT_UPDATE_RESET, SUBSCRIPTIONPRODUCT_DELETE_REQUEST, SUBSCRIPTIONPRODUCT_DELETE_SUCCESS, SUBSCRIPTIONPRODUCT_DELETE_FAIL, SUBSCRIPTIONPRODUCT_DELETE_RESET, SUBSCRIPTIONPRODUCT_DUPLICATE_REQUEST, SUBSCRIPTIONPRODUCT_DUPLICATE_FAIL, SUBSCRIPTIONPRODUCT_DUPLICATE_SUCCESS, SUBSCRIPTIONPRODUCT_DUPLICATE_RESET } = require('../constants/subscriptionProductConstants');

const { SUBSCRIPTIONPRODUCT_LIST_SUCCESS, SUBSCRIPTIONPRODUCT_LIST_FAIL } = require('../constants/subscriptionProductConstants')

export const subscriptionProductListReducer = (
    state = { subscriptionProducts: [], },
    action
) => {
    switch (action.type) {
        case SUBSCRIPTIONPRODUCT_LIST_REQUEST:
            return { loading: true };
        case SUBSCRIPTIONPRODUCT_LIST_SUCCESS:
            return { loading: false, subscriptionProducts: action.payload };
        case SUBSCRIPTIONPRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const subscriptionProductDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case SUBSCRIPTIONPRODUCT_DETAILS_REQUEST:
            return { loading: true };
        case SUBSCRIPTIONPRODUCT_DETAILS_SUCCESS:
            return { loading: false, subscriptionProduct: action.payload };
        case SUBSCRIPTIONPRODUCT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}


export const subscriptionProductCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBSCRIPTIONPRODUCT_CREATE_REQUEST:
            return { loading: true };
        case SUBSCRIPTIONPRODUCT_CREATE_SUCCESS:
            return { loading: false, success: true, subscriptionProduct: action.payload };
        case SUBSCRIPTIONPRODUCT_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case SUBSCRIPTIONPRODUCT_CREATE_RESET:
            return {};
        default:
            return state;
    }
}

export const subscriptionProductDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case SUBSCRIPTIONPRODUCT_DELETE_REQUEST:
        return { loading: true };
      case SUBSCRIPTIONPRODUCT_DELETE_SUCCESS:
        return { loading: false, success: true };
      case SUBSCRIPTIONPRODUCT_DELETE_FAIL:
        return { loading: false, error: action.payload };
      case SUBSCRIPTIONPRODUCT_DELETE_RESET:
        return {};
      default:
        return state;
    }
  };

export const subscriptionProductUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBSCRIPTIONPRODUCT_UPDATE_REQUEST:
            return { loading: true };
        case SUBSCRIPTIONPRODUCT_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case SUBSCRIPTIONPRODUCT_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case SUBSCRIPTIONPRODUCT_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};

export const subscriptionProductDuplicateReducer = (state = {loading: false, result: null, error: null}, action) => {
    switch (action.type) {
        case SUBSCRIPTIONPRODUCT_DUPLICATE_REQUEST:
            return {...state, loading: true}
        case SUBSCRIPTIONPRODUCT_DUPLICATE_FAIL:
            return {...state, loading: false, error: action.payload}
        case SUBSCRIPTIONPRODUCT_DUPLICATE_SUCCESS:
            return {...state, loading: false, result: 'duplicated'}
        case SUBSCRIPTIONPRODUCT_DUPLICATE_RESET:
            return {...state, loading: false, error: null, result: null}
        default:
            return state
    }
}