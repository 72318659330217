import { SUBSCRIPTION_CANCEL_RESET, SUBSCRIPTION_CANCEL_FAIL, SUBSCRIPTION_CANCEL_REQUEST, SUBSCRIPTION_CANCEL_SUCCESS, SUBSCRIPTION_MINE_LIST_FAIL, SUBSCRIPTION_MINE_LIST_REQUEST, SUBSCRIPTION_MINE_LIST_SUCCESS, SUBSCRIPTION_PAYMENT_RESET, SUBSCRIPTION_PAYMENT_SUCCESS, SUBSCRIPTION_LIST_REQUEST, SUBSCRIPTION_LIST_SUCCESS, SUBSCRIPTION_LIST_FAIL, SUBSCRIPTION_SALES_SUBSCRIPTIONPRODUCT_REQUEST, SUBSCRIPTION_SALES_SUBSCRIPTIONPRODUCT_SUCCESS, SUBSCRIPTION_PAID_BY_DATE_REQUEST, SUBSCRIPTION_PAID_BY_DATE_SUCCESS, SUBSCRIPTION_PAID_BY_DATE_FAIL, SUBSCRIPTION_PAID_BY_DATE_RESET, SUBSCRIPTION_REQUEST_CANCEL_REQUEST, SUBSCRIPTION_REQUEST_CANCEL_SUCCESS, SUBSCRIPTION_REQUEST_CANCEL_FAIL, SUBSCRIPTION_REQUEST_CANCEL_RESET } from "../constants/subscriptionConstants";

export const subscriptionPaidReducer = (state = {subscription: null}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_PAYMENT_SUCCESS:
            return { subscription: action.payload }
        case SUBSCRIPTION_PAYMENT_RESET:
            return {subscription: null};
        default:
            return state;
    }
}

export const subscriptionMineListReducer = (state = { subscriptions: [] }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_MINE_LIST_REQUEST:
            return { loading: true };
        case SUBSCRIPTION_MINE_LIST_SUCCESS:
            return { loading: false, subscriptions: action.payload };
        case SUBSCRIPTION_MINE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const subscriptionCancelReducer = (state = { success: null }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_CANCEL_REQUEST:
            return { loading: true };
        case SUBSCRIPTION_CANCEL_SUCCESS:
            return { loading: false, success: true };
        case SUBSCRIPTION_CANCEL_FAIL:
            return { loading: false, error: action.payload, success: false };
        case SUBSCRIPTION_CANCEL_RESET:
            return {loading: false, error: null, success: null}
        default:
            return state;
    }
}

export const subscriptionRequestCancelReducer = (state = { success: null }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_REQUEST_CANCEL_REQUEST:
            return { loading: true };
        case SUBSCRIPTION_REQUEST_CANCEL_SUCCESS:
            return { loading: false, success: true };
        case SUBSCRIPTION_REQUEST_CANCEL_FAIL:
            return { loading: false, error: action.payload, success: false };
        case SUBSCRIPTION_REQUEST_CANCEL_RESET:
            return {loading: false, error: null, success: null}
        default:
            return state;
    }
}

export const subscriptionListReducer = (state = { subscriptions: [] }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_LIST_REQUEST:
            return { loading: true };
        case SUBSCRIPTION_LIST_SUCCESS:
            return { loading: false, subscriptions: action.payload };
        case SUBSCRIPTION_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const salesSubscriptionProductReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_SALES_SUBSCRIPTIONPRODUCT_REQUEST:
            return {loading: true}
        case SUBSCRIPTION_SALES_SUBSCRIPTIONPRODUCT_SUCCESS:
            return {loading: false, sales: action.payload}
        default:
            return state;
    }
}

export const subscriptionsPaidByDateReducer = (state = {loading: false, subscriptions: []}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_PAID_BY_DATE_REQUEST:
            return {...state, loading: true };
        case SUBSCRIPTION_PAID_BY_DATE_SUCCESS:
            return {...state, loading: false, subscriptions: action.payload };
        case SUBSCRIPTION_PAID_BY_DATE_FAIL:
            return {...state, loading: false, error: action.payload };
        case SUBSCRIPTION_PAID_BY_DATE_RESET:
            return {...state, loading: false, subscriptions: []};
        default:
            return state;
    }
}