import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createAttribute } from '../actions/attributeActions';
import { listCourses } from '../actions/courseActions';
import { ToolBar } from '../components/ToolBar'
import { ATTRIBUTE_CREATE_RESET } from '../constants/attributeConstants';
import Select from "react-select"

export const CreateAttributeScreen = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [values, setValue] = useState([])
    const [dates, setDates] = useState([])
    const [coursesOptions, setCoursesOptions] = useState({options: [], selected: []})

    const attributeCreated = useSelector(state => state.attributeCreate)
    const {courses} = useSelector(state => state.courseList)

    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault();

        let attributeValues = []
        values.forEach((value, index) => {
            let attributeCourses = []
            coursesOptions.selected[index].forEach(c => {
                attributeCourses.push(c.value)
            })
            attributeValues.push({value: value, date: dates[index], courses: attributeCourses})
        });

        dispatch(createAttribute({
            name,
            values: attributeValues   
        }))
    }

    const changeValue = (value, index) => {
        let newValues = values.map((val, ind) => (
            ind === index ? value : val
        ))
        setValue(newValues)
    }

    const changeDates = (value, index) => {
        let newDates = dates.map((date, ind) => (
            ind === index ? value : date
        ))
        setDates(newDates)
    }

    const deleteValue = (index) => {
        let newValues = []
        values.forEach((ele, ind) => {
            if (ind !== index) {
                newValues.push(ele)
            }
        })
        let newDates = []
        dates.forEach((ele, ind) => {
            if (ind !== index) {
                newDates.push(ele)
            }
        })
        let newCourses = []
        coursesOptions.selected.forEach((ele, ind) => {
            if (ind !== index) {
                newCourses.push(ele)
            }
        })
        setValue(newValues)
        setDates(newDates)
        setCoursesOptions({...coursesOptions, selected: newCourses})
    }

    const handleCoursesChange = (selectedValues, index) => {
        setCoursesOptions({...coursesOptions, selected: coursesOptions.selected.map((val, i) => {
            if (i === index) {
                return selectedValues
            } else {
                return val
            }
        })})
    }

    useEffect(()=>{
        if (attributeCreated?.attribute) {
            dispatch({type: ATTRIBUTE_CREATE_RESET})
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Atributo creado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
            navigate(`/attribute/${attributeCreated.attribute._id}/edit`)
        }
    }, [attributeCreated, navigate, dispatch])

    useEffect(() => {
        dispatch(listCourses());
    }, [dispatch])

    useEffect(() => {
        setCoursesOptions({selected: [], options: courses?.map(c => ({label: c.name, value: c._id}))})
    }, [courses])

    return (
        <>
            <ToolBar titulo={"Crear atributo"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Atributos", enlace: "/attributelist" },
                { titulo: "Crear atributo", enlace: "#" },
            ]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <form autoComplete='off' id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row" onSubmit={submitHandler}>
                        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-12">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="kt_ecommerce_add_product_general">
                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>General</h2>
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="mb-10 fv-row">
                                                    <label className="required form-label">Nombre del atributo</label>
                                                    <input className="form-control mb-2" id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                                    <div className="text-muted fs-7">Se requiere un nombre de atributo que sea único.</div>
                                                </div>
                                                <div className="mb-10 fv-row">
                                                    <div className='row align-items-center'>
                                                        <div className='col-md-2 col-6'>
                                                            <h4 className='mb-5'>Valores</h4>
                                                        </div>
                                                        <div className='col-md-4 col-6'>
                                                            <button onClick={() => {setValue(['', ...values]); setDates(['', ...dates]); setCoursesOptions({...coursesOptions, selected: [[], ...coursesOptions.selected]})}} type="button" className=" mt-5 btn btn-light-primary btn-sm mb-10">
                                                                <span className="svg-icon svg-icon-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black"></rect>
                                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="black"></rect>
                                                                    </svg>
                                                                </span>
                                                                Añadir valor
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {values.map((value, index) => (
                                                        <div className='form-row row align-items-end' key={index}>
                                                            <div className='col-4'>
                                                                <label className="form-label">Nombre</label>
                                                                <input className="form-control mb-2" name={`attribute-value-${index}`} id={`attribute-value-${index}`} type="text" value={value} onChange={(e) => changeValue(e.target.value, index)} />
                                                            </div>
                                                            <div className='col-3'>
                                                                <label className="form-label">Tipo de fechas</label>
                                                                <select className='form-select mb-2' value={dates[index]} onChange={(e) => changeDates(e.target.value, index)}>
                                                                    <option value=''>Sin fechas</option>
                                                                    <option value='daily'>Días</option>
                                                                    <option value='weekly'>Semanas</option>
                                                                    <option value='monthly'>Meses</option>
                                                                </select>
                                                            </div>
                                                            <div className='col-4'>
                                                                <label className='form-label'>Cursos relacionados</label>
                                                                <Select className='mb-2' placeholder="Ninguno seleccionado" isMulti isClearable options={coursesOptions.options} value={coursesOptions.selected[index]} onChange={(selected) => {handleCoursesChange(selected, index)}}/>
                                                            </div>
                                                            <div className='col-1 text-center'>
                                                                <button onClick={() => deleteValue(index)} type='button' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm mb-2'>
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black"></path>
                                                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black"></path>
                                                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black"></path>
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Link to="/attributelist" id="kt_ecommerce_add_product_cancel" className="btn btn-light me-5">Cancelar</Link>
                                <button type="submit" id="kt_ecommerce_add_product_submit" className="btn btn-primary">
                                    <span className="indicator-label">Guardar</span>
                                    <span className="indicator-progress">Espere por favor...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
