import { STUDENTS_IMPORT_FAIL, STUDENTS_IMPORT_REQUEST, STUDENTS_IMPORT_RESET, STUDENTS_IMPORT_SUCCESS, STUDENT_DELETE_FAIL, STUDENT_DELETE_REQUEST, STUDENT_DELETE_RESET, STUDENT_DELETE_SUCCESS, STUDENT_DETAILS_FAIL, STUDENT_DETAILS_REQUEST, STUDENT_DETAILS_RESET, STUDENT_DETAILS_SUCCESS, STUDENT_LIST_FAIL, STUDENT_LIST_REQUEST, STUDENT_LIST_SUCCESS, STUDENT_REGISTER_FAIL, STUDENT_REGISTER_REQUEST, STUDENT_REGISTER_RESET, STUDENT_REGISTER_SUCCESS, STUDENT_UPDATE_FAIL, STUDENT_UPDATE_PROFILE_FAIL, STUDENT_UPDATE_PROFILE_REQUEST, STUDENT_UPDATE_PROFILE_RESET, STUDENT_UPDATE_PROFILE_SUCCESS, STUDENT_UPDATE_REQUEST, STUDENT_UPDATE_RESET, STUDENT_UPDATE_SUCCESS } from "../constants/studentConstants";

export const studentRegisterReducer = (state = {loading: false, userInfo: null, error: null, success: null}, action) => {
    switch (action.type) {
        case STUDENT_REGISTER_REQUEST:
            return { ...state, loading: true, userInfo: action.payload };
        case STUDENT_REGISTER_SUCCESS:
            return { ...state, loading: false, userInfo: action.payload, success: true };
        case STUDENT_REGISTER_FAIL:
            return { ...state, loading: false, error: action.payload };
        case STUDENT_REGISTER_RESET:
            return { ...state, loading: false, userInfo: null, error: null, success: null };
        default:
            return state;
    }
}

export const studentDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case STUDENT_DETAILS_REQUEST:
            return { loading: true }
        case STUDENT_DETAILS_SUCCESS:
            return { loading: false, student: action.payload }
        case STUDENT_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        case STUDENT_DETAILS_RESET:
            return {loading: true}
        default:
            return state;
    }
}


export const studentUpdateProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case STUDENT_UPDATE_PROFILE_REQUEST:
            return { loading: true };
        case STUDENT_UPDATE_PROFILE_SUCCESS:
            return { loading: false, success: true };
        case STUDENT_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload };
        case STUDENT_UPDATE_PROFILE_RESET:
            return {};
        default:
            return state;
    }
}

export const studentListReducer = (state = { loading: true}, action) => {
    switch (action.type) {
        case STUDENT_LIST_REQUEST:
            return { loading: true };
        case STUDENT_LIST_SUCCESS:
            return { loading: false, students: action.payload };
        case STUDENT_LIST_FAIL:
            return { loading: false, error: action.payload };                
        default:
            return state;
    }
}

export const studentDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case STUDENT_DELETE_REQUEST:
            return { loading: true };
        case STUDENT_DELETE_SUCCESS:
            return { loading: false, success: true };
        case STUDENT_DELETE_FAIL:
            return { loading: false, error: action.payload };     
        case STUDENT_DELETE_RESET:
            return {};           
        default:
            return state;
    }
}

export const studentUpdateReducer = (state = {loading: false, success: null, error: null}, action) => {
    switch (action.type) {
        case STUDENT_UPDATE_REQUEST:
            return { ...state, loading: true };
        case STUDENT_UPDATE_SUCCESS:
            return { ...state, loading: false, success: true };
        case STUDENT_UPDATE_FAIL:
            return { ...state, loading: false, error: action.payload };
        case STUDENT_UPDATE_RESET:
            return {...state, loading: false, success: null, error: null};
        default:
            return state;
    }
}

export const studentsImportReducer = (state = {}, action) => {
    switch (action.type) {
        case STUDENTS_IMPORT_REQUEST:
            return { loading: true };
        case STUDENTS_IMPORT_SUCCESS:
            return { loading: false, success: true };
        case STUDENTS_IMPORT_FAIL:
            return { loading: false, error: action.payload };
        case STUDENTS_IMPORT_RESET:
            return {};
        default:
            return state;
    }
}