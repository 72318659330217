import { CATEGORIE_LIST_SUCCESS, CATEGORIE_LIST_FAIL, CATEGORIE_LIST_REQUEST, CATEGORIE_DETAILS_REQUEST, CATEGORIE_DETAILS_SUCCESS, CATEGORIE_DETAILS_FAIL, CATEGORIE_UPDATE_REQUEST, CATEGORIE_UPDATE_SUCCESS, CATEGORIE_UPDATE_FAIL, CATEGORIE_CREATE_REQUEST, CATEGORIE_CREATE_SUCCESS, CATEGORIE_CREATE_FAIL, CATEGORIE_DELETE_FAIL, CATEGORIE_DELETE_SUCCESS, CATEGORIE_DELETE_REQUEST, CATEGORIE_CREATE_RESET } from '../constants/categorieConstants'
import Axios from 'axios';

export const listCategories = () => async (dispatch) => {
    dispatch({ type: CATEGORIE_LIST_REQUEST });
    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + '/api/categories');
        dispatch({ type: CATEGORIE_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CATEGORIE_LIST_FAIL, payload: error.message });
    }
}

export const detailsCategory = (categoryId) => async (dispatch) => {
    dispatch({ type: CATEGORIE_DETAILS_REQUEST, payload: categoryId });

    try {
        const { data } = await Axios.get(process.env.REACT_APP_DB_ADDRESS + `/api/categories/${categoryId}`);
        dispatch({ type: CATEGORIE_DETAILS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: CATEGORIE_DETAILS_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const updateCategory = (category) => async (dispatch, getState) => {
    dispatch({ type: CATEGORIE_UPDATE_REQUEST, payload: category });
    const { userSignin: { userInfo } } = getState();
    try {
        const { data } = await Axios.put(process.env.REACT_APP_DB_ADDRESS + `/api/categories/${category._id}`, category, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: CATEGORIE_UPDATE_SUCCESS, payload: data });
        dispatch(listCategories())
    } catch (error) {
        const message =
            error.response && error.response.data.message?
                error.response.data.message:
                error.message;
        dispatch({ type: CATEGORIE_UPDATE_FAIL, error: message });
    }
};

export const createCategory = (category) => async (dispatch, getState) => {
    dispatch({ type: CATEGORIE_CREATE_REQUEST });

    const { userSignin: { userInfo } } = getState();

    try {
        const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/categories', category, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        })
        dispatch({ type: CATEGORIE_CREATE_SUCCESS, payload: data.categorie })
        dispatch({type: CATEGORIE_CREATE_RESET})
        dispatch(listCategories())
    } catch (error) {
        dispatch({
            type: CATEGORIE_CREATE_FAIL,
            payload: error.response && error.response.data.message? error.response.data.message: error.message,
        });
    }
}

export const deleteCategory = (categoryId) => async (dispatch, getState) => {
    dispatch({ type: CATEGORIE_DELETE_REQUEST, payload: categoryId });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        await Axios.delete(process.env.REACT_APP_DB_ADDRESS + `/api/categories/${categoryId}`, {
            headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: CATEGORIE_DELETE_SUCCESS });
    } catch (error) {
        const message =
            error.response && error.response.data
                ? error.response.data
                : error.message;
        dispatch({ type: CATEGORIE_DELETE_FAIL, payload: message });
    }
};